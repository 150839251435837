import { AspectRatio } from "@mui/joy";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMemo } from "react";
import { ApiUrl } from "../../../config";
import StubImage from "../../../static/stubs/shop-image-stub.webp";
import { InvoiceTemplateInfoProps } from "./types";
import useAppContext from "../../../useAppContext";
import Interweave from "../../../features/Interweave";

const InvoiceImage = styled("img")({
	borderRadius: 4,
	borderBottomLeftRadius: 0,
	borderBottomRightRadius: 0,
	width: "100%",
	height: "auto",
});

export default function InvoiceTemplateInfo(props: InvoiceTemplateInfoProps) {
	const { lang } = useAppContext();

	const computedInvoiceImage = useMemo(() => {
		if (props.invoiceTemplate.photo) {
			let processedImagePath = props.invoiceTemplate.photo;
			if (processedImagePath.startsWith("http")) {
				return processedImagePath;
			}
			if (!processedImagePath.startsWith("static")) {
				processedImagePath = `static/${processedImagePath}`;
			}
			return `${ApiUrl}/${processedImagePath}`;
		}

		return StubImage;
	}, [props.invoiceTemplate]);

	if (!lang) return null;

	return (
		<>
			<AspectRatio ratio={"6/4"} objectFit={"contain"}>
				<InvoiceImage src={computedInvoiceImage} alt={props.invoiceTemplate.title} />
			</AspectRatio>

			<Box
				sx={{
					borderRadius: 1,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					borderColor: "borderColor",
					px: 3,
					pb: 3,
					pt: 2,
				}}
			>
				<Box>
					<Typography textAlign={"center"} variant={"h5"} fontWeight={"bold"}>
						<Interweave content={props.invoiceTemplate.title} />
					</Typography>

					<Typography textAlign={"center"} variant={"body2"}>
						<Interweave content={props.invoiceTemplate.description} />
					</Typography>
				</Box>
			</Box>
		</>
	);
}
