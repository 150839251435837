import {useMemo, useEffect, useCallback} from "react"
import {DateTimePicker, DatePicker, LocalizationProvider} from "@mui/x-date-pickers"
import dayjs, {Dayjs} from "dayjs";
import '../../../../features/dayjs-locales'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

import {CreateOrderPayload} from "../../../../api/shop/order/types"
import {SetMergeState} from "../../../../helpers/useMergedState";
import {isShopClosed} from "../../../scripts/info";
import {WorkingSlot} from "../../../../api/shop/basic/types"
import {Alert, Box} from "@mui/material";
import useWorkingTimesService from "../../../services/useWorkingTimesService";
import {convertToTimeString} from "../../../../helpers";
import useAppContext from "../../../../useAppContext";
import {isLocaleClockType24h} from "../../../../helpers";
import useDatetimeService from "../../../../services/useDatetimeService";
import {SelectedShipmentServiceType} from "../useSelectedShipmentService";

interface DeliveryTimeProps{
    form: CreateOrderPayload
    setForm: SetMergeState<CreateOrderPayload>
    selectedShipmentService: SelectedShipmentServiceType
}

export default function DeliveryTime(props: DeliveryTimeProps){
    const {lang, localisation} = useAppContext()

    const {workingTimes} = useWorkingTimesService()
    const {computedLocaleText} = useDatetimeService()

    const setForm = props.setForm
    const handleDateTimeChanged = useCallback((value: Dayjs | null) => {
        if (!value) {
            setForm({
                desired_delivery_date: null,
                desired_delivery_time: null,
            })
        } else {
            if(props.selectedShipmentService.selectedShipment?.delivery_datetime_mode === "date"){
                value = value.set('hour', 12).set('minute', 0)
            }
            setForm({
                desired_delivery_date: value.toISOString(),
                desired_delivery_time: convertToTimeString(value.toDate())
            })
        }
    }, [props.selectedShipmentService.selectedShipment?.delivery_datetime_mode, setForm])

    const computedDeliveryTimeWarning = useMemo(() => {
        if(props.form.desired_delivery_date && workingTimes){
            const date = new Date(props.form.desired_delivery_date)
            if(props.form.desired_delivery_time){
                const time = props.form.desired_delivery_time.split(':')
                date.setHours(parseInt(time[0]))
                date.setMinutes(parseInt(time[1]))
            }

            const day = date.toLocaleDateString("en", { weekday: 'long' })
            if (workingTimes.allWorkingDays) {
                let workingDay = workingTimes.allWorkingDays
                    .find(wt => wt.day.toLowerCase() === day.toLowerCase()) || null

                if(!workingDay){
                    workingDay = workingTimes.allWorkingDays
                    .find(wt => wt.day.toLowerCase() === "week") || null
                }

                if(workingDay){
                    if(workingDay.is_weekend){
                        return localisation.global.weekend
                    }
                    if(props.selectedShipmentService.selectedShipment?.delivery_datetime_mode === "date"){
                        return ""
                    }

                    const isClosed = isShopClosed(workingDay, date)

                    if(isClosed){
                        let workingTimeString = ""

                        workingDay.slots?.forEach((slot: WorkingSlot) => {
                            workingTimeString += `${slot.start_time_text}-${slot.end_time_text}, `
                        })

                        return localisation.orders.deliveryTimeWarning
                            .replace("{working_time}",
                                workingTimeString.substring(0, workingTimeString.length - 2))
                    }
                }
            }
        }

        return ""
    }, [
        localisation.global.weekend, localisation.orders.deliveryTimeWarning,
        props.form.desired_delivery_date, props.form.desired_delivery_time,
        props.selectedShipmentService.selectedShipment?.delivery_datetime_mode,
        workingTimes
    ])

    const computedDatePickerValue = useMemo(() => {
        if(props.form.desired_delivery_date){
            return dayjs(new Date(props.form.desired_delivery_date))
        }
        return null
    }, [props.form.desired_delivery_date])

    const computedDefaultDateTime = useMemo(() => {
        let timedelta: number | null = null
        if(props.selectedShipmentService.selectedShipment?.shipment_time?.min_time){
            timedelta = props.selectedShipmentService.selectedShipment?.shipment_time?.min_time * 1000
        }
        else if(props.selectedShipmentService.selectedShipment?.shipment_time?.execution_time){
            timedelta = props.selectedShipmentService.selectedShipment?.shipment_time?.execution_time * 1000
        }

        if(timedelta){
            const currentDate = dayjs()
            if(props.selectedShipmentService.selectedShipment?.delivery_datetime_mode === "datetime"){
                return currentDate.add(timedelta, 'millisecond')
            }
            // Check if timedelta is more than 1 day
            if (timedelta >= 24 * 60 * 60 * 1000 &&
                props.selectedShipmentService.selectedShipment?.delivery_datetime_mode === "date"
            ) {
                return currentDate.add(timedelta, 'millisecond')
            }
        }

        return undefined
    }, [
        props.selectedShipmentService.selectedShipment?.delivery_datetime_mode,
        props.selectedShipmentService.selectedShipment?.shipment_time?.execution_time,
        props.selectedShipmentService.selectedShipment?.shipment_time?.min_time,
    ])

    const computedMaximumDateTime = useMemo(() => {
        if(props.selectedShipmentService.selectedShipment?.shipment_time?.max_time){
            const timedelta = props.selectedShipmentService.selectedShipment?.shipment_time?.max_time * 1000
            const currentDate = dayjs()
            return currentDate.add(timedelta, 'millisecond')
        }
        return undefined
    }, [props.selectedShipmentService.selectedShipment?.shipment_time?.max_time])

    useEffect(() => {
        if(computedDefaultDateTime && !props.form.desired_delivery_date){
            handleDateTimeChanged(computedDefaultDateTime)
        }
    }, [computedDefaultDateTime, handleDateTimeChanged, props.form.desired_delivery_date])

    if (!lang || !computedLocaleText) return null

    return (
        <Box>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={lang}
                localeText={{...computedLocaleText}}
            >
                {props.selectedShipmentService.selectedShipment?.delivery_datetime_mode === "date" && (
                    <DatePicker
                        className={'w-100'}
                        disablePast
                        label={localisation.orders.deliveryDateLabel}
                        value={computedDatePickerValue || computedDefaultDateTime}
                        onChange={handleDateTimeChanged}
                        minDate={computedDefaultDateTime}
                        maxDate={computedMaximumDateTime}
                        slotProps={{ textField: { size: 'small', error: false } }}
                    />
                )}

                {props.selectedShipmentService.selectedShipment?.delivery_datetime_mode === "datetime" && (
                    <DateTimePicker
                        sx={{color: 'main.primary'}}
                        disableIgnoringDatePartForTimeValidation={true}
                        minutesStep={5}
                        disablePast
                        ampm={!isLocaleClockType24h(lang)}
                        ampmInClock={!isLocaleClockType24h(lang)}
                        label={localisation.orders.deliveryTimeDateLabel}
                        value={computedDatePickerValue || computedDefaultDateTime}
                        onChange={handleDateTimeChanged}
                        minDateTime={computedDefaultDateTime}
                        maxDateTime={computedMaximumDateTime}
                        slotProps={{
                            textField: {
                                size: 'small',
                                fullWidth: true,
                                error: false,
                            }
                        }}
                    />
                )}
            </LocalizationProvider>

            {props.selectedShipmentService.selectedShipment?.delivery_time_warning_enabled &&
                computedDeliveryTimeWarning && (
                <Alert
                    severity={'warning'}
                    className={'w-100 mt-2 p-2'}
                >
                    {computedDeliveryTimeWarning}
                </Alert>
            )}
        </Box>
    )
}
