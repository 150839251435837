import { useCallback, useState } from "react";
import { getOrderState, saveOrderState } from "./OrderStepper/orderSessionState";
import {
	BillingAddress,
	CreateOrderPayload,
	Order,
	UpdateOrder,
} from "../../../api/shop/order/types";
import api from "../../../api";
import { SelectedShipmentServiceType } from "./useSelectedShipmentService";
import useAppContext from "../../../useAppContext";
import { useShopContext } from "../../context";
import useLocalisation from "../../../hooks/localisation/useLocalisation";

export default function useUpdateOrder(
	form: CreateOrderPayload,
	selectedShipmentService: SelectedShipmentServiceType,
	billingForm: BillingAddress,
	cartToken: string | null = null
): IUseUpdateOrder {
	const localisation = useLocalisation();
	const { brandInfo, showError } = useAppContext();
	const { addressValue, addressCoords } = useShopContext();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");

	const { selectedShipment, selectedShipmentPrice } = selectedShipmentService;

	const buildPayload = useCallback(
		(createdOrder: Order, type: UpdateOrderDataType) => {
			switch (type) {
				case "shipment":
					const payload: UpdateOrder = {
						delivery_address: addressValue,
						address_comment: form.address_comment,
						address_floor: form.address_floor,
						address_house: form.address_house,
						address_flat: form.address_flat,
						address_entrance: form.address_entrance,
						address_street: form.address_street,
						custom_shipment_comment: form.custom_shipment_comment,
						shipment_method_id: selectedShipment?.id,
						desired_delivery_date: form.desired_delivery_date
							? form.desired_delivery_date
							: null,
						desired_delivery_time: form.desired_delivery_time
							? form.desired_delivery_time
							: null,
					};
					if (selectedShipmentService.base_type !== "delivery") {
						payload.delivery_address = null;
						payload.address_comment = null;
					}
					if (
						brandInfo?.is_get_order &&
						selectedShipmentService.is_base_shipment &&
						selectedShipmentService.base_type === "delivery"
					) {
						payload.delivery_address = `${form.address_street}, ${form.address_house}`;
					}
					if (selectedShipment?.delivery_datetime_mode === "disabled") {
						payload.desired_delivery_date = null;
						payload.desired_delivery_time = null;
					}
					payload.price = selectedShipmentPrice;
					if (!selectedShipmentPrice) {
						payload.free_shipment = true;
					}
					if (billingForm) {
						payload.billing_address = billingForm;
					}
					if (payload.delivery_address && addressCoords) {
						payload.address_lat = addressCoords[1].toString();
						payload.address_lng = addressCoords[0].toString();
						const addressObj = api.shop.basic.getLastAddressObject();
						if (addressObj && addressObj.placeId) {
							payload.address_place_id = addressObj.placeId;
						}
					}
					return payload;
				case "personalData":
					return {
						first_name: form.first_name,
						last_name: form.last_name,
						phone: form.phone,
						email: form.email,
					};
				case "tips":
					return {
						tips_sum: form.tips_sum,
					};
				case "comment":
					return {
						comment: form.comment,
					};
			}
		},
		[
			addressCoords,
			addressValue,
			billingForm,
			brandInfo?.is_get_order,
			form,
			selectedShipment?.delivery_datetime_mode,
			selectedShipment?.id,
			selectedShipmentPrice,
			selectedShipmentService.base_type,
			selectedShipmentService.is_base_shipment,
		]
	);

	const updatePersonalData = useCallback(async () => {
		setIsLoading(true);
		setError("");
		try {
			const orderState = getOrderState();
			if (orderState && orderState.createdOrder) {
				const payload = buildPayload(orderState.createdOrder, "personalData");
				const response = await api.shop.order.updateOrder(
					payload,
					orderState.createdOrder.id,
					cartToken
				);
				if (response.data) {
					saveOrderState(response.data, "createdOrder");
					return { ok: true };
				}
				setError(localisation.orders.updateOrderPersonalData);
			}
		} catch (ex) {
			setError("error");
			console.log(ex);
			showError(ex);
		} finally {
			setIsLoading(false);
		}
	}, [buildPayload, cartToken, localisation.orders.updateOrderPersonalData, showError]);

	const updateShipment = useCallback(async () => {
		setIsLoading(true);
		setError("");
		try {
			const orderState = getOrderState();
			if (orderState && orderState.createdOrder) {
				const payload = buildPayload(orderState.createdOrder, "shipment");
				const response = await api.shop.order.updateOrder(
					payload,
					orderState.createdOrder.id,
					cartToken
				);
				if (response.data) {
					saveOrderState(response.data, "createdOrder");
					return { ok: true };
				}
				setError(localisation.orders.updateOrderShipmentData);
			}
		} catch (ex) {
			setError("error");
			console.log(ex);
			showError(ex);
		} finally {
			setIsLoading(false);
		}
	}, [buildPayload, cartToken, localisation.orders.updateOrderShipmentData, showError]);

	const updateTips = useCallback(async () => {
		setIsLoading(true);
		setError("");
		try {
			const orderState = getOrderState();
			if (orderState && orderState.createdOrder) {
				const payload = buildPayload(orderState.createdOrder, "tips");
				const response = await api.shop.order.updateOrder(
					payload,
					orderState.createdOrder.id,
					cartToken
				);
				if (response.data) {
					saveOrderState(response.data, "createdOrder");
					return { ok: true };
				}
				setError(localisation.orders.updateOrderTipsData);
			}
		} catch (ex) {
			setError("error");
			console.log(ex);
			showError(ex);
		} finally {
			setIsLoading(false);
		}
	}, [buildPayload, cartToken, localisation.orders.updateOrderTipsData, showError]);

	const updateComment = useCallback(async () => {
		setIsLoading(true);
		setError("");
		try {
			const orderState = getOrderState();
			if (orderState && orderState.createdOrder) {
				const payload = buildPayload(orderState.createdOrder, "comment");
				const response = await api.shop.order.updateOrder(
					payload,
					orderState.createdOrder.id,
					cartToken
				);
				if (response.data) {
					saveOrderState(response.data, "createdOrder");
					return { ok: true };
				}
				setError(localisation.orders.updateOrderTipsData);
			}
		} catch (ex) {
			setError("error");
			console.log(ex);
			showError(ex);
		} finally {
			setIsLoading(false);
		}
	}, [buildPayload, cartToken, localisation.orders.updateOrderTipsData, showError]);

	return {
		updatePersonalData,
		updateShipment,
		updateTips,
		updateComment,
		isLoading,
		error,
	};
}

export interface IUseUpdateOrder {
	updatePersonalData: () => Promise<{ ok: boolean } | undefined>;
	updateShipment: () => Promise<{ ok: boolean } | undefined>;
	updateTips: () => Promise<{ ok: boolean } | undefined>;
	updateComment: () => Promise<{ ok: boolean } | undefined>;
	isLoading: boolean;
	error: string;
}

type UpdateOrderDataType = "shipment" | "personalData" | "tips" | "comment";
