import CheckLine from "./CheckLine";
import formatCurrency from "../../helpers/formatCurrency";
import useAppContext from "../../useAppContext";
import { CheckProps } from "./types";
import useCurrency from "../../services/useCurrencyService";
import { Alert, Box, Typography } from "@mui/material";
import Interweave from "../Interweave";
import formatNumber from "../../helpers/formatNumber";
import { ExtraFeeSchema } from "../../api/shop/basic/types";
import f from "../../helpers/formatText";

export default function CheckTotal({ data }: CheckProps) {
	const {
		lang,
		localisation: { check: locale },
		brandInfo,
	} = useAppContext();

	const currency = useCurrency(data.currency);

	const paidOrToPaySum =
		data.status_pay === "payed" && data.paid_sum ? data.paid_sum : data.sum_to_pay;

	return (
		<>
			{/* Total */}
			{data.subtotal !== undefined && data.subtotal !== null && (
				<CheckLine fontWeight={"bold"}>
					<span>{locale.subtotalText}</span>
					<span>{formatNumber(data.subtotal, brandInfo?.default_lang || lang)}</span>
				</CheckLine>
			)}
			{!!data.totalDiscount && (
				<CheckLine color={"warning.main"}>
					<span>{locale.totalDiscountText}</span>
					<span>
						-{formatNumber(data.totalDiscount, brandInfo?.default_lang || lang)}
					</span>
				</CheckLine>
			)}
			{!!data.paymentPrice && (
				<CheckLine>
					<span>{locale.paymentPriceText}</span>
					<span>{formatNumber(data.paymentPrice)}</span>
				</CheckLine>
			)}
			{!!data.deliveryPrice && (
				<CheckLine>
					<span>{locale.deliveryPriceText}</span>
					<span>
						{formatNumber(data.deliveryPrice)}
						{data.isDeliveryPaymentSeparately && (
							<span>{locale.deliveryPaymentSeparatelyText}</span>
						)}
					</span>
				</CheckLine>
			)}

			{data.totalAmount && (
				<CheckLine
					fontWeight={"bold"}
					borderBottom={data.tips || data.payer_fee || paidOrToPaySum ? 1 : 0}
					borderColor={"divider"}
				>
					<span>{locale.totalAmountText}</span>
					<span>
						{currency
							? formatCurrency(
									data.totalAmount,
									brandInfo?.default_lang || lang,
									currency
								)
							: formatNumber(data.totalAmount)}
					</span>
				</CheckLine>
			)}

			{/* Extra Fee */}
			{data.extraFee &&
				data.extraFee.length > 0 &&
				data.extraFee
					.filter(fee => fee.applied_amount_float > 0)
					.map((fee: ExtraFeeSchema) => (
						<CheckLine key={fee.extra_fee_id}>
							<span>{fee.name}</span>
							<span>
								{currency
									? formatCurrency(
											fee.applied_amount_float,
											brandInfo?.default_lang || lang,
											currency
										)
									: formatNumber(fee.applied_amount_float)}
							</span>
						</CheckLine>
					))}

			{!!(
				data.extraFee &&
				data.extraFee.some(fee => fee.applied_amount_float > 0) &&
				data.total_sum_with_extra_fee > 0
			) && (
				<CheckLine
					fontWeight={"bold"}
					borderBottom={data.tips || data.payer_fee || paidOrToPaySum ? 1 : 0}
					borderColor={"divider"}
				>
					<span>{locale.totalAmountText}</span>
					<span>
						{currency
							? formatCurrency(
									data.total_sum_with_extra_fee,
									brandInfo?.default_lang || lang,
									currency
								)
							: formatNumber(data.total_sum_with_extra_fee)}
					</span>
				</CheckLine>
			)}

			{!!data.tips && (
				<>
					<CheckLine>
						<span>{locale.tipsText}</span>
						<span>
							{currency
								? formatCurrency(
										data.tips,
										brandInfo?.default_lang || lang,
										currency
									)
								: formatNumber(data.tips)}
						</span>
					</CheckLine>
					<CheckLine
						fontWeight={"bold"}
						borderBottom={data.payer_fee || paidOrToPaySum ? 1 : 0}
						borderColor={"divider"}
					>
						<span>{locale.totalAmountWithTipsText}</span>
						<span>
							{currency
								? formatCurrency(
										data.totalAmountWithTips,
										brandInfo?.default_lang || lang,
										currency
									)
								: formatNumber(data.totalAmountWithTips)}
						</span>
					</CheckLine>
				</>
			)}

			{/* show service fee in final check view or if is the same fee for all payment methods*/}
			{!!data.payer_fee_row && (
				<CheckLine>
					<span>{locale.payerFeeListOrderText}</span>
					<Box display={"flex"} alignItems={"center"}>
						{typeof data.payer_fee_row === "string"
							? data.payer_fee_row
							: formatCurrency(
									data.payer_fee_row,
									brandInfo?.default_lang || lang,
									currency
								)}
					</Box>
				</CheckLine>
			)}

			{!!paidOrToPaySum && (
				<CheckLine>
					<span>
						{data.status_pay === "payed" ? locale.paidText : locale.paymentDueText}
					</span>
					<span>
						{typeof paidOrToPaySum === "string"
							? paidOrToPaySum
							: formatCurrency(
									paidOrToPaySum || 0,
									brandInfo?.default_lang || lang,
									currency
								)}
					</span>
				</CheckLine>
			)}

			{/* service fee */}
			{!!data.payer_fee && !data.payer_fee_row && (
				<CheckLine>
					<Typography
						component="span"
						variant="body2"
						sx={{
							fontStyle: "italic",
							fontSize: "0.875em",
							color: "text.secondary",
						}}
					>
						{f(locale.serviceFeeText, { service_fee: data.payer_fee })}
					</Typography>
				</CheckLine>
			)}

			{data.paymentMethodInfo && data.status !== "closed" && data.status_pay !== "payed" && (
				<Box marginTop={2}>
					<Alert severity={"info"}>
						<Interweave
							content={data.paymentMethodInfo}
							openLinkInExtBrowser={true}
							defaultNewWindow={true}
						/>
					</Alert>
				</Box>
			)}
		</>
	);
}
