import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Box, ListItemText, Typography, useColorScheme } from "@mui/material";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import Divider from "@mui/material/Divider";

import {
	IAccountsBenefits,
	IBonusesBenefits,
	ICustomerBenefits,
	IReferralProgramChain,
	IReferralProgramChainNode,
	IReferralProgramLevelSummary,
	IReferralProgramSummary,
} from "../../../../api/shop/loyalty/types";
import useCurrency from "../../../../services/useCurrencyService";
import formatCurrency from "../../../../helpers/formatCurrency";
import useAppContext from "../../../../useAppContext";

interface IReferralListProps {
	chain: IReferralProgramChain;
	summary: IReferralProgramSummary;
}

export default function ReferralList(props: IReferralListProps) {
	const {
		localisation: { profile },
	} = useAppContext();
	const colorScheme = useColorScheme();

	return (
		<List
			sx={{
				width: "100%",
				bgcolor: window.Telegram.WebApp.initData ? "background.main" : "background.paper",
			}}
		>
			{props.summary.levels?.map((level: IReferralProgramLevelSummary, index: number) => (
				<Box
					key={`${level.level}${level.referrals_count}${level.confirmed_referrals_count}${index}`}
				>
					{!!(level.referrals_count && level.referrals_count > 0) && (
						<>
							<Typography
								variant={"h5"}
								fontWeight={"bold"}
								sx={{ color: "primary.main" }}
								className={"border-bottom"}
							>
								{profile.referralLevel} {level.level}
							</Typography>
							{props.chain.referrals
								?.filter(
									(ref: IReferralProgramChainNode) => ref.level === level.level
								)
								.map((ref: IReferralProgramChainNode) => (
									<ReferralListItem referral={ref} />
								))}
						</>
					)}
				</Box>
			))}
		</List>
	);
}

interface IReferralListItemProps {
	referral: IReferralProgramChainNode;
}

function ReferralListItem(props: IReferralListItemProps) {
	const {
		localisation: { profile },
	} = useAppContext();

	return (
		<>
			<ListItem sx={{ px: 0 }}>
				<ListItemText
					primaryTypographyProps={{ variant: "h6" }}
					primary={
						<Box display={"flex"}>
							<Box>{props.referral.user_name || profile.referralUnknownUser}</Box>
							<Box sx={{ ml: "auto" }}>
								{props.referral.rewards ? (
									<ReferralListItemRewards rewards={props.referral.rewards} />
								) : (
									""
								)}
							</Box>
						</Box>
					}
				/>
			</ListItem>
			<Divider />
		</>
	);
}

interface IReferralListItemRewardsProps {
	rewards: ICustomerBenefits;
}

function ReferralListItemRewards(props: IReferralListItemRewardsProps) {
	const { lang, brandInfo } = useAppContext();
	const currency = useCurrency();
	const isBonuses = !!props.rewards.bonuses && props.rewards.bonuses.length > 0;
	const isAccounts = !!props.rewards.accounts && props.rewards.accounts.length > 0;

	return (
		<>
			{isBonuses && (
				<Box display={"flex"}>
					<Typography fontWeight={"bold"} variant={"h5"}>
						{props.rewards.bonuses?.map((bonus: IBonusesBenefits) => (
							<Box key={bonus.currency}>
								{formatCurrency(
									bonus.amount || 0,
									brandInfo?.default_lang || lang,
									bonus.currency || currency || ""
								)}
							</Box>
						))}
					</Typography>
					<PaymentsOutlinedIcon
						fontSize={"inherit"}
						sx={{ color: "primary.main", alignSelf: "center", ml: 2 }}
					/>
				</Box>
			)}

			{isAccounts && (
				<Box display={"flex"}>
					<Typography fontWeight={"bold"} variant={"h5"}>
						{props.rewards.accounts?.map((account: IAccountsBenefits) => (
							<Box key={account.id}>
								{formatCurrency(
									account.amount || 0,
									brandInfo?.default_lang || lang,
									account.currency || currency || ""
								)}
							</Box>
						))}
					</Typography>
					<PaymentsOutlinedIcon
						fontSize={"inherit"}
						sx={{ color: "primary.main", alignSelf: "center", ml: 2 }}
					/>
				</Box>
			)}

			{!isAccounts && !isBonuses && (
				<Box display={"flex"}>
					<Typography fontWeight={"bold"} variant={"h5"}>
						{formatCurrency(0, brandInfo?.default_lang || lang, currency || "")}
					</Typography>
					<PaymentsOutlinedIcon
						fontSize={"inherit"}
						sx={{ color: "primary.main", alignSelf: "center", ml: 2 }}
					/>
				</Box>
			)}
		</>
	);
}
