import useMergeState from "../../../helpers/useMergedState";
import { MakeInvoiceForm, MakeInvoiceProps } from "./types";
import MakeInvoiceStepper from "./MakeInvoiceStepper";
import useStepper from "../../../hooks/useStepper";
import useCurrency from "../../../services/useCurrencyService";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import InvoiceTemplateInfo from "./InvoiceTemplateInfo";
import { IInvoiceCreatedResult } from "../../../api/invoice/types";
import useIncustService, {
	useIncustCheckItemsFromInvoiceTemplateItems,
} from "../../../features/services/useIncustService";
import { matchIsValidTel } from "mui-tel-input";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import useNewPaymentsService from "../hooks/useNewPaymentsService";

export default function MakeInvoice(props: MakeInvoiceProps) {
	const {
		lang,
		brandInfo,
		localisation,
		authService: { user, token: userToken },
		loadingManager: { isSomethingLoading },
	} = useAppContext();

	const [form, setForm] = useMergeState<MakeInvoiceForm>({
		first_name: "",
		last_name: "",
		entered_amount: props.enteredAmount || null,
		count: null,
		payment_method: "online",
	});

	const currency = useCurrency();

	const checkItems = useIncustCheckItemsFromInvoiceTemplateItems(
		props.invoiceTemplate?.items,
		form.count
	);
	const selectedStore = useSelectedStoreOptional();
	const incustService = useIncustService(
		brandInfo?.id,
		selectedStore?.id,
		brandInfo?.incust_data,
		lang,
		currency || "",
		checkItems,
		form.entered_amount,
		!props.noLoyaltyKey
	);

	const [errorText, setErrorText] = useState("");
	const stepper = useStepper();
	const paymentsService = useNewPaymentsService(selectedStore?.id, null, currency);

	const [invoiceCreated, setInvoiceCreated] = useState<IInvoiceCreatedResult | null>(null);

	useEffect(() => {
		const lastPersonalData = api.shop.order.getLastPersonalData();

		setForm(prevState => ({
			first_name: user?.first_name || lastPersonalData?.first_name || prevState.first_name,
			last_name: user?.last_name || lastPersonalData?.last_name || prevState.last_name,
			email: user?.email || lastPersonalData?.email || prevState.email,
			phone:
				(user?.wa_phone && `+${user.wa_phone}`) ||
				(lastPersonalData?.phone &&
					matchIsValidTel(lastPersonalData.phone) &&
					lastPersonalData.phone) ||
				prevState.phone,
		}));
	}, [setForm, user]);

	if (!lang) return null; // for typescript

	if (!currency) {
		return <Typography color={"error"}>No currency</Typography>;
	}

	if (props.isLoading || (userToken && !user)) {
		return (
			<Box width={"100%"} display={"flex"} justifyContent={"center"} mt={4} mb={3}>
				{!isSomethingLoading && <CircularProgress size={"3rem"} />}
			</Box>
		);
	}

	const stepperElement = (
		<Box
			{...{
				...props.stepperWrapperProps,
				sx: {
					p: 2,
					...props.stepperWrapperProps?.sx,
				},
			}}
		>
			<Typography variant={"h6"} fontSize={"large"} mb={{ xs: 2, md: 3 }}>
				{localisation.payment.makingInvoiceHeader}
			</Typography>
			<MakeInvoiceStepper
				form={form}
				setForm={setForm}
				stepper={stepper}
				currency={currency}
				paymentsService={paymentsService}
				invoiceCreated={invoiceCreated}
				setInvoiceCreated={setInvoiceCreated}
				incustService={incustService}
				errorText={errorText}
				setErrorText={setErrorText}
				{...props}
			/>
		</Box>
	);

	if (props.paymentMode === "template" && props.invoiceTemplate) {
		return (
			<Grid container columnSpacing={3} justifyContent={"center"} alignItems={"stretch"}>
				<Grid
					item
					xs={12}
					md={5}
					display={"flex"}
					alignItems={"center"}
					sx={{
						borderRight: { xs: 0, md: 1 },
						borderRightColor: { xs: "none", md: "divider" },
						pr: { xs: 0, md: 2 },
					}}
				>
					<Box
						sx={{
							width: "100%",
						}}
					>
						<InvoiceTemplateInfo
							currency={currency}
							invoiceTemplate={props.invoiceTemplate}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={7}>
					{stepperElement}
				</Grid>
			</Grid>
		);
	}

	return <Box width={"100%"}>{stepperElement}</Box>;
}
