import {Typography} from "@mui/material";
import {RouteObject} from "react-router-dom";
import FastPay from "./FastPay";
import useAppContext from "../../useAppContext";
import {InvoicePaymentMode} from "../../api/invoice/types";
import f from "../../helpers/formatText";
import {useEffect, useState} from "react";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";
import {NonModalDialogPage} from "../../features/NonModalDialog";
import Interweave from "../../features/Interweave";


function FastPayPageComponent() {
    const {localisation, brandInfo} = useAppContext()

    const [searchParams, setSearchParams] = useSearchParamsFixed()

    const enteredAmountParam = searchParams.get("entered_amount")
    const paymentMode = searchParams.get("mode")
    const invoiceTemplateIdParam = searchParams.get("invoice_template_id")
    const noLoyaltyKey = searchParams.get("nl_key")

    const enteredAmount = (!!enteredAmountParam && parseFloat(enteredAmountParam)) || null
    const invoiceTemplateId = (!!invoiceTemplateIdParam && parseInt(invoiceTemplateIdParam)) || null

    const [isCreated, setIsCreated] = useState(false)

    useEffect(() => {
        if (
            searchParams.has("is_success") ||
            searchParams.has("external_id") ||
            searchParams.has("invoice_id")
        ) {
            setSearchParams(prev => {
                prev.delete("is_success")
                prev.delete("external_id")
                prev.delete("invoice_id")
                return prev
            })
        }
    }, [searchParams, setSearchParams]);

    return (
        <NonModalDialogPage
            containerProps={{
                maxWidth: paymentMode === 'template' && invoiceTemplateId ? 'lg' : 'sm'
            }}
            header={f(
                isCreated
                    ? localisation.payment.fastPayInvoiceForHeader
                    : localisation.payment.fastPayCreateInvoiceHeader,
                {name: brandInfo?.name},
            )}
        >
            {(!paymentMode || !['entered_amount', 'template'].includes(paymentMode)) ? (
                <Typography variant={'h6'} color={'error'}>
                    <Interweave content={
                        localisation.payment.invalidPaymentModeError
                            .replace('{payment_mode}', paymentMode || '')}
                    />
                </Typography>
            ) : (
                <FastPay
                    paymentMode={paymentMode as InvoicePaymentMode}
                    enteredAmount={enteredAmount}
                    invoiceTemplateId={invoiceTemplateId}
                    noLoyaltyKey={noLoyaltyKey}
                    onInvoiceCreated={() => setIsCreated(true)}
                />
            )}
        </NonModalDialogPage>
    )
}


export default function FastPayPage(): RouteObject {
    return {
        path: 'fast_pay',
        Component: FastPayPageComponent,
    }
}
