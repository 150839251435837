import { AxiosInstance } from "axios";

import { IInvoiceCreatedResult, InvoiceTemplate, CreateInvoiceWebPayload, Invoice } from "./types";
import { PaymentCheckoutData, PaymentPayload, PaymentTransactionId } from "../shop/payments/types";
import { AvailablePayments, PrePaymentPayload } from "../shop/basic/types";

export default function InvoiceModule(instance: AxiosInstance) {
	const getPath = (path: string) => `/payments/${path}`;

	const getHeaders = (token: string | null | undefined) =>
		token ? { Authorization: `Bearer ${token}` } : undefined;

	return {
		getInvoiceByToken(token: string) {
			return instance.get<Invoice>(getPath(`invoice/byToken`), {
				headers: getHeaders(token),
			});
		},

		getInvoice(invoiceId: number) {
			return instance.get<Invoice>(getPath(`invoice/${invoiceId}`));
		},

		makePrePayment(payload: PrePaymentPayload) {
			return instance.post<PaymentCheckoutData>(getPath("pre_payment"), payload, {
				headers: getHeaders(payload.order_token || payload.invoice_token),
			});
		},

		makePrePaymentOnline(payload: PrePaymentPayload) {
			return instance.post<PaymentCheckoutData>(getPath("pre_payment/online"), payload, {
				headers: getHeaders(payload.order_token || payload.invoice_token),
			});
		},

		createInvoice(payload: CreateInvoiceWebPayload) {
			return instance.post<IInvoiceCreatedResult>(getPath("invoice/create"), payload);
		},

		getInvoiceTemplate(invoiceTemplateId: number) {
			return instance.get<InvoiceTemplate>(getPath(`invoiceTemplate/${invoiceTemplateId}`));
		},
	};
}
