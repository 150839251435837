import {ChangeEvent} from "react";
import {Checkbox, FormControlLabel } from "@mui/material";
import {getPrivacyPolicyLink, getTermsOfUseLink} from "../../../helpers/consents";
import useAppContext from "../../../useAppContext";
import Interweave from "../../../features/Interweave";


interface AgreementCheckboxProps {
    value: boolean
    setValue: (newValue: boolean) => void
}


export default function AgreementCheckbox(props: AgreementCheckboxProps) {
    const {
        lang,
        brandInfo,
        localisation
    } = useAppContext()

    const handleIsAcceptAgreementChanged = (e: ChangeEvent<HTMLInputElement>) => {
        props.setValue(e.target.checked)
    }

    return (
        <FormControlLabel
            sx={{mt: 1}}
            control={(
                <Checkbox
                    name={'Agreement'}
                    checked={props.value}
                    onChange={handleIsAcceptAgreementChanged}
                />
            )}
            label={(
                <Interweave className={'user-select-none'} content={(
                    localisation.auth.agreementText
                        .replace('{terms_of_use_link}', getTermsOfUseLink(brandInfo, lang))
                        .replace('{privacy_policy_link}', getPrivacyPolicyLink(lang))
                )}/>
            )}
        />
    )
}
