import { TgButton } from "../../helpers/ThemeComponents";
import { FormEvent, useMemo } from "react";
import api from "../../api";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import { getErrorText } from "../../helpers/errors";
import { AuthorisationComponentProps } from "./types";
import EmailInput from "./inputs/EmailInput";
import { MDBSpinner } from "mdb-react-ui-kit";
import useAuthSettings from "../../services/useAuthSettings";
import useAppContext from "../../useAppContext";

interface IAuthMethodsProps extends AuthorisationComponentProps {
	disabledExternalLogin?: boolean;
}

export default function AuthMethods(props: IAuthMethodsProps) {
	if (props.form.state !== "authMethods") {
		throw new Error("AuthMethods must be called only in state authMethods");
	}

	const { brandInfo } = useAppContext();
	const localisation = useLocalisation();
	const authSettings = useAuthSettings(brandInfo?.group_id);

	const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (props.form.state !== "authMethods") return null; // for typescript

		props.form.setIsLoading(true);
		try {
			const response = await api.auth.checkIsEmailExists(props.form.email);
			if (response.data.is_exists) {
				props.form.startEmailLogin();
			} else {
				props.form.startEmailRegistration();
			}
		} catch (err: any) {
			props.form.setError("form", getErrorText(err, localisation.auth.unknownError));
		} finally {
			props.form.setIsLoading(false);
		}
	};

	const disabledEmailLogin = useMemo(() => {
		console.log(
			"*** authSettings.computedIsAnyAuthEnabled",
			authSettings.computedIsAnyAuthEnabled
		);
		console.log(
			"*** authSettings.settings?.is_auth_email_enabled",
			authSettings.settings?.is_auth_email_enabled
		);
		return (
			authSettings.computedIsAnyAuthEnabled && !authSettings.settings?.is_auth_email_enabled
		);
	}, [authSettings.computedIsAnyAuthEnabled, authSettings.settings?.is_auth_email_enabled]);

	if (disabledEmailLogin) {
		return <></>;
	}

	return (
		<div className={"w-100"}>
			<form onSubmit={onFormSubmit}>
				<EmailInput form={props.form} />

				<TgButton
					disabled={props.form.isLoading}
					type={"submit"}
					className={"w-100 mt-2 shadow-none"}
				>
					{props.form.isLoading && (
						<MDBSpinner size={"sm"} role={"status"} className={"me-2"} />
					)}
					{localisation.auth.continueButton}
				</TgButton>
			</form>
		</div>
	);
}
