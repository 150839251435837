import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
	AuthServiceMethodsType,
	AuthServiceReducerType,
	AuthServiceType,
	AuthServiceUpdateUserType,
} from "./types";
import {
	ShortTokenDataType,
	LoginData,
	RegisterData,
	Token,
	UpdateUserData,
	User,
} from "../../api/auth/types";
import api from "../../api";
import { LoadingManager } from "../../features/hooks/useLoadingManager";
import { createCustomEvent } from "../../features/custom-events";
import {
	IncustLoyaltyBonusesStorageKey,
	IncustLoyaltyCouponsStorageKey,
	IncustLoyaltyAppliedCouponsStorageKey,
} from "../../features/services/useIncustService";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";
import { useQueryClient } from "@tanstack/react-query";

export default function useAuthService(
	loadingManager: LoadingManager,
	showError: (err: any) => void,
	shortTokenData: ShortTokenDataType | null,
	isBrandLoading: boolean,
	botId?: number | null | undefined,
	lang?: string | null | undefined
): AuthServiceType {
	const isShortTokenLoading = loadingManager.getIsLoading("short-token");

	const [searchParams] = useSearchParamsFixed();

	const [showAuthorisationModal, setShowAuthorisationModal] = useState(false);

	const queryClient = useQueryClient();

	const [state, dispatch] = useReducer<AuthServiceReducerType>((prevState, action) => {
		switch (action.type) {
			case "SIGN_IN":
				return {
					token: action.token,
					user: prevState.user,
					isLoading: false,
				};
			case "SIGN_OUT":
				return {
					token: null,
					user: null,
					isLoading: false,
				};
			case "SET_USER":
				return {
					...prevState,
					user: action.user,
					isLoading: false,
				};
			case "UPDATE_USER":
				if (!prevState.user) {
					throw new Error("user must be set for action UPDATE_USER");
				}

				return {
					...prevState,
					user: { ...prevState.user, ...action.data },
					isLoading: false,
				};
			case "SET_LOADING":
				return {
					...prevState,
					isLoading: prevState.isLoading,
				};
		}
	}, {});

	const setIsLoading = useCallback((isLoading: boolean) => {
		dispatch({ type: "SET_LOADING", isLoading });
	}, []);

	const onLogin = useCallback((data: Token) => {
		api.instance.defaults.headers.common["Authorization"] = `${data.token_type} ${data.token}`;
		dispatch({ type: "SIGN_IN", token: data.token });
		api.auth.setCurrentToken(data.token);
		const event = createCustomEvent("onAuth");
		document.dispatchEvent(event);
	}, []);

	const methods = useMemo((): AuthServiceMethodsType => {
		return {
			onLogin,
			async login(data: LoginData, onLoginCallback?: () => void): Promise<Token> {
				setIsLoading(true);
				try {
					const response = await api.auth.login(data);
					if (onLoginCallback) onLoginCallback();
					onLogin(response.data);
					return response.data;
				} finally {
					setIsLoading(false);
				}
			},
			async register(data: RegisterData, onLoginCallback?: () => void): Promise<Token> {
				try {
					const response = await api.auth.register(data);
					if (onLoginCallback) onLoginCallback();
					onLogin(response.data);
					return response.data;
				} finally {
					setIsLoading(false);
				}
			},
			logout() {
				dispatch({ type: "SIGN_OUT" });
				api.instance.defaults.headers.common["Authorization"] = "";
				api.auth.removeCurrentToken();
				api.auth.removeCurrentUser();

				sessionStorage.removeItem(IncustLoyaltyBonusesStorageKey);
				sessionStorage.removeItem(IncustLoyaltyCouponsStorageKey);
				sessionStorage.removeItem(IncustLoyaltyAppliedCouponsStorageKey);

				const event = createCustomEvent("onUnAuth");
				document.dispatchEvent(event);
				queryClient.invalidateQueries({
					queryKey: ["incust-customer-data"],
				});
			},
			async loadUser(): Promise<User> {
				const response = await api.auth.getUser();
				console.log("loaded user", response.data);
				dispatch({ type: "SET_USER", user: response.data });
				api.auth.setCurrentUser(response.data);
				return response.data;
			},
			async updateUser(data: UpdateUserData): Promise<User> {
				const response = await api.auth.updateUser(data);
				dispatch({ type: "SET_USER", user: response.data });
				return response.data;
			},
			async updateUserLang(newLang: string): Promise<string> {
				const response = await api.auth.updateUserLang(newLang);
				dispatch({ type: "UPDATE_USER", data: response.data });
				return response.data.lang;
			},
			updateUserData(data: AuthServiceUpdateUserType) {
				dispatch({ type: "UPDATE_USER", data: data });
			},
		};
	}, [onLogin, queryClient, setIsLoading]);

	const restoreUserData = useCallback(() => {
		let token = api.auth.getCurrentToken();
		if (token) {
			const restoredUser = api.auth.getCurrentUser();
			if (restoredUser) {
				dispatch({ type: "SET_USER", user: restoredUser });
			}
			onLogin({
				token: token,
				token_type: "bearer",
			});
		} else {
			methods.logout();
		}
	}, [methods, onLogin]);

	useEffect(() => {
		if (
			!window.Telegram.WebApp.initData &&
			!isShortTokenLoading &&
			!shortTokenData?.token_data
		) {
			restoreUserData();
		}
	}, [restoreUserData, isShortTokenLoading, shortTokenData?.token_data]);

	const botIdQuery = searchParams.get("bot_id");
	const tgBotId = botId || botIdQuery;
	useEffect(() => {
		if (window.Telegram.WebApp.initData && tgBotId) {
			if (!state.token) {
				api.auth.loginByTgWebview(tgBotId).then(response => {
					onLogin(response.data);
				});
			}
		}
	}, [tgBotId, onLogin, state.token]);

	useEffect(() => {
		if (shortTokenData?.token_data) {
			onLogin({
				token: shortTokenData.token_data.token,
				token_type: shortTokenData.token_data.token_type,
			});
		}
	}, [onLogin, shortTokenData?.token_data]);

	const isUser = !!state.user;
	const { setIsLoading: lmSetIsLoading } = loadingManager;
	useEffect(() => {
		if (state.token && !isBrandLoading && lang) {
			setIsLoading(true);

			if (!isUser) {
				lmSetIsLoading("user", true);
			}

			console.log("loading user");
			methods
				.loadUser()
				.catch(err => {
					console.log(err);
					if (err?.response?.status === 401) {
						methods.logout();
					} else {
						showError(err);
					}
				})
				.finally(() => {
					setIsLoading(false);
					lmSetIsLoading("user", false);
				});
		}
	}, [
		state.token,
		setIsLoading,
		lmSetIsLoading,
		methods,
		showError,
		isBrandLoading,
		lang,
		isUser,
	]);

	return useMemo(
		() => ({
			...state,
			...methods,
			client: state.user?.client || "web",
			isAuthorised: !!state.user,
			showAuthorisationModal,
			setShowAuthorisationModal,
		}),
		[methods, state, showAuthorisationModal]
	);
}
