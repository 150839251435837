import React, { ReactNode, useState } from "react";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import useStepper from "../../../hooks/useStepper";
import PersonalDataStep from "../MakeOrder/OrderStepper/PersonalDataStep";
import useAppContext from "../../../useAppContext";
import { BillingAddress, CreateOrderPayload, Order } from "../../../api/shop/order/types";
import { SetMergeState } from "../../../helpers/useMergedState";
import { StepIconComponent } from "../MakeOrder/OrderStepper/OrderStepper";
import { SelectedShipmentServiceType } from "../MakeOrder/useSelectedShipmentService";
import { isIOS } from "../../../helpers";
import { IShipmentPricesService } from "../../services/useShipmentPrices";
import ConfirmationStepLite from "./ConfirmationStepLite";
import { SetState } from "../../../types";
import { ITopupMakeOrderResult, IUseProductTopup } from "../../menu/product/hooks/useProductTopup";
import { PaymentsModal } from "../../payments/Payment/Payments";
import { IUsePaymentsService } from "../../payments/hooks/useNewPaymentsService";
import { useSelectedStore } from "../../SelectedStore/context";

interface IOrderStepperLiteProps {
	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;
	paymentsService: IUsePaymentsService;
	billingForm: BillingAddress;
	setBillingForm: SetMergeState<BillingAddress>;
	selectedShipmentService: SelectedShipmentServiceType;
	shipmentPricesService: IShipmentPricesService;
	errorText: string | null;
	needLoginForEmail: boolean;
	confirmationContent: ReactNode;
	setErrorText: SetState<string | null>;
	submit: (form: CreateOrderPayload) => Promise<ITopupMakeOrderResult>;
	setNeedLoginForEmail: (needLogin: boolean) => void;
	backCallback?: () => void;
	service?: IUseProductTopup | null;
}

export default function OrderStepperLite(props: IOrderStepperLiteProps) {
	const isDeviceIos = isIOS();
	const {
		localisation,
		authService: { user },
	} = useAppContext();
	const stepper = useStepper();
	const selectedStore = useSelectedStore();

	const [createdOrder, setCreatedOrder] = useState<Order | null>(null);
	const [showPayments, setShowPayments] = useState<boolean>(false);

	return (
		<div ref={stepper.ref}>
			<Stepper activeStep={stepper.activeStep} orientation={"vertical"}>
				<Step key={"personalData"}>
					<StepLabel StepIconComponent={StepIconComponent} icon={"user"}>
						{localisation.orders.stepPersonalHeader}
					</StepLabel>
					<StepContent>
						<PersonalDataStep
							form={props.form}
							setForm={props.setForm}
							email_required={
								props.paymentsService.paymentsInfo?.email_required || []
							}
							setPrevStep={() => {
								props.backCallback && props.backCallback();
							}}
							setNextStep={stepper.setNextStep}
							prevEnabled={!!props.backCallback}
						/>
					</StepContent>
				</Step>

				<Step last={!createdOrder}>
					<StepLabel StepIconComponent={StepIconComponent} icon={"final"}>
						{localisation.orders.orderConfirmStepHeader}
					</StepLabel>
					<StepContent
						transitionDuration={isDeviceIos ? 0 : 300}
						sx={{ paddingBottom: 3 }}
					>
						<ConfirmationStepLite
							content={props.confirmationContent}
							form={props.form}
							setForm={props.setForm}
							selectedShipmentService={props.selectedShipmentService}
							needLoginForEmail={props.needLoginForEmail}
							errorText={props.errorText}
							shipmentPricesService={props.shipmentPricesService}
							setPrevStep={stepper.setPrevStep}
							setNextStep={stepper.setNextStep}
							paymentsService={props.paymentsService}
							billingForm={props.billingForm}
							setNeedLoginForEmail={props.setNeedLoginForEmail}
							setErrorText={props.setErrorText}
							submit={props.submit}
							setCreatedOrder={setCreatedOrder}
							createdOrder={createdOrder}
							setShowPayments={setShowPayments}
							service={props.service}
						/>
					</StepContent>
				</Step>

				<PaymentsModal
					show={showPayments}
					setShow={setShowPayments}
					orderId={createdOrder?.id}
					type={"order"}
					selectedStoreId={selectedStore.id}
					paymentService={props.paymentsService}
					createdOrder={createdOrder}
					user={user}
					orderToken={createdOrder?.token}
					onlyOnlinePayments={true}
				/>
			</Stepper>
		</div>
	);
}
