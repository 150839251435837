import { useState } from "react";
import Carousel from "react-material-ui-carousel";

import { ApiUrl } from "../../config";
import ShopImage from "../../static/stubs/shop-image-stub.webp";
import { useShopContext } from "../context";
import { useSelectedStore, useSelectedStoreOptional } from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import { Theme, useMediaQuery } from "@mui/material";

export default function ShopBanner() {
	const { brandInfo, isEMenu, menuInStoreService } = useShopContext();
	const selectedStore = useSelectedStoreOptional();

	const menuInStore = menuInStoreService.menuInStore;

	const isDownSm = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

	const computedShopImageSource = () => {
		if (selectedStore?.banners?.length && selectedStore?.banners?.length === 1) {
			if (selectedStore.banners[0].image_path) {
				return `${ApiUrl}/${selectedStore.banners[0].image_path}`;
			}
		}

		return brandInfo.image_url || ShopImage;
	};

	const computedBanner = () => {
		const handleClick = () => {
			if (selectedStore?.banners?.length && selectedStore?.banners?.length === 1) {
				if (selectedStore.banners[0].url) {
					const url = selectedStore.banners[0].url;
					if (url.startsWith(window.location.origin)) {
						const paramsObject: Record<string, string> = {};
						try {
							const urlLink = new URL(url);
							const params = new URLSearchParams(urlLink.search);

							params.forEach((value, key) => {
								paramsObject[key] = value;
							});
						} catch (ex) {
							console.log(ex);
						}
						const path = url.replace(window.location.origin, "");
						changePage(path, paramsObject);
						return;
					}
					window.open(url, "_blank");
				}
			}
		};

		if (selectedStore?.banners?.length && selectedStore?.banners?.length > 1) {
			return <Banners />;
		}

		const singleBannerWithLink =
			selectedStore?.banners?.length &&
			selectedStore?.banners?.length === 1 &&
			!!selectedStore?.banners[0].url;

		return (
			<img
				src={computedShopImageSource()}
				alt={selectedStore?.name}
				className={`img-fluid w-100 ${singleBannerWithLink ? "cursor-pointer" : ""}`}
				onClick={handleClick}
			/>
		);
	};

	return (
		<div
			className={"shop-main-image-wrapper d-flex flex-column mb-2 mx-md-1 mx-lg-0"}
			style={{ flex: 1 }}
		>
			<div className={"d-flex align-items-center "} style={{ flex: 1, height: "100%" }}>
				{computedBanner()}
			</div>

			{menuInStore && (
				<div
					className={"menu-image-overlay " + (isDownSm ? "rounded-0" : "")}
					style={{ zIndex: 9 }}
				></div>
			)}

			<div className="bottom-left-text " style={{ lineHeight: "1.2", zIndex: 9 }}>
				<div className="d-flex">
					{!!menuInStore && !isEMenu && (
						<div>
							<div className="fs-2 ">{menuInStore.comment}</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

function Banners() {
	const selectedStore = useSelectedStore();

	const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);

	const handleCarouselItemClick = (url: string | null) => {
		if (url) {
			if (url.startsWith(window.location.origin)) {
				const paramsObject: Record<string, string> = {};
				try {
					const urlLink = new URL(url);
					const params = new URLSearchParams(urlLink.search);

					params.forEach((value, key) => {
						paramsObject[key] = value;
					});
				} catch (ex) {
					console.log(ex);
				}
				const path = url.replace(window.location.origin, "");
				changePage(path, paramsObject);
				return;
			}
			window.open(url, "_blank");
		}
	};

	if (!isFirstImageLoaded) {
		// noinspection JSUnusedGlobalSymbols
		return (
			<img
				key={"loading"}
				src={`${ApiUrl}/${selectedStore.banners[0].image_path}`}
				alt={selectedStore.banners[0].image_path}
				className={"img-fluid w-100"}
				onLoad={() => setIsFirstImageLoaded(true)}
				style={{ display: "none" }}
			/>
		);
	}

	return (
		<Carousel
			autoPlay={true}
			navButtonsAlwaysVisible
			duration={500}
			sx={{ width: "100%" }}
			interval={5000}
			indicatorContainerProps={{
				style: {
					display: "none",
				},
			}}
		>
			{selectedStore.banners.map(banner => (
				<img
					src={`${ApiUrl}/${banner.image_path}`}
					alt={banner.image_path}
					key={banner.image_path}
					onClick={() => handleCarouselItemClick(banner.url)}
					className={`w-100 ${banner.url ? "cursor-pointer" : ""}`}
				/>
			))}
		</Carousel>
	);
}
