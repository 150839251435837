import {NavigationButtonsProps} from "../types";
import {Box, BoxProps, Button} from "@mui/material";
import useAppContext from "../../../../useAppContext";
import {LoadingButton} from "@mui/lab";
import React, {useMemo} from "react";


export default function NavigationButtons(
    {
        boxProps,

        hidePrev = false,
        disabledPrev = false,
        prevButtonType,
        onPrevClick,
        prevButtonNode,

        hideNext = false,
        disabledNext = false,
        loadingNext,
        nextButtonType = 'submit',
        onNextClick,
        nextButtonNode,
    }: NavigationButtonsProps
) {
    const {localisation} = useAppContext()

    const computedBoxProps = useMemo((): BoxProps => {
        const defaultBoxProps: BoxProps = {
            textAlign: 'end',
            mt: 2,
            pb: 2,
        }

        if (!boxProps) {
            return defaultBoxProps
        }

        return {
            ...defaultBoxProps,
            ...boxProps,
        }

    }, [boxProps])

    return (
        <Box {...computedBoxProps}>
            {!hidePrev && (
                <Button
                    type={prevButtonType}
                    disabled={disabledPrev}
                    onClick={onPrevClick}
                >
                    {prevButtonNode || localisation.orders.stepPrev}
                </Button>
            )}

            {!hideNext && (
                <LoadingButton
                    loading={loadingNext}
                    variant={'contained'}
                    type={nextButtonType}
                    disabled={disabledNext}
                    onClick={onNextClick}
                    sx={{ml: 2}}
                >
                    {nextButtonNode || localisation.orders.stepNext}
                </LoadingButton>
            )}
        </Box>
    )
}
