import { AuthorisationComponentProps } from "../../../types";
import useAppContext from "../../../../../useAppContext";
import { memo, useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { oauthLoginState } from "../../options/state";
import { OAuthWidgetMessage } from "../../../../../features/OAuthWidget/OAuthWidget";
import { Alert, Box, CircularProgress } from "@mui/material";
import OAuthOptionsButtons from "./OAuthOptionsButtons";

function OAuthOptions(props: AuthorisationComponentProps) {
	const {
		localisation: { auth },
		authService: { onLogin, setShowAuthorisationModal },
		oauthIframe,
	} = useAppContext();
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const setOAuthLogin = useSetRecoilState(oauthLoginState);

	const handleIframeMessage = useCallback(
		(event: MessageEvent) => {
			if (event.data) {
				const message = event.data as OAuthWidgetMessage;
				if (message.fromOAuth) {
					if (message.isLoading) {
						return setIsLoading(true);
					}
					setIsLoading(false);
					if (!message.noData) {
						if (message.isNewUser) {
							setOAuthLogin({
								oauthToken: message.token || "",
								firstName: message.firstName || "",
								lastName: message.lastName || "",
								email: message.email || "",
							});
							props.form.startOAuthConsent();
						} else {
							setError(null);
							if (message.token && message.token_type) {
								setShowAuthorisationModal(false);
								onLogin({
									token: message.token,
									token_type: message.token_type,
								});
								props.onLoginCallback && props.onLoginCallback();
								return;
							}
							setError(message.error || auth.unknownError);
						}
					}
				}
			}
		},
		[auth.unknownError, onLogin, props.form, setShowAuthorisationModal, setOAuthLogin]
	);

	useEffect(() => {
		window.addEventListener("message", handleIframeMessage);
		setError(null);

		return () => {
			window.removeEventListener("message", handleIframeMessage);
		};
	}, [handleIframeMessage]);

	return (
		<>
			{!!error && (
				<Alert severity={"error"} sx={{ my: 2 }}>
					{error}
				</Alert>
			)}
			{isLoading && (
				<Box my={2}>
					<Alert
						severity={"info"}
						icon={<CircularProgress style={{ width: "18px", height: "18px" }} />}
					>
						{auth.oauthLoadingText}
					</Alert>
				</Box>
			)}

			<OAuthOptionsButtons
				googleIframeSrc={oauthIframe.computedIframeGoogleSource}
				appleIframeSrc={oauthIframe.computedIframeAppleSource}
			/>
		</>
	);
}

export default memo(OAuthOptions);
