import { useState, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import useAppContext from "../../../useAppContext";
import EditPassword from "./forms/EditPassword";
import EditEmail from "./forms/EditEmail";
import { ThemeModal } from "../../../helpers/ThemeComponents";
import EditMessanger from "./forms/EditMessanger";
import MessangerIcon from "../../../features/MessangerIcon";
import { useMessangerName, useUserMessangerIdString } from "../../../hooks/messanger";

export default function ChangeDataItems() {
	const {
		bot,
		authService: { user },
		localisation: { auth, profile },
	} = useAppContext();

	const messangerName = useMessangerName(bot?.bot_type);
	const userMessangerIdString = useUserMessangerIdString(bot?.bot_type);

	return (
		<Box sx={{ textAlign: "left" }} className={"pt-2"}>
			<Typography
				variant={"h5"}
				sx={{ mb: 0, fontWeight: "bold" }}
				className={"border-bottom"}
			>
				{profile.authAndSecurity}
			</Typography>

			<ChangeDataItem
				buttonTextOrNode={
					<Box sx={{ display: "flex", width: "100%" }}>
						<LockOutlinedIcon sx={{ mr: 2, color: "primary.main" }} />
						<Box sx={{ alignSelf: "center" }}>{profile.passwordHeader}</Box>
					</Box>
				}
				dialogTitle={auth.changePasswordHeader}
				dialogContent={<EditPassword />}
			/>

			<ChangeDataItem
				buttonTextOrNode={
					<Box sx={{ display: "flex", width: "100%" }}>
						<EmailOutlinedIcon sx={{ mr: 2, color: "primary.main" }} />
						<Box sx={{ alignSelf: "center" }}>
							{user?.email || profile.notLinkedText}
						</Box>
					</Box>
				}
				dialogTitle={auth.changeEmailHeader}
				dialogContent={<EditEmail />}
			/>

			{!!bot && (
				<ChangeDataItem
					buttonTextOrNode={
						<Box sx={{ display: "flex", width: "100%" }}>
							<MessangerIcon botType={bot.bot_type} />
							<Box sx={{ alignSelf: "center" }}>
								{userMessangerIdString || profile.notLinkedText}
							</Box>
						</Box>
					}
					dialogTitle={auth.linkUnlinkHeader.replace("{messanger}", messangerName)}
					dialogContent={<EditMessanger externalLoginType={bot.bot_type} />}
					isDisabled={!!window.Telegram.WebApp.initDataUnsafe.user}
				/>
			)}
		</Box>
	);
}

interface IChangeDataItemProps {
	buttonTextOrNode: string | ReactNode;
	icon?: ReactNode;
	dialogContent: ReactNode;
	dialogTitle: string;
	isDisabled?: boolean;
}

function ChangeDataItem(props: IChangeDataItemProps) {
	const [show, setShow] = useState<boolean>(false);

	const handleClick = () => {
		if (props.isDisabled) return;
		setShow(true);
	};

	return (
		<>
			<Box
				sx={{ display: "flex", py: 1, cursor: props.isDisabled ? "default" : "pointer" }}
				className={"border-bottom"}
				onClick={handleClick}
			>
				<Box sx={{ width: "100%", alignSelf: "center" }}>{props.buttonTextOrNode}</Box>
				{!props.isDisabled && (
					<Box sx={{ ml: "auto" }}>
						{!!props.icon ? (
							props.icon
						) : (
							<ChevronRightIcon
								fontSize={"large"}
								sx={{ color: "primary.main", ml: 2 }}
							/>
						)}
					</Box>
				)}
			</Box>

			<ThemeModal open={show} title={props.dialogTitle} setOpen={setShow}>
				{props.dialogContent}
			</ThemeModal>
		</>
	);
}
