export const PaymentLocale = {
	invalidPaymentModeError: "web invalid payment mode error",
	noTemplateForTemplatePaymentError: "web no template for template payment error",
	invoiceTemplateWithPositionStepHeader:
		"web payment invoice template with positions step header",
	invoiceEnterAmountStepHeader: "web payment invoice enter amount step header",
	makingInvoiceHeader: "web payment making invoice header",
	confirmationStepHeader: "web payment confirmation step header text",
	paymentDataStepHeaderText: "web payment data step header text",
	finalConfirmationText: "web payment final confirmation text",
	confirmationStepCheckHeaderText: "web payment confirmation step check header text",
	payStepHeaderText: "web payment pay step header text",
	providerError: "web app payment provider error",
	providerErrorShort: "payment provider error",
	orangeModalTitle: "web payment orange modal title text",
	orangePayOtherDeviceButton: "web payment orange pay other device button",
	orangePayThisDeviceButton: "web payment orange pay this device button",
	orangeScanQrText: "web payment orange scan qr text",
	orangeChooseAppText: "web payment orange choose app text",
	fastPayCreateInvoiceHeader: "web payment fast pay create invoice header",
	fastPayInvoiceForHeader: "web payment fast pay invoice for header",
	enterAmountText: "web payment enter amount text",
	amountToPayText: "web payment amount to pay text",
	friendPayedRedirectWaitText: "web payment friend payed redirect wait text",
	viewInBotButton: "web payment invoice view in bot button",
	friendPaidInvoiceText: "web payment friend paid invoice text",
	invoiceSentToFriendText: "web payment invoice sent to friend text",
	orderSentToFriendText: "web payment order sent to friend text",
	commentLabel: "invoice comment label",
	incustPayAvailableAmountText: "web incust pay available amount text",
	incustPayModalTitle: "web incust pay modal title",
	incustPayUnlimitedText: "incust pay unlimited text",
	incustPayCreditLimitText: "incust pay credit limit text",
	incustPayAccountTypeCorporateText: "incust pay account type corporate text",
	incustPay_day_limitText: "incust pay day limit text",
	incustPay_month_limitText: "incust pay month limit text",
	incustPayAvailableForPaymentText: "incust pay available for payment text",
	incustPayOnHandAmountText: "incust pay on hand amount text",
	incustPayCreditUsedText: "incust pay credit used text",
	incustPayCustomerAccountPayText: "incust pay customer account pay text",
	incustPayCorporateAccountPayText: "incust pay corporate account pay text",
	incustPaySecurityCodeLabel: "incust pay security code label",
	incustPayOdometerLabel: "incust pay odometer label",
	incustPayVehicleIdLabel: "incust pay vehicle id label",
	incustPayCustomerAccountSuccessText: "incust pay customer account success text",
	incustPayCorporateAccountSuccessText: "incust pay corporate account success text",
	incustPay_order_redirectText: "incust pay order redirect text",
	incustPay_invoice_redirectText: "incust pay invoice redirect text",
	incustPayManualRedirectButton: "incust pay manual redirect button",
	incustPayCardText: "incust pay card text",
	incustPayCardNumberInputLabel: "incust pay card number input label",
	incustPayScanCardNumberModalTitle: "incust pay scan card number modal title",
	incustPayScanVehicleIdModalTitle: "incust pay scan vehicle id modal title",
	incustPayEnterOrScanCardNumberText: "incust pay enter or scan card number text",
	incustPayCardFoundText: "incust pay card found text",
	incustPayInsufficientFundsError: "incust pay insufficient funds error",
	incust_pay_error_terminal_unauth: "incust pay error terminal unauth",
	incust_pay_error_incust_error: "incust pay error incust error",
	incust_pay_error_unknown_error: "incust pay error unknown error",
	incustPayChangeCardButton: "incust pay change card button",
	incustPayChangeAccountButton: "incust pay change account button",
	incustPayChosenAccountText: "incust pay chosen account text",
	incustPayChooseAccountText: "incust pay choose account text",
	incustPayCarEmptyCardText: "incust pay empty card error",
	cardPaymentMethodText: "payments card payment method text",
	pl24PaymentMethodText: "payments pl24 payment method text",
	tpayPaymentMethodText: "payments tpay payment method text",
	uniposPaymentMethodText: "payments unipos payment method text",
	enteredAmountItemName: "invoice entered amount item name",
	commentRequired: "web app payments comment required",
	selectPayment: "web app payments select payment",
	emptyPayments: "web app payments empty list",
	noFee: "web app no fee text",
	paymentNoLongerAvailable: "web app payments not exist payment msg",
};
