import { ReactNode, useEffect, useState, useMemo } from "react";
import { Box, Paper, Skeleton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

import useScreenService from "../../services/useScreenService";
import { useSelectedStoreContext } from "../SelectedStore/context";
import { Category } from "../../api/shop/basic/types";
import StickyBase from "../../features/StickyBase";
import ChipButton from "../../features/ChipButton";
import useAppContext from "../../useAppContext";
import useShadowInterpolation from "../../features/hooks/useShadowInterpolation";

export default function ShopSubCategories() {
	const { isMobile } = useScreenService();
	const { categoriesService } = useSelectedStoreContext();

	const [subCategories, setSubCategories] = useState<Category[] | null>(null);

	const getActiveCategory = categoriesService.getActiveCategory;
	const getCategories = categoriesService.getCategories;
	useEffect(() => {
		if (!!categoriesService.selectedCategoryId) {
			const category = getActiveCategory();
			if (category?.has_child_categories) {
				const categories = getCategories(categoriesService.selectedCategoryId);
				const filtered = categories?.filter(category => category.products_count);
				setSubCategories(filtered || []);
			} else {
				setSubCategories([]);
			}
		} else {
			const categories = getCategories("top");
			setSubCategories(categories);
		}
	}, [categoriesService, categoriesService.selectedCategoryId, getActiveCategory, getCategories]);

	if (!isMobile) return null;

	return (
		<Box
			className={"d-flex shop-hidden-scrollbar"}
			sx={{
				overflowX: "auto",
				p: 2,
				".MuiChip-root": {
					py: 1,
				},
			}}
		>
			<BackButton />
			<AllButton />
			<SubCategories subCategories={subCategories} />
		</Box>
	);
}

interface ISubCategoriesProps {
	subCategories: Category[] | null;
}

function SubCategories(props: ISubCategoriesProps) {
	const { appearance } = useAppContext();
	const { categoriesService } = useSelectedStoreContext();

	const skeletonRange = Array.from({ length: 4 }, (_, index) => index);

	return (
		<>
			{Array.isArray(props.subCategories) ? (
				<>
					{props.subCategories.map(category => (
						<StyledSubCategoryBadge
							badgeContent={category.products_count || 0}
							color="primary"
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							key={category.id}
							invisible={!appearance.computedCategoriesCountView}
						>
							<ChipButton
								noSelectBorder={true}
								mr={1}
								ml={1}
								onClick={async () => {
									await categoriesService.handleClick(category);
								}}
								selected={category.id === categoriesService.selectedCategoryId}
								color={"primary"}
								chipProps={{
									sx: {
										borderRadius: "0.7rem",
										paddingX: "4px",
										fontSize: "0.875rem",
									},
								}}
							>
								{category.name}
							</ChipButton>
						</StyledSubCategoryBadge>
					))}
				</>
			) : (
				skeletonRange.map(index => (
					<Skeleton
						key={index}
						variant={"rectangular"}
						height={31}
						width={120}
						sx={{ borderRadius: "0.7rem", mx: 1 }}
					/>
				))
			)}
		</>
	);
}

function BackButton() {
	const { categoriesService } = useSelectedStoreContext();

	const handleBack = async () => {
		if (categoriesService.selectedCategoryId) {
			const category = categoriesService.getCategoryById(
				categoriesService.selectedCategoryId
			);
			if (category) {
				if (category.father_category_id) {
					const fatherCategory = categoriesService.getCategoryById(
						category.father_category_id
					);
					if (fatherCategory) {
						return await categoriesService.handleClick(fatherCategory);
					}
				}
			}
		}

		return await categoriesService.handleClick("top");
	};

	return (
		<>
			{!!categoriesService.selectedCategoryId && (
				<Box className={"border-end"}>
					<ChipButton
						disabledHover={true}
						noSelectedColor={true}
						forceBackgroundVar={"--mui-palette-primary-mainChannel"}
						noShadow={true}
						noSelectBorder={true}
						mr={1}
						ml={0}
						onClick={handleBack}
						selected={true}
						color={"primary"}
						chipProps={{
							sx: {
								borderRadius: "50%",
								paddingX: "0rem",
								fontSize: "1rem",
								width: "1.9rem",
								height: "1.9rem",
								".MuiChip-label": {
									px: 0,
								},
							},
						}}
					>
						<ArrowBackIcon fontSize={"inherit"} />
					</ChipButton>
				</Box>
			)}
		</>
	);
}

function AllButton() {
	const {
		localisation: { global },
		appearance,
	} = useAppContext();
	const { categoriesService } = useSelectedStoreContext();

	const getCategories = categoriesService.getCategories;
	const getCategoryById = categoriesService.getCategoryById;
	const computedAllCategoriesCount = useMemo(() => {
		if (!!categoriesService.selectedCategoryId) {
			const category = getCategoryById(categoriesService.selectedCategoryId);
			return category?.products_count || 0;
		} else {
			const categories = getCategories("top");
			if (!!categories) {
				return categories.reduce((acc, category) => {
					return acc + (category.products_count || 0);
				}, 0);
			}
		}
		return 0;
	}, [categoriesService.selectedCategoryId, getCategories, getCategoryById]);

	const computedAllName = useMemo(() => {
		if (!!categoriesService.selectedCategoryId) {
			const category = getCategoryById(categoriesService.selectedCategoryId);
			if (category?.has_child_categories) {
				return `${global.all} ${category?.name}`;
			} else {
				return category?.name;
			}
		} else {
			return global.all;
		}
	}, [categoriesService.selectedCategoryId, getCategoryById, global.all]);

	return (
		<StyledSubCategoryBadge
			badgeContent={computedAllCategoriesCount}
			color="primary"
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			sx={{ py: "1px" }}
			invisible={!appearance.computedCategoriesCountView}
		>
			<ChipButton
				noSelectedColor={true}
				noSelectBorder={true}
				disabledHover={true}
				mr={1}
				ml={!!categoriesService.selectedCategoryId ? 1 : 0}
				selected={true}
				color={"primary"}
				chipProps={{
					sx: {
						borderRadius: "0.7rem",
						paddingX: "4px",
						fontSize: "0.875rem",
					},
				}}
			>
				{computedAllName}
			</ChipButton>
		</StyledSubCategoryBadge>
	);
}

interface IShopSubCategoriesAndFiltersWrapperProps {
	children: ReactNode;
}

export function ShopSubCategoriesAndFiltersWrapper(
	props: IShopSubCategoriesAndFiltersWrapperProps
) {
	return (
		<ShopSubCategoriesAndFiltersStickyWrapper>
			<Box className={"w-100 mt-0 px-1 px-md-2"}>
				<>{props.children}</>
			</Box>
		</ShopSubCategoriesAndFiltersStickyWrapper>
	);
}

function ShopSubCategoriesAndFiltersStickyWrapper(props: IShopSubCategoriesAndFiltersWrapperProps) {
	const { isMobile } = useScreenService();
	const { headerHeight, headerRef } = useAppContext();

	const [isSticky, setIsSticky] = useState(false);

	useShadowInterpolation(
		{
			elementId: "sub-categories-bar",
			options: [
				{
					triggerElId: "products-container",
					triggerCheckElId: "header-bar",
				},
			],
		},
		isMobile
	);

	const handleElementIsSticky = (isSticky: boolean) => {
		const el = headerRef.current;
		if (el) {
			setIsSticky(isSticky);
		}
	};

	return (
		<>
			{isMobile ? (
				<StickyBase
					id={"shop-sub-categories-container"}
					offset={headerHeight || 56}
					extraIsStickyClasses={"sticky-base-sub-categories"}
					callback={(isSticky: boolean) => handleElementIsSticky(isSticky)}
				>
					<Paper
						id={"sub-categories-bar"}
						className={isSticky ? "no-top-shadow" : ""}
						sx={{
							position: "sticky",
							zIndex: 1099,
							transition: "box-shadow .005s",
							top: headerHeight ? headerHeight - 1 : 56,
							backgroundColor: isSticky ? "background.paper" : "transparent",
							backgroundImage: "none",
							borderRadius: 0,
							...(window.Telegram.WebApp.initData && {
								backgroundColor: isSticky
									? "background.paper"
									: "background.default",
							}),
							pb: 1,
						}}
						elevation={isSticky ? 4 : 0}
					>
						{props.children}
					</Paper>
				</StickyBase>
			) : (
				<>{props.children}</>
			)}
		</>
	);
}

const StyledSubCategoryBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: 8,
		top: 3,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "0 4px",
	},
}));
