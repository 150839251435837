import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";

import { Product } from "../../../api/shop/basic/types";
import { AspectRatioType } from "../../../types";
import useLocale from "../../../hooks/localisation/useLocale";
import classnames from "classnames";
import ImageStub from "../../../features/ImageStub";
import { DefaultImageAspectRatio } from "../../../config";
import Share from "../../../features/Share";
import { ViewType } from "../../../services/useViewService";
import CollapsableComponent from "../../../features/CollapsableComponent";
import ProductPrice, { ProductPriceProps } from "./ProductPrice";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import ProductCardButtons, { ProductCardButtonsProps, ProductControls } from "./ProductCardButtons";
import Interweave from "../../../features/Interweave";
import useScreenService from "../../../services/useScreenService";
import useAppContext from "../../../useAppContext";

interface ProductCartProps extends GridProductCardProps {
	product: Product;
	view: ViewType;
}

export default function ProductCard({ product, view, additionalMarginBottom }: ProductCartProps) {
	const { cartService, favoritesService, productsService } = useSelectedStoreContext();

	const productImageRef = useRef<HTMLDivElement | null>(null);

	const cartProduct = cartService.getCartProduct(product.id);
	const isInCart = !!cartProduct;

	const favoriteProduct = favoritesService.getFavoriteProduct(product.id);
	const isInFavorites = !!favoriteProduct;

	const [addToCartLoading, setAddToCartLoading] = useState(false);

	const addProductToCart = async () => {
		if (isInCart) return;
		try {
			if (
				product.attribute_groups.length ||
				product.floating_sum_settings?.is_enabled ||
				product.type === "gift" ||
				product.type === "topup" ||
				product.floating_qty_enabled
			) {
				handleProductClick(true);
			} else {
				setAddToCartLoading(true);
				await cartService.addProductToCart(
					product,
					product.buy_min_quantity,
					undefined,
					productImageRef.current
				);
			}
		} finally {
			setAddToCartLoading(false);
		}
	};

	const addOrRemoveFavoritesProduct = async () => {
		if (isInFavorites)
			return await favoritesService.deleteProductFromFavorites(favoriteProduct.id);
		await favoritesService.addProductToFavorites(product.id);
	};

	const removeProductFromCart = () => cartService.deleteProductFromCart(product.id);

	const handleProductClick = (isAddToCart: boolean = false) => {
		const tab = isAddToCart && product.attribute_groups.length ? "attributes" : null;
		console.log({ tab });
		cartService.updateProductVariationsState(true, true, isAddToCart);
		productsService.selectProduct(product, tab);
	};

	if (view === "grid") {
		return (
			<GridProductCard
				productImageRef={productImageRef}
				product={product}
				isInCart={isInCart}
				addToCartLoading={addToCartLoading}
				addProductToCart={addProductToCart}
				removeProductFromCart={removeProductFromCart}
				view={view}
				cartProduct={cartProduct}
				handleProductClick={handleProductClick}
				isInFavorites={isInFavorites}
				addOrRemoveFavoritesProduct={addOrRemoveFavoritesProduct}
				additionalMarginBottom={additionalMarginBottom}
			/>
		);
	}

	return (
		<ListProductCard
			productImageRef={productImageRef}
			product={product}
			isInCart={isInCart}
			isInFavorites={isInFavorites}
			addToCartLoading={addToCartLoading}
			addProductToCart={addProductToCart}
			removeProductFromCart={removeProductFromCart}
			addOrRemoveFavoritesProduct={addOrRemoveFavoritesProduct}
			view={view}
			cartProduct={cartProduct}
			handleProductClick={handleProductClick}
		/>
	);
}

type ProductCardComponentProps = ProductCardButtonsProps &
	ProductPriceProps & {
		productImageRef: React.MutableRefObject<HTMLDivElement | null>;
		product: Product;
		handleProductClick: (isAddToCart?: boolean) => any;
		view: ViewType;
	};

export type GridProductCardProps = {
	additionalMarginBottom?: string;
};

type GridProductCardComponentProps = ProductCardComponentProps & GridProductCardProps;

function GridProductCard(props: GridProductCardComponentProps) {
	const { appearance } = useAppContext();
	const { productsService } = useSelectedStoreContext();
	const locale = useLocale("cart");
	const globalLocale = useLocale("global");
	const { isMobile } = useScreenService();

	return (
		<Box
			className={"shop-product-card"}
			sx={{
				display: "flex",
				flexDirection: "column",
				borderRadius: ".5rem",
				cursor: "pointer",
				height: "100%",
				boxShadow: 2,
				"&:hover": {
					boxShadow: 5,
				},
				backgroundImage: "var(--mui-overlays-1)",
				opacity: props.product.is_available ? 100 : 65,
			}}
			onClick={() => props.handleProductClick()}
		>
			<div
				ref={props.productImageRef}
				className={"cursor-pointer"}
				onClick={() => props.handleProductClick()}
			>
				<ProductImage
					product={props.product}
					ratio={
						appearance.appearanceState?.product_image_aspect_ratio_converted ||
						appearance.appearanceQuery?.product_image_aspect_ratio_converted
					}
					view={"grid"}
				/>
			</div>

			<Box
				flex={1}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"space-between"}
				px={2}
				pb={2}
			>
				<div>
					{!props.product.is_available && (
						<div className={"text-danger text-center"}>{locale.notAvailable}</div>
					)}

					<Typography
						variant={"h6"}
						lineHeight={"normal"}
						fontWeight={"bold"}
						display={"flex"}
						flexWrap={"wrap"}
						mt={2}
						sx={{
							opacity: props.product.is_available ? 100 : 65,
							wordBreak: "break-word",
						}}
					>
						<Interweave className={"me-1 flex-1"} content={props.product.name} />

						<div className={"fs-5"}>
							<Share
								copyDisabled={false}
								url={productsService.getProductLink(props.product.id)}
								title={props.product.name}
								description={props.product.description}
							/>
						</div>
					</Typography>
				</div>

				<Box
					display={"flex"}
					justifyContent={
						(props.product.price === 0 ||
							props.product.type === "info" ||
							(props.product.type === "gift" && props.product.price === 0)) &&
						!isMobile
							? "end"
							: "space-between"
					}
					alignItems={"center"}
					flexWrap={"wrap"}
					mt={{ xs: 2, sm: 4 }}
					gap={2}
					mb={0}
				>
					{props.product.price > 0 && (
						<Box>
							<ProductPrice
								product={props.product}
								cartProduct={props.cartProduct}
								isInCart={props.isInCart}
								isCard={true}
							/>
							{props.product.type === "gift" && (
								<Typography variant={"body2"} color={"text.secondary"}>
									{globalLocale.bonuses}
								</Typography>
							)}
						</Box>
					)}

					<ProductCardButtons
						product={props.product}
						cartProduct={props.cartProduct}
						isInCart={props.isInCart}
						addToCartLoading={props.addToCartLoading}
						addProductToCart={props.addProductToCart}
						removeProductFromCart={props.removeProductFromCart}
						isInFavorites={props.isInFavorites}
						addOrRemoveFavoritesProduct={props.addOrRemoveFavoritesProduct}
						isCard={true}
						isGridView={true}
					/>
				</Box>
			</Box>
		</Box>
	);
}

function ListProductCard(props: ProductCardComponentProps) {
	const { appearance } = useAppContext();
	const { productsService } = useSelectedStoreContext();

	const [imageBoxHeight, setImageBoxHeight] = useState<number | null>(null);
	const [namePriceBoxHeight, setNamePriceBoxHeight] = useState<number | null>(null);
	const [descriptionBoxHeight, setDescriptionBoxHeight] = useState<number | null>(null);

	const namePriceBoxRef = useRef<HTMLDivElement | null>(null);
	const descriptionRef = useRef<HTMLParagraphElement | null>(null);

	useEffect(() => {
		let elementsToObserve: HTMLElement[] = [];

		const imageBoxEl = props.productImageRef.current;
		if (imageBoxEl) {
			elementsToObserve.push(imageBoxEl);
		}

		const namePriceBoxEl = namePriceBoxRef.current;
		if (namePriceBoxEl) {
			elementsToObserve.push(namePriceBoxEl);
		}

		const descriptionEl = descriptionRef.current;
		if (descriptionEl) {
			elementsToObserve.push(descriptionEl);
		}

		if (elementsToObserve.length) {
			const observer = new ResizeObserver(entries => {
				entries.forEach(entry => {
					const height = entry.target.getBoundingClientRect().height;
					if (entry.target.classList.contains("image-box")) {
						setImageBoxHeight(height);
					} else if (entry.target.classList.contains("name-price-box")) {
						setNamePriceBoxHeight(height);
					} else if (entry.target.classList.contains("description-box")) {
						setDescriptionBoxHeight(height);
					}
				});
			});

			elementsToObserve.forEach(el => observer.observe(el));

			return () => {
				elementsToObserve.forEach(el => observer.unobserve(el));
				observer.disconnect();
			};
		}
	}, [props.productImageRef]);

	const locale = useLocale("cart");
	const globalLocale = useLocale("global");

	const collapsedSize = useMemo(() => {
		if (
			imageBoxHeight &&
			namePriceBoxHeight &&
			descriptionBoxHeight &&
			imageBoxHeight > namePriceBoxHeight + descriptionBoxHeight
		) {
			return null;
		}

		let lineHeight;

		const descriptionEl = descriptionRef.current;
		if (descriptionEl) {
			lineHeight = parseFloat(getComputedStyle(descriptionEl).lineHeight.slice(0, 2));
		} else {
			lineHeight = 0;
		}

		let byLeftSize = 0;

		if (namePriceBoxHeight) {
			byLeftSize = namePriceBoxHeight + lineHeight * 4;
		}

		if (imageBoxHeight && imageBoxHeight > byLeftSize) {
			if (!lineHeight) {
				return null;
			}
			return Math.floor(imageBoxHeight + lineHeight - (imageBoxHeight % lineHeight));
		}

		return byLeftSize || namePriceBoxHeight || 24 * 4;
	}, [imageBoxHeight, namePriceBoxHeight, descriptionBoxHeight]);

	const cardButtons = (
		<Box
			display={"flex"}
			gap={{ xs: 1, sm: 2 }}
			alignItems={"center"}
			className={"fs-3"}
			mt={2}
			flexWrap={"wrap"}
		>
			<Share
				copyDisabled={false}
				url={productsService.getProductLink(props.product.id)}
				title={props.product.name}
				description={props.product.description}
			/>

			<ProductCardButtons
				product={props.product}
				cartProduct={props.cartProduct}
				isInCart={props.isInCart}
				addToCartLoading={props.addToCartLoading}
				addProductToCart={props.addProductToCart}
				removeProductFromCart={props.removeProductFromCart}
				isInFavorites={props.isInFavorites}
				addOrRemoveFavoritesProduct={props.addOrRemoveFavoritesProduct}
				isCard={true}
			/>
			{props.isInCart &&
				(productsService.canAddProductWithAnotherAttributes(props.product) ||
					props.product.floating_sum_settings?.is_enabled ||
					props.product.floating_qty_enabled) && (
					<ProductControls product={props.product} isListView={true} />
				)}
		</Box>
	);

	return (
		<Box
			px={1}
			pt={2}
			pb={3}
			my={2}
			width={"100%"}
			className={"shop-product-card m-0 border-top cursor-pointer"}
			onClick={() => props.handleProductClick()}
		>
			<CollapsableComponent
				collapsedSize={collapsedSize}
				sx={{ width: "100%" }}
				log={props.product.id === 31572}
			>
				<Box
					ref={props.productImageRef}
					className={"media image-box"}
					sx={{
						width: { xs: "35%", sm: "30%", lg: "25%" },
						opacity: props.product.is_available ? 100 : 65,
						pt: 1,
						pl: 2,
						pb: 1,
						float: "right",
					}}
				>
					<ProductImage
						product={props.product}
						ratio={
							appearance.appearanceState?.product_image_aspect_ratio_converted ||
							appearance.appearanceQuery?.product_image_aspect_ratio_converted
						}
						view={"list"}
					/>

					{!props.product.is_available && (
						<Typography color={"danger"} mt={2} textAlign={"center"}>
							{locale.notAvailable}
						</Typography>
					)}
				</Box>

				<Box>
					<div
						className={classnames({
							"opacity-65": !props.product.is_available,
						})}
					>
						<div ref={namePriceBoxRef} className={"name-price-box"}>
							<Typography
								variant={"h6"}
								lineHeight={"normal"}
								fontWeight={"bold"}
								mb={1}
								sx={{
									opacity: props.product.is_available ? 100 : 65,
									wordBreak: "break-word",
								}}
							>
								<Interweave
									className={"me-1 flex-1"}
									content={props.product.name}
								/>
							</Typography>

							{props.product.price > 0 && (
								<Box>
									<ProductPrice
										product={props.product}
										cartProduct={props.cartProduct}
										isInCart={props.isInCart}
										isCard={true}
									/>
									{props.product.type === "gift" && (
										<Typography variant={"body2"} color={"text.secondary"}>
											{globalLocale.bonuses}
										</Typography>
									)}
								</Box>
							)}
						</div>

						{props.product.description && (
							<Typography
								variant={"body1"}
								ref={descriptionRef}
								className={"description-box"}
							>
								<Interweave content={props.product.description} />
							</Typography>
						)}
					</div>
				</Box>

				{collapsedSize === null && cardButtons}
			</CollapsableComponent>

			{collapsedSize !== null && cardButtons}
		</Box>
	);
}

type ProductImageProps = {
	product: Product;
	ratio?: AspectRatioType | null | undefined;
	view: ViewType;
};

function ProductImage(props: ProductImageProps) {
	const imageUrl = props.product.thumbnail_url || props.product.image_url;
	if (imageUrl) {
		return (
			<img
				className={"shop-product-card-image"}
				src={imageUrl}
				alt={props.product.name}
				style={{
					aspectRatio: props.ratio ? `${props.ratio[0]} / ${props.ratio[1]}` : 1,
				}}
			/>
		);
	}

	const ratio = props.ratio || DefaultImageAspectRatio;

	if (props.view === "grid") {
		return <ImageStub ratio={ratio} boxProps={{ className: "shop-product-card-image" }} />;
	}

	return <></>;
}
