import { ThemeModal } from "../../../../../helpers/ThemeComponents";
import { IncustPayAccountData, IncustPayCardModalProps, IncustPayDataService } from "../types";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	IncustPayCardInfo,
	IncustPayConfiguration,
	IncustPayDataError,
} from "../../../../../api/shop/payments/types";
import { Alert, Box, IconButton, Link, Typography } from "@mui/material";
import useAppContext from "../../../../../useAppContext";
import f from "../../../../../helpers/formatText";
import api from "../../../../../api";
import InputWithEndButton from "../../../../../features/InputWithEndButton";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrScanner } from "@yudiel/react-qr-scanner";
import SendIcon from "@mui/icons-material/Send";
import { SetState } from "../../../../../types";
import IncustPayAccount from "../IncustPayAccount";
import useIncustPayDataService from "../useIncustPayDataService";
import { incustPayRedirect } from "../helpers";
import IncustPayError from "../IncustPayError";
import IncustPayButton from "../IncustPayButton";
import useUnifiedSpecialAccountData, {
	UnifiedSpecialAccountData,
} from "../useUnifiedSpecialAccountData";
import CloseIcon from "@mui/icons-material/Close";
import Interweave from "../../../../../features/Interweave";
import useLocalisation from "../../../../../hooks/localisation/useLocalisation";

export default function IncustPayCardModal({
	modalData: propsModalData,
	noModal,
	...props
}: IncustPayCardModalProps) {
	const [prevModalData, setPrevModalData] = useState(propsModalData);

	const [internalCardInfo, internalSetCardInfo] = useState<IncustPayCardInfo | null>(null);
	const [internalSelectedAccount, internalSetSelectedAccount] =
		useState<IncustPayAccountData | null>(null);

	const incustPayDataService = useIncustPayDataService();

	const cardInfo = useMemo(() => {
		if (props.externalCardInfo !== undefined) {
			return props.externalCardInfo;
		}
		return internalCardInfo;
	}, [internalCardInfo, props.externalCardInfo]);

	const setCardInfo = useMemo(() => {
		if (props.externalSetCardInfo) {
			return props.externalSetCardInfo;
		}
		return internalSetCardInfo;
	}, [props.externalSetCardInfo]);

	const selectedAccount = useMemo(() => {
		if (props.externalSelectedAccount !== undefined) {
			return props.externalSelectedAccount;
		}
		return internalSelectedAccount;
	}, [internalSelectedAccount, props.externalSelectedAccount]);

	const setSelectedAccount = useMemo(() => {
		if (props.externalSelectedAccount !== undefined) {
			return props.setExternalSelectedAccount;
		}
		return internalSetSelectedAccount;
	}, [props.externalSelectedAccount, props.setExternalSelectedAccount]);

	useEffect(() => {
		if (propsModalData) {
			setPrevModalData(propsModalData);
		}
	}, [propsModalData]);

	useEffect(() => {
		if (cardInfo?.incust_pay_data?.total_accounts_count === 1) {
			let newData: IncustPayAccountData | null = null;

			if (cardInfo?.incust_pay_data.specials.length === 1) {
				newData = {
					type: "special-account",
					special: cardInfo?.incust_pay_data.specials[0],
					incust_pay_configuration: cardInfo.incust_pay_data.incust_pay_configuration,
				};
			} else if (cardInfo?.incust_pay_data.corporate_special_accounts_access.length === 1) {
				newData = {
					type: "corporate-special-account-access",
					corporate_special_account_access:
						cardInfo?.incust_pay_data.corporate_special_accounts_access[0],
					incust_pay_configuration: cardInfo.incust_pay_data.incust_pay_configuration,
				};
			}

			setSelectedAccount &&
				setSelectedAccount(prev => {
					if (!newData || !prev) return newData;

					if (
						prev.type === newData.type &&
						prev.special?.id === newData.special?.id &&
						prev.corporate_special_account_access?.id ===
							newData.corporate_special_account_access?.id &&
						// objects, not ids should be compared due to possible name and card_payment_enabled diff
						prev.incust_pay_configuration === newData.incust_pay_configuration
					) {
						return prev;
					}
					return newData;
				});
		}
	}, [cardInfo, setSelectedAccount]);

	useEffect(() => {
		if (
			!cardInfo ||
			(selectedAccount?.type === "special-account" &&
				!cardInfo.incust_pay_data?.specials.some(
					x => x.id === selectedAccount.special.id
				)) ||
			(selectedAccount?.type === "corporate-special-account-access" &&
				!cardInfo.incust_pay_data?.corporate_special_accounts_access.some(
					x => x.id === selectedAccount.corporate_special_account_access.id
				))
		) {
			setSelectedAccount && setSelectedAccount(null);
		}
	}, [selectedAccount, cardInfo, setSelectedAccount]);

	const { reset } = incustPayDataService;
	useEffect(() => {
		reset();
	}, [reset, selectedAccount]);

	const modalData = propsModalData || prevModalData;

	const open = !!propsModalData;
	useEffect(() => {
		if (open) {
			setCardInfo(null);
		}
	}, [open, setCardInfo]);

	const specialAccountData = useUnifiedSpecialAccountData(selectedAccount);

	return (
		<>
			{!noModal ? (
				<ThemeModal
					fullWidth
					maxWidth={"xs"}
					open={open}
					onClose={() => {
						if (incustPayDataService.isPayed) {
							incustPayRedirect(props.type, {
								selectedStoreId: props.selectedStoreId,
								orderId: props.orderId,
								orderToken: props.orderToken,
								invoiceToken: props.invoiceToken,
							});
						} else {
							props.setModalData(null);
						}
					}}
					replaceTitle
					title={
						<IncustPayCardTitle
							cardInfo={cardInfo}
							setCardInfo={setCardInfo}
							modalData={modalData}
							specialAccountData={specialAccountData}
						/>
					}
					PaperProps={{
						sx: {
							margin: "8px",
							width: "calc(100% - 16px)",
						},
					}}
					contentProps={{
						sx: {
							p: 0,
						},
					}}
				>
					{modalData && setSelectedAccount && (
						<ModalContentIncustCard
							modalData={modalData}
							cardInfo={cardInfo}
							setCardInfo={setCardInfo}
							selectedAccount={selectedAccount}
							setSelectedAccount={setSelectedAccount}
							incustPayDataService={incustPayDataService}
							{...props}
						/>
					)}
				</ThemeModal>
			) : (
				modalData &&
				setSelectedAccount && (
					<ModalContentIncustCard
						modalData={modalData}
						cardInfo={cardInfo}
						setCardInfo={setCardInfo}
						selectedAccount={selectedAccount}
						setSelectedAccount={setSelectedAccount}
						incustPayDataService={incustPayDataService}
						{...props}
					/>
				)
			)}
		</>
	);
}

type ModalContentProps = IncustPayCardModalProps & {
	modalData: IncustPayConfiguration;

	cardInfo: IncustPayCardInfo | null;
	setCardInfo: SetState<IncustPayCardInfo | null>;

	selectedAccount: IncustPayAccountData | null;
	setSelectedAccount: SetState<IncustPayAccountData | null>;

	incustPayDataService: IncustPayDataService;
};

export function ModalContentIncustCard({
	modalData,
	setModalData,
	cardInfo,
	setCardInfo,
	selectedAccount,
	setSelectedAccount,
	...props
}: ModalContentProps) {
	if (selectedAccount) {
		return (
			<AccountSelected
				selectedAccount={selectedAccount}
				setSelectedAccount={setSelectedAccount}
				cardInfo={cardInfo}
				setCardInfo={setCardInfo}
				{...props}
			/>
		);
	}

	if (cardInfo) {
		return (
			<ChooseAccount
				cardInfo={cardInfo}
				setSelectedAccount={setSelectedAccount}
				amountToPay={props.amountToPay}
			/>
		);
	}

	// if (!props.paymentToken) return null

	return <IncustCardInputComponent modalData={modalData} onCardFound={setCardInfo} />;
}

interface ScanIncustCardComponent {
	modalData: IncustPayConfiguration;
	onCardFound: (data: IncustPayCardInfo) => any;
}

function IncustCardInputComponent({ modalData, onCardFound }: ScanIncustCardComponent) {
	const { localisation } = useAppContext();

	const [showScanModal, setShowScanModal] = useState(false);

	const [error, setError] = useState<IncustPayDataError | string | null>(null);
	const [cardNumberInputValue, setCardNumberInputValue] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	const findCardByNumber = useCallback(
		async (cardNumber: string) => {
			try {
				setIsLoading(true);
				console.log("*** MODAL DATA", modalData);
				const cardInfo = await api.shop.payments
					.getIncustPayCardInfo(modalData.payment_settings_id, cardNumber)
					.then(response => response.data);
				if (cardInfo.is_error) {
					setError(cardInfo.incust_pay_data_error);
				} else if (cardInfo.incust_pay_data.total_accounts_count === 0) {
					setError(
						f(localisation.payment.incustPayCarEmptyCardText, {
							card_number: cardInfo.card_number,
						})
					);
				} else {
					onCardFound(cardInfo);
				}
			} catch (err: any) {
				setError(err?.response?.data?.detail || localisation.global.errUnknown);
			} finally {
				setIsLoading(false);
			}
		},
		[
			localisation.global.errUnknown,
			localisation.payment.incustPayCarEmptyCardText,
			modalData,
			onCardFound,
		]
	);

	return (
		<Box p={3} pb={4}>
			<Typography fontWeight={"bold"} mb={2}>
				{f(localisation.payment.incustPayEnterOrScanCardNumberText, {
					name: modalData.name,
				})}
			</Typography>

			<form
				onSubmit={event => {
					event.preventDefault();
					event.stopPropagation();
					if (cardNumberInputValue) {
						setError(null);
						findCardByNumber(cardNumberInputValue).then();
					}
				}}
			>
				<InputWithEndButton
					textFieldProps={{
						sx: { label: { pr: "79px" } },
						fullWidth: true,
						name: "incustPayCardNumber",
						label: f(localisation.payment.incustPayCardNumberInputLabel, {
							name: modalData.name,
						}),
						value: cardNumberInputValue,
						onChange: e => setCardNumberInputValue(e.target.value),
						InputProps: {
							endAdornment: (
								<>
									{cardNumberInputValue && (
										<IconButton
											aria-label={"clear input"}
											onClick={() => setCardNumberInputValue("")}
											sx={{ p: 0.25 }}
										>
											<CloseIcon />
										</IconButton>
									)}
									<IconButton
										onClick={() => setShowScanModal(true)}
										sx={{ p: 0.75, mr: 0.25 }}
									>
										<QrCodeScannerIcon aria-label={"scan qr"} />
									</IconButton>
								</>
							),
						},
					}}
					isLoading={isLoading}
					endButtonProps={{ type: "submit", disabled: !cardNumberInputValue }}
					endButtonNode={<SendIcon />}
				/>
			</form>

			{error && (
				<Alert severity={"error"} sx={{ mt: 2 }}>
					<Interweave
						content={
							typeof error === "string"
								? error
								: f(localisation.payment[`incust_pay_error_${error.error_type}`], {
										incust_pay_name: error.incust_pay_configuration.name,
										error_detail: error.error_detail || "no-detail",
									})
						}
					/>
				</Alert>
			)}

			<ThemeModal
				open={showScanModal}
				setOpen={setShowScanModal}
				title={localisation.payment.incustPayScanCardNumberModalTitle}
			>
				<QrScanner
					containerStyle={{
						width: "min(350px, calc(100vw-16px)",
						height: "min(350px, calc(100vw-16px)",
						margin: 0,
					}}
					onDecode={result => {
						setCardNumberInputValue(result);
						setShowScanModal(false);
						findCardByNumber(result).then();
					}}
					onError={error => console.log(error?.message)}
					scanDelay={1000}
				/>
			</ThemeModal>
		</Box>
	);
}

interface AccountSelectedProps extends Omit<ModalContentProps, "modalData" | "setModalData"> {
	selectedAccount: IncustPayAccountData;
}

function AccountSelected(props: AccountSelectedProps) {
	const { localisation } = useAppContext();

	return (
		<IncustPayAccount
			accountData={props.selectedAccount}
			changeAccountButtonNode={
				(props.cardInfo?.incust_pay_data?.total_accounts_count ?? 0) > 1 && (
					<Link
						px={1}
						role={"button"}
						variant={"body2"}
						onClick={() => props.setSelectedAccount(null)}
					>
						{localisation.payment.incustPayChangeAccountButton}
					</Link>
				)
			}
			onClose={() => props.setCardInfo(null)}
			id_type={"card"}
			card_number={props.cardInfo?.card_number}
			hideCancel
			{...props}
		/>
	);
}

interface ChooseAccountProps {
	cardInfo: IncustPayCardInfo;
	setSelectedAccount: SetState<IncustPayAccountData | null>;
	amountToPay: number;
}

function ChooseAccount({ cardInfo, setSelectedAccount, amountToPay }: ChooseAccountProps) {
	const { localisation } = useAppContext();

	if (cardInfo.is_error) {
		return (
			<Box p={3}>
				<IncustPayError incust_pay_error={cardInfo.incust_pay_data_error} noBorder />
			</Box>
		);
	}

	console.log("cardInfo", cardInfo);

	return (
		<Box>
			<Typography
				px={3}
				py={"12px"}
				fontWeight={"bold"}
				borderBottom={1}
				borderColor={"divider"}
			>
				{amountToPay > 0 ? localisation.payment.incustPayChooseAccountText : ""}
			</Typography>

			{cardInfo.incust_pay_data.specials.map(special => (
				<IncustPayButton
					key={`special-${special.id}`}
					data={{
						incust_pay_configuration: cardInfo.incust_pay_data.incust_pay_configuration,
						type: "special-account",
						special: special,
					}}
					setData={setSelectedAccount}
				/>
			))}

			{cardInfo.incust_pay_data.corporate_special_accounts_access.map(access => (
				<IncustPayButton
					key={`access-${access.id}`}
					data={{
						incust_pay_configuration: cardInfo.incust_pay_data.incust_pay_configuration,
						type: "corporate-special-account-access",
						corporate_special_account_access: access,
					}}
					setData={setSelectedAccount}
				/>
			))}
		</Box>
	);
}

interface IIncustPayCardTitleProps {
	cardInfo: IncustPayCardInfo | null;
	setCardInfo: SetState<IncustPayCardInfo | null>;
	modalData: IncustPayConfiguration | null;
	specialAccountData: UnifiedSpecialAccountData | null;
}

export function IncustPayCardTitle(props: IIncustPayCardTitleProps) {
	const localisation = useLocalisation();

	return (
		<Box flex={1} display={"grid"} py={1}>
			{props.cardInfo ? (
				<>
					<Typography variant={"h6"} fontWeight={"bold"} lineHeight={1.3}>
						{f(localisation.payment.incustPayCardFoundText, {
							card_number: props.cardInfo.card_number,
						})}
					</Typography>
					{props.specialAccountData && (
						<Typography fontWeight={"bold"}>
							{props.specialAccountData.title}
						</Typography>
					)}
					<Link role={"button"} variant={"body2"} onClick={() => props.setCardInfo(null)}>
						{localisation.payment.incustPayChangeCardButton}
					</Link>
				</>
			) : (
				<Typography variant={"h6"} fontWeight={"bold"}>
					{f(localisation.payment.incustPayCardText, {
						name: props.modalData?.name || "",
					})}
				</Typography>
			)}
		</Box>
	);
}
