import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SelectStoreCardContent } from "../../../../select/SelectStoreCard";
import { CreateOrderPayload } from "../../../../../api/shop/order/types";
import { SetMergeState } from "../../../../../helpers/useMergedState";
import { useShopContext } from "../../../../context";
import CartNote from "../../../../CartNote";
import ShippingNote from "./ShippingNote";
import ShippingMethods from "./ShippingMethods";
import { useSelectedStoreContext } from "../../../../SelectedStore/context";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { OrderStepperButtons } from "../OrderStepperButtons";
import useLocale from "../../../../../hooks/localisation/useLocale";
import {
	SelectedShipmentServiceType,
	SelectedShipmentType,
} from "../../useSelectedShipmentService";
import DeliveryTime from "../DeliveryTime";
import { IShipmentPricesService } from "../../../../services/useShipmentPrices";
import formatCurrency from "../../../../../helpers/formatCurrency";
import useAppContext from "../../../../../useAppContext";
import useCurrency from "../../../../../services/useCurrencyService";
import { IAddressValid } from "../../../../../features/AddressInput/AddressInput";
import ShippingForm from "./ShippingForm/ShippingForm";
import api from "../../../../../api";
import { IGeocodingParams } from "../../../../../api/map/types";
import { IStorageAddressObject } from "../../../../../api/shop/basic/types";
import Interweave from "../../../../../features/Interweave";
import { getOrderState, saveOrderState } from "../orderSessionState";
import { IUseUpdateOrder } from "../../useUpdateOrder";

interface ShippingProps {
	computedShopImage: string;

	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;

	selectedShipmentService: SelectedShipmentServiceType;

	setPrevStep: () => void;
	setNextStep: () => void;

	computedTotalAmount: number;
	shipmentPricesService: IShipmentPricesService;
	updateService: IUseUpdateOrder;
}

export default function Shipping(props: ShippingProps) {
	const { lang, showError } = useAppContext();
	const currency = useCurrency();
	const { brandInfo, addressValue, menuInStoreService, setAddressValue } = useShopContext();

	const { selectedStore, cartService } = useSelectedStoreContext();

	const locale = useLocale("orders");

	const [showInvalidError, setShowInvalidError] = useState(false);
	const [validAddress, setValidAddress] = useState<null | IAddressValid>(null);

	const { selectedShipment, errors } = props.selectedShipmentService;

	const [showMap, setShowMap] = useState(false);

	const propsSetNextStep = props.setNextStep;

	const validateAddress = useCallback(async () => {
		if (brandInfo.is_enabled_google_maps_api) {
			if (validAddress && !validAddress.valid) {
				return false;
			} else {
				const address = api.shop.basic.getLastAddressObject();
				if (address && address.type === "google" && address.googleAddressComponents) {
					if (selectedShipment?.enabled_any_address_from_map && address.selectedFromMap) {
						return true;
					}
					console.log(
						"*** SETTED VALID ADDRESS ERROR",
						selectedShipment?.enabled_any_address_from_map
					);
					const response = await api.mapGoogle.validateAddress(
						address.googleAddressComponents
					);
					if (response && response.data) {
						setValidAddress(response.data);
						if (response.data.valid) return true;
					}
					return false;
				} else {
					const args: IGeocodingParams = {
						address: addressValue,
						language: brandInfo.default_lang || lang || "",
					};
					try {
						const response = await api.mapGoogle.geocoding(args);
						if (response && response.data && response.data.results.length > 0) {
							const validateResponse = await api.mapGoogle.validateAddress(
								response.data.results[0].address_components
							);

							if (validateResponse && validateResponse.data)
								setValidAddress(validateResponse.data);

							if (
								validateResponse &&
								validateResponse.data &&
								validateResponse.data.valid
							) {
								setAddressValue(response.data.results[0].formatted_address);
								const lastAddressObject: IStorageAddressObject = {
									type: "google",
									addressValue: response.data.results[0].formatted_address,
									addressCoords: [
										response.data.results[0].geometry.location.lng,
										response.data.results[0].geometry.location.lat,
									],
									googleAddressComponents:
										response.data.results[0].address_components,
									placeId: response.data.results[0].place_id,
									selectedFromMap: false,
								};
								api.shop.basic.saveLastAddressObject(
									JSON.stringify(lastAddressObject)
								);
								return true;
							}
						}
					} catch (ex) {
						showError(ex);
						return false;
					}
					return false;
				}
			}
		}
		return true;
	}, [
		addressValue,
		brandInfo.default_lang,
		brandInfo.is_enabled_google_maps_api,
		lang,
		setAddressValue,
		showError,
		validAddress,
		selectedShipment?.enabled_any_address_from_map,
	]);

	const updateShipment = props.updateService.updateShipment;
	const setNextStep = useCallback(async () => {
		const orderState = getOrderState();

		if (
			selectedShipment?.custom_type === "custom_shipment" &&
			selectedShipment.need_address &&
			selectedShipment?.base_type !== "pickup"
		) {
			if (!addressValue) {
				return setShowInvalidError(true);
			}

			const valid = await validateAddress();
			if (!valid) {
				return setShowInvalidError(true);
			}
		}

		if (
			selectedShipment &&
			!errors.isCriticalError &&
			(selectedShipment.custom_type !== "custom_shipment" ||
				!selectedShipment?.need_comment ||
				!!props.form.custom_shipment_comment) &&
			(selectedShipment.custom_type === "custom_shipment" ||
				selectedShipment.base_type !== "delivery" ||
				(brandInfo.is_get_order &&
					props.form.address_street &&
					props.form.address_house &&
					props.form.address_flat &&
					props.form.address_floor &&
					props.form.address_entrance) ||
				(!brandInfo.is_get_order && addressValue))
		) {
			setShowInvalidError(false);
			if (!orderState || !orderState.createdOrder) {
				propsSetNextStep();
			}
		} else {
			setShowInvalidError(true);
		}

		if (selectedShipment) {
			saveOrderState(selectedShipment.id, "shipmentId");
			if (orderState && orderState.createdOrder) {
				Promise.resolve<{ ok: boolean } | undefined>(updateShipment()).then(result => {
					if (result && result.ok) {
						propsSetNextStep();
					}
				});
			}
		}
	}, [
		selectedShipment,
		errors.isCriticalError,
		props.form.custom_shipment_comment,
		props.form.address_street,
		props.form.address_house,
		props.form.address_flat,
		props.form.address_floor,
		props.form.address_entrance,
		brandInfo.is_get_order,
		addressValue,
		validateAddress,
		propsSetNextStep,
		updateShipment,
	]);

	const getNextPriceByShipmentId = props.shipmentPricesService.getNextPriceByShipmentId;
	const calculateCartTotalSum = cartService.calculateCartTotalSum;
	const computedAlert = useMemo(() => {
		if (props.selectedShipmentService.selectedShipment) {
			const nextPrice = getNextPriceByShipmentId(
				props.selectedShipmentService.selectedShipment.id
			);
			if (nextPrice) {
				const sum = formatCurrency(
					(nextPrice.minimum_order_amount - calculateCartTotalSum()).toFixed(2),
					brandInfo?.default_lang || lang,
					currency || ""
				);
				if (nextPrice.cost_delivery === 0) {
					return locale.nextPriceFree.replace("{sum}", sum);
				}
				const nextPriceValue = formatCurrency(
					nextPrice.cost_delivery.toFixed(2),
					brandInfo?.default_lang || lang,
					currency || ""
				);
				return locale.nextPriceCost.replace("{cost}", nextPriceValue).replace("{sum}", sum);
			} else {
				if (
					props.shipmentPricesService?.computedSelectedShipmentPrice
						?.minimum_order_amount &&
					props.shipmentPricesService.computedSelectedShipmentPrice.minimum_order_amount >
						0
				) {
					if (
						props.shipmentPricesService?.computedSelectedShipmentPrice.cost_delivery > 0
					) {
						const cost = formatCurrency(
							props.shipmentPricesService.computedSelectedShipmentPrice.cost_delivery.toFixed(
								2
							),
							brandInfo?.default_lang || lang,
							currency || ""
						);
						return locale.achivedPriceCost.replace("{sum}", cost);
					} else {
						return locale.achivedPriceFree;
					}
				}
			}
		}
		return null;
	}, [
		brandInfo?.default_lang,
		calculateCartTotalSum,
		currency,
		getNextPriceByShipmentId,
		lang,
		locale.achivedPriceCost,
		locale.achivedPriceFree,
		locale.nextPriceCost,
		locale.nextPriceFree,
		props.selectedShipmentService.selectedShipment,
		props.shipmentPricesService?.computedSelectedShipmentPrice?.cost_delivery,
		props.shipmentPricesService?.computedSelectedShipmentPrice?.minimum_order_amount,
	]);

	const computedPricesError = useMemo(() => {
		if (props.shipmentPricesService.error) {
			if (
				!props.shipmentPricesService.error.internalCode ||
				props.shipmentPricesService.error.internalCode !== "address_error"
			) {
				return props.shipmentPricesService.error.text;
			}
		}
		return null;
	}, [props.shipmentPricesService.error]);

	const getShipmentPricesById = props.shipmentPricesService.getShipmentPricesById;
	const getPricesString = props.shipmentPricesService.getPricesString;
	const computedSelectedShipmentPrice = props.shipmentPricesService.computedSelectedShipmentPrice;
	const computedShipmentHeaderPricesString = useMemo(() => {
		let pricesString = "";
		if (!!computedSelectedShipmentPrice && !!selectedShipment) {
			let prices = getShipmentPricesById(selectedShipment.id);
			if (
				(!computedSelectedShipmentPrice ||
					computedSelectedShipmentPrice?.cost_delivery === 0) &&
				prices.length === 0
			) {
				if (selectedShipment?.is_paid_separately) return "";
				return locale.free;
			}
			if (computedSelectedShipmentPrice?.cost_delivery) {
				return formatCurrency(
					computedSelectedShipmentPrice?.cost_delivery.toFixed(2),
					brandInfo?.default_lang || lang,
					currency || ""
				);
			} else {
				return locale.free;
			}
		} else {
			if (!!selectedShipment) {
				pricesString = getPricesString(
					getShipmentPricesById(selectedShipment.id),
					currency || ""
				);
				if (pricesString === locale.free && selectedShipment.is_paid_separately) {
					return locale.paidSeparately;
				}
			}
		}

		return pricesString;
	}, [
		computedSelectedShipmentPrice,
		selectedShipment,
		getShipmentPricesById,
		locale.free,
		locale.paidSeparately,
		brandInfo?.default_lang,
		lang,
		currency,
		getPricesString,
	]);

	const setPricesError = props.shipmentPricesService.setError;
	useEffect(() => {
		if (!addressValue) {
			if (validAddress) setValidAddress(null);
			if (props.shipmentPricesService.error) setPricesError(null);
		}
	}, [addressValue, setPricesError, props.shipmentPricesService.error, validAddress]);

	const showShippingMethodsError = showInvalidError && !selectedShipment;

	return (
		<Box>
			<ShippingMethods
				selectedShipmentService={props.selectedShipmentService}
				showError={showShippingMethodsError}
				shipmentPricesService={props.shipmentPricesService}
				getPricesString={props.shipmentPricesService.getPricesString}
			/>

			{!!selectedShipment && (
				<ShippingHeader
					shipment={selectedShipment}
					pricesString={computedShipmentHeaderPricesString}
				/>
			)}

			<Box>
				<ShippingForm
					form={props.form}
					setForm={props.setForm}
					showInvalidError={showInvalidError}
					selectedShipmentService={props.selectedShipmentService}
					getShipmentPricesById={props.shipmentPricesService.getShipmentPricesById}
					computedSelectedShipmentHasZones={
						props.shipmentPricesService.computedSelectedShipmentHasZones
					}
					polygons={props.shipmentPricesService.computedSelectedShipmentPolygons}
					radius={props.shipmentPricesService.computedSelectedShipmentRadius}
					showMap={showMap}
					setShowMap={setShowMap}
					setValidAddress={setValidAddress}
					pricesError={props.shipmentPricesService.error}
					validAddress={validAddress}
				/>

				{props.selectedShipmentService.base_type === "pickup" && (
					<Box pl={1}>
						<div className={"theme-text fw-bold"}>
							{locale.successSelectedShopHeader}
						</div>
						<div className={"d-flex"}>
							<img
								src={props.computedShopImage}
								alt={selectedStore?.name || ""}
								className={"order-shop-img me-3"}
							/>
							<div className={"w-100"}>
								{selectedStore && <SelectStoreCardContent store={selectedStore} />}
							</div>
						</div>
					</Box>
				)}
			</Box>

			{!menuInStoreService.menuInStore?.id &&
				!(
					props.selectedShipmentService.selectedShipment?.delivery_datetime_mode ===
					"disabled"
				) && (
					<Box
						mt={
							props.selectedShipmentService.selectedShipment?.custom_type ===
							"shipment"
								? 3
								: 2
						}
					>
						<DeliveryTime
							form={props.form}
							setForm={props.setForm}
							selectedShipmentService={props.selectedShipmentService}
						/>
					</Box>
				)}

			<CartNote selectedShipmentService={props.selectedShipmentService} />

			<ShippingNote selectedShipmentService={props.selectedShipmentService} />

			<ShipmentPricesAlert
				regularAlertText={computedAlert}
				isLoading={props.shipmentPricesService.selectedPriceLoading}
				error={computedPricesError}
			/>

			{showShippingMethodsError && (
				<Typography variant={"body2"} color={"error"}>
					{locale.chooseDeliveryText}
				</Typography>
			)}

			<OrderStepperButtons
				setPrevStep={props.setPrevStep}
				setNextStep={setNextStep}
				nextDisabled={
					props.shipmentPricesService.computedDisabledShipping ||
					(validAddress && !validAddress.valid) ||
					false
				}
				isNextLoading={props.updateService.isLoading}
			/>
		</Box>
	);
}

interface IShipmentPricesAlertProps {
	regularAlertText: string | null;
	isLoading: boolean;
	error: string | null;
}

function ShipmentPricesAlert(props: IShipmentPricesAlertProps) {
	const alertRef = useRef<HTMLDivElement>(null);
	const [lastHeight, setLastHeight] = useState<null | number>(null);

	useEffect(() => {
		if (alertRef && alertRef.current && !props.isLoading) {
			const height = alertRef.current.offsetHeight;
			setLastHeight(height);
		}
	}, [alertRef, props.isLoading]);

	return (
		<>
			{!!(props.regularAlertText || props.error) && (
				<Alert
					ref={alertRef}
					severity={!!props.error ? "error" : "success"}
					variant={"outlined"}
					className={"small p-3 my-2 w-100"}
					sx={
						lastHeight && props.isLoading
							? { height: lastHeight, overflow: "hidden" }
							: {}
					}
				>
					{props.isLoading ? (
						<Box
							sx={{
								width: "100%",
								height: "100%",
								color: !!props.error ? "main.error" : "main.success",
								overflow: "hidden",
							}}
						>
							<CircularProgress
								color={!!props.error ? "error" : "success"}
								style={{
									width: "16px",
									height: "16px",
									marginTop: "1px",
									marginBottom: "1px",
								}}
							/>
						</Box>
					) : (
						<Interweave
							content={!!props.error ? props.error : props.regularAlertText}
						/>
					)}
				</Alert>
			)}
		</>
	);
}

interface IShippingHeaderProps {
	shipment: SelectedShipmentType;
	pricesString?: string | null;
}

function ShippingHeader(props: IShippingHeaderProps) {
	const {
		localisation: { orders },
	} = useAppContext();

	if (!props.shipment) return null;

	return (
		<Box sx={{ mb: 2, mt: 3, pl: 1 }} className={"border-bottom"}>
			<Typography fontWeight={"bold"} fontSize={"large"}>
				{orders.selectedShipmentMethodHeader}
			</Typography>
			<Typography variant={"body2"} fontWeight={"bold"}>
				{props.shipment.name}

				{" | "}

				{props.pricesString && props.pricesString}
				{!!(
					props.pricesString !== orders.paidSeparately &&
					props.shipment?.is_paid_separately
				) && (
					<>
						{" | "}
						{orders.paidSeparately}
					</>
				)}
			</Typography>

			{props.shipment.custom_type === "custom_shipment" && props.shipment.description && (
				<Typography variant={"body2"} color={"text.secondary"}>
					<Interweave content={props.shipment.description} />
				</Typography>
			)}
		</Box>
	);
}
