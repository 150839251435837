import {Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import {PersonalDataForm, PersonalDataStepBaseProps} from "./types";
import useAppContext from "../../../../useAppContext";
import PhoneInput from "../../../../features/PhoneInput";
import React from "react";


export default function PersonalDataStepBase(props: PersonalDataStepBaseProps){
    const {
        localisation,
    } = useAppContext()

    const handleFirstNameChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        props.setForm({first_name: e.target.value})
    const handleLastNameChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        props.setForm({last_name: e.target.value})
    const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        props.setForm({email: e.target.value})

    const isFirstNameError = props.showInvalidError && !props.form.first_name
    const isLastNameError = props.showInvalidError && !props.form.last_name

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const isEmailError = props.showInvalidError && (
        (props.isEmailRequired && !props.form.email) ||
        (!!props.form.email && !emailRegex.test(props.form.email))
    );

    const activeFieldsCount = Object.values(props.fields || {}).filter(x => x !== false).length
    const smColumnSize = activeFieldsCount % 2 === 0 ? 6 : 12

    const getAutoFocusedField = (): (keyof PersonalDataForm) | null => {
        if (props.fields?.first_name ?? true) {
            return 'first_name'
        }
        if (props.fields?.last_name ?? true) {
            return 'last_name'
        }
        if (props.fields?.email ?? true) {
            return 'email'
        }
        if (props.fields?.phone ?? true) {
            return 'phone'
        }
        return null
    }
    const autoFocused = getAutoFocusedField()

    return (
        <form onSubmit={props.onSubmit}>
            <Grid container rowSpacing={3} columnSpacing={2}>
                {props.fields?.first_name !== false && (
                    <Grid item xs={12} sm={smColumnSize}>
                        <TextField
                            required
                            size={'small'} fullWidth
                            id={'typeFirstName'} type={'text'}
                            autoFocus={autoFocused === 'first_name'}
                            label={localisation.orders.firstNameLabel}
                            value={props.form.first_name}
                            onChange={(e) => handleFirstNameChanged(e)}
                            error={isFirstNameError}
                            helperText={isFirstNameError && localisation.orders.firstNameFeedback}
                        />
                    </Grid>
                )}
                {props.fields?.last_name !== false && (
                    <Grid item xs={12} sm={smColumnSize}>
                        <TextField
                            required
                            size={'small'} fullWidth
                            id={'typeLastName'} type={'text'}
                            autoFocus={autoFocused === 'last_name'}
                            label={localisation.orders.lastNameLabel}
                            value={props.form.last_name}
                            onChange={(e) => handleLastNameChanged(e)}
                            error={isLastNameError}
                            helperText={isLastNameError && localisation.orders.lastNameFeedback}
                        />
                    </Grid>
                )}
                {props.fields?.email !== false && (
                    <Grid item xs={12} sm={smColumnSize}>
                        <TextField
                            size={'small'} fullWidth
                            required={props.isEmailRequired}
                            id={'typeEmail'} type={'email'}
                            autoFocus={autoFocused === 'email'}
                            label={localisation.orders.emailLabel}
                            value={props.form.email || ""}
                            onChange={(e) => handleEmailChanged(e)}
                            error={isEmailError}
                            helperText={isEmailError && localisation.orders.emailFeedback}
                        />
                    </Grid>
                )}
                {props.fields?.phone !== false && (
                    <Grid item xs={12} sm={smColumnSize}>
                        <PhoneInput
                            required={props.isPhoneRequired}
                            value={props.form.phone}
                            setValue={(val: string) => props.setForm({phone: val})}
                            autoFocus={autoFocused === 'phone'}
                            isValid={props.isValidPhone}
                            setIsValid={props.setIsValidPhone}
                            showInvalidError={props.showInvalidError}
                            errorText={localisation.orders.phoneFeedback}
                        />
                    </Grid>
                )}
            </Grid>
            {props.children}
        </form>
    )
}
