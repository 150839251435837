import useCurrencyInfo from "../../../../../../hooks/useCurrencyInfo";
import { AmountInputProps } from "../../../types";
import useAppContext from "../../../../../../useAppContext";
import TextField from "@mui/material/TextField";
import { InputAdornment, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useDebounced from "../../../../../../features/hooks/useDebounced";
import formatCurrency from "../../../../../../helpers/formatCurrency";

export default function AmountInput(props: AmountInputProps) {
	const { lang, localisation, brandInfo } = useAppContext();
	const currencyInfo = useCurrencyInfo(brandInfo?.default_lang || lang, props.currency);

	const [value, setValue] = useState(
		props.form.entered_amount !== null ? props.form.entered_amount.toString() : ""
	);

	useEffect(() => {
		setValue(props.form.entered_amount?.toString() || "");
	}, [props.form.entered_amount]);

	const { setForm } = props;
	const updateEnteredAmount = useDebounced(
		(value: string) => {
			if (isNaN(parseFloat(value))) {
				setForm({
					entered_amount: null,
				});
			} else if (parseFloat(value) < 0) {
				setForm({
					entered_amount: 0,
				});
			} else {
				setForm({
					entered_amount: parseFloat(value),
				});
			}
		},
		[setForm],
		500
	);

	if (props.enteredAmount) {
		return (
			<Typography
				width={"100%"}
				variant={"h6"}
				fontWeight={"bold"}
				gutterBottom
				display={"flex"}
				justifyContent={"space-between"}
			>
				<span>{localisation.payment.amountToPayText}</span>
				<span>
					{formatCurrency(
						props.enteredAmount,
						brandInfo?.default_lang || lang,
						props.currency
					)}
				</span>
			</Typography>
		);
	}

	return (
		<TextField
			required
			type={"number"}
			size={"medium"}
			variant={"outlined"}
			sx={{ mb: 2, flex: 1 }}
			label={localisation.payment.enterAmountText}
			value={value}
			onChange={e => {
				e.preventDefault();
				setValue(e.target.value);
				updateEnteredAmount(e.target.value);
			}}
			InputProps={{
				inputProps: {
					min: 0.01,
					step: "0.01",
				},
				readOnly: !!props.enteredAmount,
				[`${currencyInfo.position}Adornment`]: (
					<InputAdornment
						position={currencyInfo.position}
						sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
					>
						{currencyInfo.symbol}
					</InputAdornment>
				),
			}}
		/>
	);
}
