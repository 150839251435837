import {Button} from "@mui/material";

import {useShopContext} from "../context";
import {useSelectedStoreOptional} from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import useScreenService from "../../services/useScreenService";
import Interweave from "../../features/Interweave";


export default function BackToQrMenuButton() {
    const {
        menuInStoreService,
    } = useShopContext()

    const selectedStore = useSelectedStoreOptional()
    const {isMobile} = useScreenService()

    const menuInStore = menuInStoreService.menuInStore

    if (!menuInStore || !menuInStore.texts.active_menu.open_button) return null

    const buttonText = menuInStore.texts.active_menu.open_button

    return (
        <Button
            variant={isMobile ? 'text' : 'contained'}
            onClick={() => {
                if (window.Telegram.WebApp.initData) {
                    window.Telegram.WebApp.close()
                } else {
                    if (selectedStore?.id) {
                        changePage(`/shop/${selectedStore.id}/qr_menu`)
                    } else {
                        changePage('/shop/qr_menu')
                    }
                }
            }}
            sx={{
                width: '100%',
            }}
        >
            <Interweave content={buttonText}/>
        </Button>
    )
}
