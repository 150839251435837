import { useMemo } from "react";

import useAppContext from "../../../../useAppContext";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

interface IIncustWalletProps {
	widgetHeight?: string;
}

export function IncustWallet(props: IIncustWalletProps) {
	const { lang, brandInfo } = useAppContext();

	const { customerData } = useUserIncustCustomerData();

	const getColors = () => {
		const element = document.querySelector("body");
		if (element) {
			const computedStyle = getComputedStyle(element);

			return {
				bgColor: computedStyle.getPropertyValue("--mui-palette-background-default"),
				bgCardColor: computedStyle.getPropertyValue("--mui-palette-background-paper"),
				textCardColor: computedStyle.getPropertyValue("--mui-palette-text-primary"),
			};
		}
	};

	const computedIncustLink = useMemo(() => {
		const colors = getColors();
		const token = customerData?.token;
		const primaryColor = "";
		const secondaryColor = "";
		const bgColor = encodeURIComponent(colors?.bgColor || "");
		const bgCardColor = encodeURIComponent(colors?.bgCardColor || "");
		const textCardColor = encodeURIComponent(colors?.textCardColor || "");
		const base = `${brandInfo?.incust_data?.client_url}/widget/wallet/${brandInfo?.incust_data?.loyalty_id}?`;

		let link = `${base}auth_token=${token}`;
		link += `&primaryColor=${primaryColor}&secondaryColor=${secondaryColor}`;
		link += `&bgColor=${bgColor}&textCardColor=${textCardColor}&bgCardColor=${bgCardColor}`;
		// link += `&mcard=1` // Show or not show "My card" and "Logout" buttons
		link += `&lang=${lang}`;
		return link;
	}, [customerData, brandInfo, lang]);

	if (!brandInfo || brandInfo.loyalty_info?.loyalty_type !== "incust") return null;

	return (
		<>
			{brandInfo?.incust_data?.loyalty_id && (
				<iframe
					allow={"clipboard-write"}
					key={computedIncustLink}
					title={"incust-wallet"}
					src={computedIncustLink}
					className={"w-100"}
					style={{ height: `${props.widgetHeight ? props.widgetHeight : "300px"}` }}
				/>
			)}
		</>
	);
}
