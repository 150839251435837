import React, { useState } from "react";
import QRCode from "qrcode.react";
import { IApplyCouponInfo, IIncustCoupon, IncustTransaction } from "../../../types";
import useAppContext from "../../../../../useAppContext";
import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { CouponDesc } from "../shared/CouponDesc";
import { MediaContentSide } from "../shared/MediaContentSide";
import { BottomButtonsBar } from "../shared/BottomButtonsBar";
import Share from "../shared/Share";
import CouponAwards from "../shared/CouponAwards";

const MotionBox = motion(Box);

interface CouponDesktopFrontSideProps {
	showOnPage: null | undefined | boolean;
	containerRef: React.MutableRefObject<HTMLDivElement | null>;
	insideCoupon: boolean;
	coupon: IIncustCoupon;
	contentRef: React.MutableRefObject<HTMLDivElement | null>;
	handleIsInside: () => void;
	handleDownload: () => Promise<void>;
	disabled: boolean;
	handlePdf: () => void;
	isInWallet: boolean;
	handleApply: () => unknown;
	isAddCouponToWalletLoading: boolean;
	isApplyCouponError: boolean;
	redeemedCouponCheck: IncustTransaction | null;
	handleWallet: () => void;
	couponBenefitAmount: string;
	handleShare: () => unknown;
	shareCode: string | null;
	openShareCode: boolean;
	closeShareModal: () => unknown;
	isShareLoading: boolean;
	botUrl: string | null;
	webUrl: string;
	isShowApplyButton: boolean;
	applyCouponInfo: IApplyCouponInfo | null;
	onCloseApplyPopup: () => unknown;
	firstRender: boolean;
	setFirstRender: (value: boolean) => void;
}

export function CouponDesktopFrontSide({
	applyCouponInfo,
	botUrl,
	closeShareModal,
	containerRef,
	contentRef,
	coupon,
	couponBenefitAmount,
	handleApply,
	handleIsInside,
	handlePdf,
	handleShare,
	handleWallet,
	insideCoupon,
	isAddCouponToWalletLoading,
	isApplyCouponError,
	isInWallet,
	isShareLoading,
	isShowApplyButton,
	onCloseApplyPopup,
	openShareCode,
	redeemedCouponCheck,
	showOnPage,
	webUrl,
	firstRender,
	setFirstRender,
}: CouponDesktopFrontSideProps) {
	const {
		lang,
		authService: { user },
	} = useAppContext();
	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
	const isMd = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
	const isExternalType = coupon.type === "external";

	const [descNeedExpand, setDescNeedExpand] = useState(false);

	React.useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
		}
	}, [firstRender, setFirstRender]);

	return (
		<MotionBox
			sx={{
				...(showOnPage && { position: "relative" }),
				scrollbarWidth: "none",
				overflowY: "hidden",
			}}
			initial={
				firstRender
					? { opacity: 0.3 }
					: {
							x: "100%",
							opacity: 0.3,
						}
			}
			animate={{
				x: 0,
				opacity: 1,
			}}
			exit={{
				x: "100%",
				opacity: 0.3,
			}}
			transition={{ duration: 0.5 }}
			overflow={{ sm: "hidden" }}
		>
			<Box
				ref={containerRef}
				display={"flex"}
				flexDirection={{
					xs: "column",
					sm: "row",
				}}
				gap={{
					xs: "30px",
					sm: "10px",
				}}
				minHeight={{
					sm: "250px",
				}}
				height={"auto"}
				justifyContent={"center"}
				flexWrap={"wrap"}
			>
				<MediaContentSide
					insideCoupon={insideCoupon}
					coupon={coupon}
					lang={lang}
					user={user}
					isInWallet={isInWallet}
				/>

				<Box
					order={insideCoupon ? 1 : 2}
					flex={{ sm: 1 }}
					height={{ xs: "fit-content", sm: "auto" }}
					width={"100%"}
					display={{ xs: "flex" }}
					flexDirection={{ xs: "column", sm: "row" }}
					gap={{ xs: 4, sm: 2 }}
					alignItems={"center"}
					className={"content"}
					ref={contentRef}
					borderRadius={{
						xs: "40px 40px 0px 0px",
						sm: "150px 0px 0px 150px",
						md: "180px 0px 0px 180px",
					}}
					// boxShadow={"-2px 0 8px rgba(0, 0, 0, 0.25)"}
					p={{
						xs: "30px 20px 0px 20px",
						sm: "30px 20px 20px 20px",
						md: "20px 30px 20px 40px",
					}}
					sx={{
						width: "100%",
						textAlign: "center",
						bgcolor: `primary.main`,
						color: "primary.contrastText",
					}}
					position={"relative"}
				>
					{(!!coupon.code || !!coupon.external_code) && (
						<QRCode
							value={
								coupon.external_code?.length
									? coupon.external_code.toString()
									: coupon.code?.toString() ?? ""
							}
							size={isMd ? 100 : 80}
							style={{ margin: 0 }}
						/>
					)}
					<Box
						flex={1}
						alignSelf={"stretch"}
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"space-between"}
						gap={2}
					>
						<Box
							display={"flex"}
							flexDirection={"column"}
							justifyContent={{ xs: "stretch", sm: "flex-end" }}
							gap={1}
							mt={{ md: "20px" }}
							color={"primary.contrastText"}
						>
							<Typography
								variant={"h5"}
								fontWeight={"700"}
								textAlign={{ xs: "center", sm: "end" }}
								px={{ xs: 3, sm: 0 }}
							>
								{coupon.title}
							</Typography>

							<CouponAwards
								isExternalType={isExternalType}
								coupon={coupon}
								applyCouponInfo={applyCouponInfo}
								redeemedCouponCheck={redeemedCouponCheck}
								onCloseApplyPopup={onCloseApplyPopup}
							/>

							{coupon.description && (
								<CouponDesc
									setNeedExpand={setDescNeedExpand}
									insideCoupon={insideCoupon}
									coupon={coupon}
									hideShowButton={isTablet}
									isExternalType={isExternalType}
								/>
							)}
						</Box>

						<Box width={"100%"} pl={isExternalType ? undefined : { sm: 5 }}>
							{!!user &&
								!!coupon.id &&
								isInWallet &&
								!!coupon.batch?.share_allowed && (
									<Share
										couponBenefitAmount={couponBenefitAmount}
										handleShare={handleShare}
										openShareCode={openShareCode}
										closeShareModel={closeShareModal}
										isShareLoading={isShareLoading}
										botUrl={botUrl}
										webUrl={webUrl}
									/>
								)}

							<Divider
								sx={{
									borderColor: "primary.contrastText",
									ml: !isExternalType
										? {
												sm: "10%",
												md: 10,
												lg: 12,
												xl: 18,
											}
										: 0,
								}}
							/>

							<BottomButtonsBar
								isExternalType={isExternalType}
								handlePdf={handlePdf}
								coupon={coupon}
								isShowApplyButton={isShowApplyButton}
								isApplyCouponError={isApplyCouponError}
								handleWallet={handleWallet}
								handleApply={handleApply}
								isApplyDisabled={isAddCouponToWalletLoading}
								descNeedExpand={descNeedExpand}
								handleIsInside={handleIsInside}
								isInWallet={isInWallet}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</MotionBox>
	);
}
