import { IncustPayAccountData, IncustPayAccountProps, IncustPayDataService } from "./types";
import useAppContext from "../../../../useAppContext";
import { MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import useUnifiedSpecialAccountData, {
	UnifiedSpecialAccountDataLimit,
} from "./useUnifiedSpecialAccountData";
import Divider from "@mui/material/Divider";
import {
	Alert,
	AlertTitle,
	Box,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	Typography,
} from "@mui/material";
import f from "../../../../helpers/formatText";
import formatCurrency from "../../../../helpers/formatCurrency";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { incustPayRedirect } from "./helpers";
import IncustPayModalButtons from "./modals/IncustPayModalButtons";
import api from "../../../../api";
import { AxiosError } from "axios";
import { getErrorText } from "../../../../helpers/errors";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { ThemeModal } from "../../../../helpers/ThemeComponents";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Interweave from "../../../../features/Interweave";
import { PaymentPayload } from "../../../../api/shop/payments/types";
import useScreenService from "../../../../services/useScreenService";
import { IUsePaymentsService } from "../../hooks/useNewPaymentsService";

const WaitForRedirectSeconds = 5;

export default function IncustPayAccount({
	amountToPay,
	accountData,
	incustPayDataService,
	afterAccountInfoNode,
	changeAccountButtonNode,
	onClose,
	id_type,
	card_number,
	hideCancel,
	additionalFields,
	payedCallback,
	paymentData,
	paymentService,
	...props
}: IncustPayAccountProps) {
	const { lang, localisation, brandInfo } = useAppContext();

	const [timeToRedirect, setTimeToRedirect] = useState<number | null>(null);

	const specialAccountData = useUnifiedSpecialAccountData(accountData);

	const stablePayedCallback = payedCallback ? payedCallback : null;
	useEffect(() => {
		if (incustPayDataService.isPayed) {
			incustPayRedirect(props.type, {
				selectedStoreId: props.selectedStoreId,
				orderId: paymentData?.order_id || props.orderId,
				orderToken: paymentData?.order_token || props.orderToken,
				invoiceToken: paymentData?.invoice_token || props.invoiceToken,
			});
			stablePayedCallback && stablePayedCallback();
		}
		// if (incustPayDataService.isPayed) {
		// 	let counter = WaitForRedirectSeconds;
		// 	incustPayRedirect(props.type, {
		// 		selectedStoreId: props.selectedStoreId,
		// 		orderId: paymentData?.order_id || props.orderId,
		// 		orderToken: paymentData?.order_token || props.orderToken,
		// 		invoiceToken: paymentData?.invoice_token || props.invoiceToken,
		// 	});

		// 	setTimeToRedirect(counter);
		// 	const timer = setInterval(() => {
		// 		counter -= 1;
		// 		setTimeToRedirect(counter);
		// 		if (counter === 0) {
		// 			clearInterval(timer);
		// 			incustPayRedirect(props.type, {
		// 				selectedStoreId: props.selectedStoreId,
		// 				orderId: paymentData?.order_id || props.orderId,
		// 				orderToken: paymentData?.order_token || props.orderToken,
		// 				invoiceToken: paymentData?.invoice_token || props.invoiceToken,
		// 			});
		// 		}
		// 	}, 1000);

		// 	return () => {
		// 		clearInterval(timer);
		// 	};
		// } else {
		// 	setTimeToRedirect(null);
		// }
	}, [
		incustPayDataService.isPayed,
		stablePayedCallback,
		paymentData?.invoice_token,
		paymentData?.order_id,
		paymentData?.order_token,
		props.invoiceToken,
		props.orderId,
		props.orderToken,
		props.selectedStoreId,
		props.type,
	]);

	const isInsufficientFundsError =
		!specialAccountData.is_unlimited &&
		(!specialAccountData.available_amount || specialAccountData.available_amount < amountToPay);

	const insufficientFundsError = useMemo(() => {
		if (!isInsufficientFundsError) return "";

		return f(localisation.payment.incustPayInsufficientFundsError, {
			account_title: specialAccountData.title,
			available_amount: formatCurrency(
				specialAccountData.available_amount || 0,
				brandInfo?.default_lang || lang,
				specialAccountData.currency
			),
			amount_to_pay: formatCurrency(
				amountToPay,
				brandInfo?.default_lang || lang,
				specialAccountData.currency
			),
			shortfall_amount: formatCurrency(
				amountToPay - (specialAccountData.available_amount || 0),
				brandInfo?.default_lang || lang,
				specialAccountData.currency
			),
		});
	}, [
		amountToPay,
		brandInfo?.default_lang,
		isInsufficientFundsError,
		lang,
		localisation.payment.incustPayInsufficientFundsError,
		specialAccountData.available_amount,
		specialAccountData.currency,
		specialAccountData.title,
	]);

	useEffect(() => {
		if (incustPayDataService.isPayed && timeToRedirect === 0)
			stablePayedCallback && stablePayedCallback();
	}, [incustPayDataService.isPayed, stablePayedCallback, timeToRedirect]);

	return (
		<>
			{paymentService && !paymentService.paymentsCollapseOpen && (
				<AccountInfo accountData={accountData} />
			)}

			<Box mt={2}>
				{afterAccountInfoNode}

				<Box>
					{incustPayDataService.isPayed ? (
						<Box pb={3}>
							<Alert severity={"success"} sx={{ mt: 2 }}>
								<AlertTitle>
									<Interweave
										content={f(
											accountData.type === "special-account"
												? localisation.payment
														.incustPayCustomerAccountSuccessText
												: localisation.payment
														.incustPayCorporateAccountSuccessText,
											{
												amount: formatCurrency(
													amountToPay,
													brandInfo?.default_lang || lang,
													specialAccountData.currency
												),
												account_title: specialAccountData.title,
											}
										)}
									/>
								</AlertTitle>
								{timeToRedirect && (
									<>
										<Interweave
											content={f(
												localisation.payment[
													`incustPay_${props.type}_redirectText`
												],
												{
													seconds: timeToRedirect || 0,
												}
											)}
										/>
										<Link
											sx={{ ml: 1 }}
											role={"button"}
											fontSize={"smaller"}
											color={"inherit"}
											onClick={() => {
												incustPayRedirect(props.type, {
													selectedStoreId: props.selectedStoreId,
													orderId: paymentData?.order_id || props.orderId,
													orderToken:
														paymentData?.order_token ||
														props.orderToken,
													invoiceToken:
														paymentData?.invoice_token ||
														props.invoiceToken,
												});
												stablePayedCallback && stablePayedCallback();
											}}
										>
											{localisation.payment.incustPayManualRedirectButton}
										</Link>
									</>
								)}
							</Alert>
						</Box>
					) : isInsufficientFundsError ? (
						<>
							{paymentService && !paymentService.paymentsCollapseOpen && (
								<>
									<Alert severity={"error"}>
										<Interweave content={insufficientFundsError} />
									</Alert>
									{changeAccountButtonNode}
								</>
							)}
						</>
					) : (
						<>
							{paymentService && !paymentService.paymentsCollapseOpen && (
								<Box mb={2}>
									{(props.invoiceToken || props.orderToken) && (
										<Typography px={1} fontWeight={"bold"}>
											{f(
												accountData.type ===
													"corporate-special-account-access"
													? localisation.payment
															.incustPayCorporateAccountPayText
													: localisation.payment
															.incustPayCustomerAccountPayText,
												{
													amount: formatCurrency(
														amountToPay,
														brandInfo?.default_lang || lang,
														specialAccountData.currency
													),
													account_title: specialAccountData.title,
												}
											)}
										</Typography>
									)}

									{changeAccountButtonNode}
								</Box>
							)}

							<AccountForm
								accountData={accountData}
								incustPayDataService={incustPayDataService}
								onClose={onClose}
								id_type={id_type}
								card_number={card_number}
								hideCancel={hideCancel}
								paymentData={paymentData}
								additionalFields={additionalFields}
								descAndFeeNode={props.descAndFeeNode}
								buttonsNode={props.buttonsNode}
								paymentService={paymentService}
							/>
						</>
					)}
				</Box>
			</Box>
		</>
	);
}

interface AccountInfoProps {
	accountData: IncustPayAccountData;
}

export function AccountInfo({ accountData }: AccountInfoProps) {
	const { isMobile } = useScreenService();
	const { lang, localisation, brandInfo } = useAppContext();

	const specialAccountData = useUnifiedSpecialAccountData(accountData);

	return (
		<Grid
			container
			border={1}
			sx={{ borderColor: "divider" }}
			p={3}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"stretch"}
			borderRadius={"8px"}
		>
			<Grid item xs={12}>
				<Typography lineHeight={1} mb={1} textAlign={"center"}>
					{localisation.payment.incustPayAvailableForPaymentText}
				</Typography>
				<Typography
					fontSize={isMobile ? "x-large" : "xxx-large"}
					lineHeight={1}
					fontWeight={"bold"}
					textAlign={"center"}
					sx={{ wordBreak: "break-word" }}
				>
					{specialAccountData.is_unlimited
						? localisation.payment.incustPayUnlimitedText
						: formatCurrency(
								specialAccountData.available_amount || 0,
								brandInfo?.default_lang || lang,
								specialAccountData.currency
							)}
				</Typography>
				{accountData.type === "special-account" ? (
					<>
						{accountData.special.credit_type !== "debit" && (
							<Typography
								mt={1}
								lineHeight={1}
								textAlign={"center"}
								variant={"body2"}
							>
								{localisation.payment.incustPayCreditLimitText + ": "}
								{accountData.special.credit_type === "credit" ? (
									formatCurrency(
										accountData.special.credit_limit,
										brandInfo?.default_lang || lang,
										specialAccountData.currency
									)
								) : (
									<AllInclusiveIcon color={"inherit"} fontSize={"inherit"} />
								)}
							</Typography>
						)}

						{accountData.special.credit_type !== "debit" &&
							accountData.special.amount !== null && (
								<>
									<Typography
										mt={1}
										lineHeight={1}
										textAlign={"center"}
										variant={"body2"}
									>
										{localisation.payment.incustPayOnHandAmountText + ": "}
										{formatCurrency(
											accountData.special.amount > 0
												? accountData.special.amount
												: 0,
											brandInfo?.default_lang || lang,
											specialAccountData.currency
										)}
									</Typography>

									{accountData.special.amount < 0 && (
										<Typography
											mt={1}
											lineHeight={1}
											textAlign={"center"}
											variant={"body2"}
										>
											{localisation.payment.incustPayCreditUsedText + ": "}
											{formatCurrency(
												accountData.special.amount < 0
													? Math.abs(accountData.special.amount)
													: 0,
												brandInfo?.default_lang || lang,
												specialAccountData.currency
											)}
										</Typography>
									)}
								</>
							)}
					</>
				) : (
					<Typography mt={1} lineHeight={1} textAlign={"center"} variant={"body2"}>
						{localisation.payment.incustPayAccountTypeCorporateText}
					</Typography>
				)}
			</Grid>
			{(specialAccountData.limits?.day || specialAccountData.limits?.month) && (
				<>
					<Grid item xs={12} mt={3} mb={2}>
						<Divider
							sx={{
								borderColor: theme =>
									`rgba(${theme.palette.primary.contrastTextChannel} / 0.5)`,
							}}
						/>
					</Grid>
					<LimitComponent
						type={"day"}
						limit={specialAccountData.limits.day}
						currency={specialAccountData.currency}
					/>
					<LimitComponent
						type={"month"}
						limit={specialAccountData.limits.month}
						currency={specialAccountData.currency}
					/>
				</>
			)}
		</Grid>
	);
}

interface AccountFormProps {
	accountData: IncustPayAccountData;
	incustPayDataService: IncustPayDataService;
	onClose?: MouseEventHandler<HTMLButtonElement>;
	id_type: "card" | "user";
	card_number?: string | null;

	hideCancel?: boolean;
	paymentData?: PaymentPayload | null;
	additionalFields?: ReactNode | null;
	descAndFeeNode?: ReactNode | null;
	buttonsNode?: ReactNode | null;
	paymentService?: IUsePaymentsService;
}

function AccountForm({
	accountData,
	incustPayDataService,
	onClose,
	id_type,
	card_number,
	hideCancel,
	paymentData,
	additionalFields,
	descAndFeeNode,
	buttonsNode,
	paymentService,
}: AccountFormProps) {
	const { localisation } = useAppContext();

	const specialAccountData = useUnifiedSpecialAccountData(accountData);

	const [showPin, setShowPin] = useState(false);
	const [showScanVehicleId, setShowScanVehicleId] = useState(false);

	const { setError, setIsLoading, setIsPayed } = incustPayDataService;
	const pay = useCallback(async () => {
		if (incustPayDataService.isLoading) return false;

		try {
			setError("");
			setIsLoading(true);
			let cardName: string | null = null;
			if (id_type === "card") {
				cardName = f(localisation.payment.incustPayCardText, {
					name: accountData.incust_pay_configuration.name,
				});
			}
			await api.shop.payments.payWithIncust({
				incust_pay_configuration_id:
					accountData.incust_pay_configuration.payment_settings_id,
				payment_type: accountData.type,
				payment_id: specialAccountData.id,
				pin: incustPayDataService.pin !== "" ? incustPayDataService.pin : null,
				odometer: incustPayDataService.odometer,
				vehicle_id:
					incustPayDataService.vehicleId !== "" ? incustPayDataService.vehicleId : null,
				id_type: id_type,
				card_number: card_number,
				payment_data: paymentData,
				account_name: specialAccountData.title,
				card_name: cardName,
			});

			setIsPayed(true);
		} catch (err: any) {
			console.log(err);
			if (
				err instanceof AxiosError &&
				err.response?.data?.detail?.error_code === "incorrect_pin"
			) {
				setError(err.response.data.detail.message);
			} else {
				setError(getErrorText(err, localisation.global.errUnknown));
			}
		} finally {
			setIsLoading(false);
		}
	}, [
		incustPayDataService.isLoading,
		incustPayDataService.pin,
		incustPayDataService.odometer,
		incustPayDataService.vehicleId,
		setError,
		setIsLoading,
		accountData.incust_pay_configuration.payment_settings_id,
		accountData.type,
		specialAccountData.id,
		id_type,
		card_number,
		paymentData,
		setIsPayed,
		localisation.global.errUnknown,
	]);

	return (
		<Box
			onSubmit={async event => {
				event.preventDefault();
				event.stopPropagation();
				await pay();
			}}
			component={"form"}
			display={"flex"}
			flexDirection={"column"}
			gap={2}
			pb={1}
		>
			{paymentService && !paymentService.paymentsCollapseOpen && (
				<>
					{specialAccountData.security_code && (
						<TextField
							required
							fullWidth
							label={localisation.payment.incustPaySecurityCodeLabel}
							name={"specialAccountPin"}
							type={showPin ? "text" : "password"}
							value={incustPayDataService.pin}
							onChange={e => incustPayDataService.setPin(e.target.value)}
							InputProps={
								incustPayDataService.pin
									? {
											endAdornment: (
												<InputAdornment position={"end"}>
													<IconButton
														aria-label={"toggle password visibility"}
														onClick={() => setShowPin(prev => !prev)}
														onMouseDown={event =>
															event.preventDefault()
														}
													>
														{showPin ? (
															<VisibilityOff />
														) : (
															<Visibility />
														)}
													</IconButton>

													<IconButton
														aria-label={"clear input"}
														onClick={() =>
															incustPayDataService.setPin("")
														}
														onMouseDown={event =>
															event.preventDefault()
														}
														edge={"end"}
													>
														<ClearIcon />
													</IconButton>
												</InputAdornment>
											),
										}
									: undefined
							}
						/>
					)}

					{specialAccountData.odometer !== "no" && (
						<TextField
							type={"number"}
							name={"odometer"}
							inputProps={{
								step: "0.01",
							}}
							fullWidth
							label={localisation.payment.incustPayOdometerLabel}
							required={specialAccountData.odometer === "yes"}
							value={
								incustPayDataService.odometer === null
									? ""
									: incustPayDataService.odometer.toString()
							}
							onChange={e => {
								const result = parseFloat(e.target.value);
								if (!isNaN(result)) incustPayDataService.setOdometer(result);
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position={"end"}>
										{incustPayDataService.odometer && (
											<IconButton
												aria-label={"clear input"}
												onClick={() =>
													incustPayDataService.setOdometer(null)
												}
												onMouseDown={event => event.preventDefault()}
												edge={"end"}
											>
												<ClearIcon />
											</IconButton>
										)}
									</InputAdornment>
								),
							}}
						/>
					)}

					{specialAccountData.vehicle_id !== "no" && (
						<TextField
							fullWidth
							name={"vehicleId"}
							label={localisation.payment.incustPayVehicleIdLabel}
							required={specialAccountData.vehicle_id === "yes"}
							value={incustPayDataService.vehicleId}
							onChange={e => incustPayDataService.setVehicleId(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position={"end"}>
										{incustPayDataService.vehicleId && (
											<IconButton
												aria-label={"clear input"}
												onClick={() =>
													incustPayDataService.setVehicleId("")
												}
												onMouseDown={event => event.preventDefault()}
											>
												<ClearIcon />
											</IconButton>
										)}
										<IconButton
											onClick={() => setShowScanVehicleId(true)}
											edge={"end"}
										>
											<QrCodeScannerIcon aria-label={"scan qr"} />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					)}

					{descAndFeeNode && descAndFeeNode}

					<Box>{additionalFields && additionalFields}</Box>

					{incustPayDataService.error && (
						<Alert severity={"error"} sx={{ mt: 2 }}>
							<Interweave content={incustPayDataService.error} />
						</Alert>
					)}
				</>
			)}

			{buttonsNode && buttonsNode}

			{paymentService && !paymentService?.paymentsCollapseOpen && (
				<>
					<ThemeModal
						open={showScanVehicleId}
						setOpen={setShowScanVehicleId}
						title={localisation.payment.incustPayScanVehicleIdModalTitle}
					>
						<QrScanner
							containerStyle={{
								width: "min(350px, calc(100vw-16px)",
								height: "min(350px, calc(100vw-16px)",
								margin: 0,
							}}
							onDecode={result => {
								incustPayDataService.setVehicleId(result);
								setShowScanVehicleId(false);
							}}
							onError={error => console.log(error?.message)}
							scanDelay={1000}
						/>
					</ThemeModal>
				</>
			)}
		</Box>
	);
}

interface LimitComponentProps {
	type: "day" | "month";
	limit: UnifiedSpecialAccountDataLimit | null;
	currency: string;
}

function LimitComponent({ type, limit, currency }: LimitComponentProps) {
	const { lang, localisation, brandInfo } = useAppContext();

	return (
		<Grid
			item
			xs={6}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			textAlign={"center"}
		>
			<Box fontWeight={"bold"} flex={1} display={"flex"} alignItems={"start"}>
				{limit?.limit ? (
					<Box>
						<Box
							sx={{
								display: "inline-block",
								whiteSpace: "pre-wrap",
								wordBreak: "break-all",
							}}
						>
							{formatCurrency(
								limit.available,
								brandInfo?.default_lang || lang,
								currency
							)}
							/
						</Box>
						<Box
							sx={{
								display: "inline-block",
								whiteSpace: "pre-wrap",
								wordBreak: "break-all",
							}}
						>
							{formatCurrency(limit.limit, brandInfo?.default_lang || lang, currency)}
						</Box>
					</Box>
				) : (
					<AllInclusiveIcon fontSize={"large"} color={"inherit"} />
				)}
			</Box>

			<Typography variant={"body2"}>
				{localisation.payment[`incustPay_${type}_limitText`]}
			</Typography>
		</Grid>
	);
}
