import { atom } from "recoil";

export interface IOAuthLoginState {
	oauthToken: string;
	firstName: string;
	lastName: string;
	email: string;
}

export const initialOAuthLoginState = {
	oauthToken: "",
	firstName: "",
	lastName: "",
	email: "",
};

export const oauthLoginState = atom<IOAuthLoginState>({
	key: "oauthLoginState",
	default: initialOAuthLoginState,
});
