import { useEffect } from "react";
import { BrandInfo } from "../api/shop/basic/types";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api";
import { LoadingManager } from "../features/hooks/useLoadingManager";
import setFavicon from "../helpers/setFavicon";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useSearchParamsFixed from "../features/hooks/useSearchParamsFixed";
import changePage from "../helpers/changePage";

export default function useBrandService(loadingManager: LoadingManager): BrandInfo | null {
	const { setIsLoading } = loadingManager;

	const navigate = useNavigate();
	const location = useLocation();

	const [searchParams] = useSearchParamsFixed();

	const brandIdQuery = searchParams.get("brand_id");

	const {
		data: brand,
		isPending,
		isError,
	} = useQuery({
		queryKey: ["brand", brandIdQuery],
		initialData: null,
		placeholderData: keepPreviousData,
		queryFn: async () => {
			const brandIdStr = brandIdQuery;
			const brandId = brandIdStr ? parseInt(brandIdStr) : undefined;

			console.log("loading brand", brandIdStr, brandId);

			const response = await api.shop.basic.detectBrand(brandId);

			const brand = response.data.brand;
			api.instance.defaults.params["brand_id"] = brand.id.toString();
			return brand;
		},
	});

	useEffect(() => {
		setIsLoading("brand", isPending && !isError);
		return () => {
			setIsLoading("brand", false);
		};
	}, [isError, isPending, setIsLoading]);

	// useEffect(() => {
	// 	if (brand?.id) {
	// 		if (brandIdQuery && brand.id.toString() !== brandIdQuery) {
	// 			return window.location.reload();
	// 		} else if (isBrandDomain) {
	// 			removeBrandIdQuery();
	// 		}
	// 	}
	// }, [brand?.id, brandIdQuery, isBrandDomain, removeBrandIdQuery]);

	useEffect(() => {
		if (brand?.id) {
			if (location.pathname === "/" || !location.pathname) {
				changePage(`/shop/select${location.search}`);
			}
		}
	}, [brand?.id, location.pathname, location.search, navigate]);

	useEffect(() => {
		setFavicon(brand?.logo_url);
	}, [brand?.logo_url]);

	return brand;
}
