import { ChangeEvent, useEffect } from "react";
import {
	Box,
	ButtonBase,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Skeleton,
	Typography,
} from "@mui/material";
import TopupCardModal from "./TopupCardModal";
import { ITopupOption, IUseTopupOptions } from "./useTopupOptions";
import { ChevronRight } from "@mui/icons-material";
import useAppContext from "../../useAppContext";
import useUserIncustCustomerData from "../../hooks/incust/useUserIncustCustmerData";

interface ITopupOptionsProps {
	service: IUseTopupOptions;
}

export default function TopupOptions(props: ITopupOptionsProps) {
	const { isCustomerFetching } = useUserIncustCustomerData();

	//TODO: custom sx
	return (
		<>
			{!props.service.options.length || props.service.isLoading || isCustomerFetching ? (
				<Box sx={{ pr: "20px", pl: "20px" }} display={"flex"}>
					<Skeleton
						sx={{ mr: 2, alignSelf: "center" }}
						variant={"circular"}
						width={20}
						height={20}
					/>
					<Skeleton sx={{ flex: 1 }} height={83} />
				</Box>
			) : (
				<>
					<TopupOptionsForm
						options={props.service.options}
						selectedOption={props.service.selectedOption}
						handleChange={props.service.handleOptionChange}
						onOptionClick={(option: ITopupOption) => {
							if (option.balance === undefined) {
								props.service.setShowCardInfoModal(true);
							}
						}}
						getBalanceString={props.service.getBalanceString}
					/>
					<TopupCardModal service={props.service} />
				</>
			)}
		</>
	);
}

interface ITopupOptionsFormProps {
	options: ITopupOption[];
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedOption: ITopupOption | null;
	onOptionClick?: (option: ITopupOption) => void;
	getBalanceString: (amount: number, accountId: string) => string;
}

export function TopupOptionsForm(props: ITopupOptionsFormProps) {
	const handleChange = props.handleChange;
	const onOptionClick = props.onOptionClick;
	useEffect(() => {
		if (props.options && props.options.length === 1) {
			//@ts-ignore
			handleChange({ target: { value: props.options[0].value || "" } });
			onOptionClick && onOptionClick(props.options[0]);
		}
	}, [handleChange, onOptionClick, props.options]);

	return (
		<FormControl sx={{ width: "100%" }} key={props.selectedOption?.value}>
			<RadioGroup
				aria-labelledby="topup-radio-buttons-group-label"
				name="topup-radio-buttons-group"
				onChange={props.handleChange}
				value={props.selectedOption?.value}
				sx={{
					".MuiButtonBase-root": {
						p: "4px",
					},
				}}
			>
				{props.options.map(option => (
					<ButtonBase
						key={option.value}
						sx={{ width: "100%", justifyContent: "start", px: 3, py: 2 }}
						className={"border-bottom"}
						onClick={() => props.onOptionClick && props.onOptionClick(option)}
					>
						<FormControlLabel
							key={option.value}
							value={option.value}
							control={<Radio />}
							label={
								<FormControlLabelItem
									option={option}
									getBalanceString={props.getBalanceString}
									needChevron={props.options.length > 1}
								/>
							}
							sx={{
								width: "100%",
								pr: 3,
								pl: "10px",
								m: 0,
								".MuiFormControlLabel-label": { flex: 1 },
							}}
						/>
					</ButtonBase>
				))}
			</RadioGroup>
		</FormControl>
	);
}

interface IFormControlLabelItemProps {
	option: ITopupOption;
	getBalanceString: (amount: number, accountId: string) => string;
	needChevron?: boolean;
}

function FormControlLabelItem(props: IFormControlLabelItemProps) {
	const {
		localisation: { topup },
	} = useAppContext();

	return (
		<Box textAlign={"start"} display={"flex"} width={"100%"}>
			<Box>
				<Typography fontWeight={"bold"}>{props.option.label}</Typography>
				{!!props.option.configId && props.option.balance === undefined ? (
					<>
						{props.option.specialData ? (
							<Typography variant={"body2"} color={"text.secondary"}>
								{props.option.specialData.title} |{" "}
								{props.getBalanceString(
									props.option.specialData.amount || 0,
									props.option.specialData.id
								)}
							</Typography>
						) : (
							<Typography variant={"body2"} color={"text.secondary"}>
								{topup.enterCardForAccounts}
							</Typography>
						)}
					</>
				) : (
					<Typography variant={"body2"} color={"text.secondary"}>
						{props.option.balance}
					</Typography>
				)}
			</Box>
			{props.needChevron && (
				<Box ml={"auto"} alignSelf={"center"}>
					<ChevronRight fontSize={"large"} />
				</Box>
			)}
		</Box>
	);
}
