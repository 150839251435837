import { MDBIcon, MDBTypography } from "mdb-react-ui-kit";

import ShopImage from "../../static/stubs/shop-image-stub.webp";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import { Store } from "../../api/shop/basic/types";
import { useShopContext } from "../context";
import { Box, Button, Grid } from "@mui/material";
import useScreenService, { ScreenSize } from "../../services/useScreenService";
import useWorkingTimesService from "../services/useWorkingTimesService";

interface SelectStoreCardProps {
	store: Store;
	handleStoreChanged: (newStore: Store) => any;
	isSmallWidthCard?: boolean;
}

export default function SelectStoreCard({
	store,
	handleStoreChanged,
	isSmallWidthCard = false,
}: SelectStoreCardProps) {
	const { brandInfo } = useShopContext();
	const { screenSize } = useScreenService();

	const localisation = useLocalisation();

	return (
		<Box
			className={"cursor-pointer"}
			border={1}
			borderColor={"borderColor"}
			borderRadius={2}
			onClick={() => handleStoreChanged(store)}
		>
			<Grid container>
				<Grid
					item
					xs={3}
					lg={isSmallWidthCard ? 3 : 2}
					display={"flex"}
					alignItems={"center"}
				>
					<img
						src={store.image_url || brandInfo.image_url || ShopImage}
						alt={store.name}
						className={"shop-card-image w-100"}
					/>
				</Grid>
				<Grid item xs={9} lg={isSmallWidthCard ? 9 : 10} pl={2}>
					<Grid container>
						<Grid item xs={12} lg={8}>
							<SelectStoreCardContent store={store} />
						</Grid>
						{screenSize >= ScreenSize.LG && (
							<Grid
								item
								lg={4}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"end"}
								pr={2}
							>
								<Button
									variant={"contained"}
									onClick={() => handleStoreChanged(store)}
								>
									{localisation.global.openStoreButton}
									<MDBIcon fas icon="chevron-right" className={"ms-2"} />
								</Button>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

interface SelectStoreCardContentProps {
	store: Store;
}

export function SelectStoreCardContent({ store }: SelectStoreCardContentProps) {
	const { workingTimes, isShopClosed } = useWorkingTimesService();

	const localisation = useLocalisation();

	return (
		<>
			<MDBTypography className="m-0 one-line theme-text" style={{ maxWidth: "80%" }}>
				<MDBTypography tag="strong">{store.name}</MDBTypography>
			</MDBTypography>
			<div>
				<span className={isShopClosed ? "text-muted" : "theme-text"}>
					{workingTimes?.current?.includes("pass")
						? localisation.global.weekend
						: workingTimes?.current}
				</span>
				{isShopClosed && <span className="text-danger"> {localisation.select.closed}</span>}
			</div>
			<div
				className="theme-text fw-bold two-lines"
				style={{
					height: "2.2rem",
					fontSize: ".75rem",
					wordBreak: "break-word",
					maxWidth: "80%",
				}}
			>
				{store.custom_fields?.find(x => x.name === "address")?.value || ""}
			</div>
		</>
	);
}
