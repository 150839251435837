import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import useAppContext from "../../../useAppContext";
import { InterfaceSums } from "../types";
import { ExtraFeeSchema } from "../../../api/shop/basic/types";

export default function useExtraFee(
	sums: InterfaceSums,
	storeId: number | null = null,
	currency?: string | null
): IUseExtraFee {
	const { brandInfo } = useAppContext();

	const { data: extraFees, isPending } = useQuery({
		queryKey: ["extra-fees", brandInfo?.group_id],
		enabled: !!brandInfo?.id && !!currency,
		initialData: null,
		queryFn: async () => {
			if (brandInfo?.id && !!currency) {
				try {
					const response = await api.shop.basic.getPaymentsFee({
						brand_id: brandInfo?.id,
						store_id: storeId,
						total_sum: sums.totalSum,
						sum_to_pay: sums.sumToPay,
						currency: currency,
					});
					if (response.data && response.data.extra_fees) {
						return response.data.extra_fees;
					}
				} catch (ex) {} //TODO: handle error
				return null;
			}
		},
	});

	const calcExtraFeeTotalSum = useMemo(() => {
		let extraFee = 0;
		if (extraFees && extraFees.length > 0) {
			extraFee = extraFees.reduce((sum, fee) => sum + fee.applied_amount_float, 0);
		}

		return extraFee;
	}, [extraFees]);

	const calcTotalSumWithExtraFee = useMemo(() => {
		return sums.totalSum + calcExtraFeeTotalSum;
	}, [sums.totalSum, calcExtraFeeTotalSum]);

	return {
		calcTotalSumWithExtraFee,
		extraFees: extraFees || null,
	};
}

export interface IUseExtraFee {
	calcTotalSumWithExtraFee: number;
	extraFees: ExtraFeeSchema[] | null;
}
