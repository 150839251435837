import { Box, Divider } from "@mui/material";
import { PaymentDataStepProps } from "../../../types";
import useAppContext from "../../../../../../useAppContext";
import AmountInput from "./AmountInput";
import LoginOptionsStepper from "../../../../../../components/steppers/steps/LoginOptionsStepper";
import NavigationButtons from "../../NavigationButtons";
import CountInput from "./CountInput";
import IncustLoyalty from "../../../../../loyalty/components/IncustLoyalty/IncustLoyalty";
import { Check } from "../../../../../../features/Check";
import { useMemo } from "react";
import { CheckData, CheckItemType } from "../../../../../../features/Check/types";

export default function PaymentDataStep(props: PaymentDataStepProps) {
	const {
		lang,
		authService: { user },
		brandInfo,
	} = useAppContext();

	const checkData = useMemo((): CheckData | null => {
		if (!props.invoiceTemplate || props.invoiceTemplate.payment_mode === "entered_amount")
			return null;

		const items: CheckItemType[] = props.invoiceTemplate?.items.map(item => ({
			name: item.name,
			quantity: item.quantity * (props.form.count || 1),
			price: item.price,
			sum: item.price * item.quantity * (props.form.count || 1),
			hideImage: true,
		}));

		const total = items.reduce((total, item) => total + item.sum, 0);

		const isLoyalty = !!(
			brandInfo?.loyalty_info?.loyalty_enabled &&
			brandInfo.loyalty_info?.loyalty_type === "incust" &&
			(props.incustService.processedCheck || props.incustService.processCheckLoading)
		);

		if (isLoyalty) {
			return {
				type: "regular",
				viewOrder: true,
				items: items,
				subtotal: total,
				totalAmountWithTips: total,
				extraFee: [],
				total_sum_with_extra_fee: 0,
			};
		} else {
			return {
				type: "regular",
				viewOrder: true,
				items: items,
				totalAmountWithTips: total,
				extraFee: [],
				total_sum_with_extra_fee: 0,
				sum_to_pay: total,
			};
		}
	}, [
		brandInfo?.loyalty_info?.loyalty_enabled,
		brandInfo?.loyalty_info?.loyalty_type,
		props.form.count,
		props.incustService.processCheckLoading,
		props.incustService.processedCheck,
		props.invoiceTemplate,
	]);

	if (!lang) return null;

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				props.setNextStep();
			}}
		>
			{!!checkData && <Check data={checkData} />}
			<Box
				mt={1}
				pt={1}
				pb={2}
				borderTop={+!!props.invoiceTemplate?.items?.length} // 0 or 1
				borderColor={"divider"}
			>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					flexWrap={"wrap"}
					gap={2}
					mt={2}
				>
					{props.invoiceTemplate?.items?.length ? (
						!props.invoiceTemplate.disabled_qty && (
							<CountInput form={props.form} setForm={props.setForm} />
						)
					) : (
						<AmountInput
							currency={props.currency}
							form={props.form}
							setForm={props.setForm}
							enteredAmount={props.enteredAmount}
						/>
					)}

					{!!(
						brandInfo?.loyalty_info?.loyalty_enabled &&
						brandInfo.loyalty_info?.loyalty_type === "incust" &&
						(props.incustService.processedCheck ||
							props.incustService.processCheckLoading)
					) && (
						<Box width={"100%"}>
							<IncustLoyalty
								incustService={props.incustService}
								withoutPreSum={true}
								// hidden={!user}
							/>
						</Box>
					)}
				</Box>
			</Box>

			{!!(
				brandInfo?.loyalty_info?.loyalty_enabled &&
				brandInfo.loyalty_info?.loyalty_type === "incust" &&
				props.incustService.processedCheck?.amount_to_pay &&
				(props.incustService.processedCheck || props.incustService.processCheckLoading)
			) && (
				<>
					<Divider />
					<Check
						data={{
							type: "regular",
							viewOrder: true,
							extraFee: [],
							total_sum_with_extra_fee: 0,
							totalAmountWithTips: props.incustService.processedCheck?.amount_to_pay,
							sum_to_pay: props.incustService.processedCheck?.amount_to_pay,
						}}
					/>
				</>
			)}

			{!user && <LoginOptionsStepper mt={2} />}

			{(!!user || !!props.goBack) && (
				<NavigationButtons
					onPrevClick={props.goBack}
					hidePrev={!props.goBack}
					hideNext={!user}
					disabledNext={props.incustService.processCheckLoading}
				/>
			)}
		</form>
	);
}
