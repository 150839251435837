import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Step, StepContent, StepIconProps, StepLabel, Stepper } from "@mui/material";
import {
	BillingAddress,
	CreateOrderPayload,
	NotAvailableProduct,
	Order,
} from "../../../../api/shop/order/types";
import { SetMergeState } from "../../../../helpers/useMergedState";
import PersonalDataStep from "./PersonalDataStep";
import Shipping from "./Shipping/Shipping";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import { useShopContext } from "../../../context";
import useLocalisation from "../../../../hooks/localisation/useLocalisation";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentIcon from "@mui/icons-material/Payment";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { SetState } from "../../../../types";
import useStepper from "../../../../hooks/useStepper";
import { SelectedShipmentServiceType, SelectedShipmentType } from "../useSelectedShipmentService";
import Tips from "./Tips";
import useTipsService from "../../../../features/services/useTipsService";
import ConfirmationStep from "./ConfirmationStep";
import { isIOS } from "../../../../helpers";
import changePage from "../../../../helpers/changePage";
import useShipmentPrices from "../../../services/useShipmentPrices";
import { IUsePaymentsService } from "../../../payments/hooks/useNewPaymentsService";
import { PaymentsModal } from "../../../payments/Payment/Payments";
import { matchIsValidTel } from "mui-tel-input";
import { getOrderState } from "./orderSessionState";
import { IUseUpdateOrder } from "../useUpdateOrder";
import useAppContext from "../../../../useAppContext";

interface OrderStepperProps {
	computedShopImage: string;

	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;

	selectedShipmentService: SelectedShipmentServiceType;
	paymentsService: IUsePaymentsService;

	billingForm: BillingAddress;
	setBillingForm: SetMergeState<BillingAddress>;

	noPaymentsAvailable?: boolean;
	setNoPaymentsAvailable?: (value: boolean) => void;

	notAvailableProducts: NotAvailableProduct[];
	setNotAvailableProducts: (products: NotAvailableProduct[]) => void;

	needLoginForEmail: boolean;
	setNeedLoginForEmail: SetState<boolean>;

	errorText: string | null;
	setErrorText: SetState<string | null>;
	is_friend_payment: boolean | null;

	updateService: IUseUpdateOrder;
}

export default function OrderStepper(props: OrderStepperProps) {
	const isDeviceIos = isIOS();
	const { menuInStoreService, addressValue, addressCoords } = useShopContext();
	const [showPayments, setShowPayments] = useState<boolean>(false);
	const {
		authService: { user },
	} = useAppContext();

	const {
		selectedStore,
		shipmentsService: {
			refetchShipments,
			shipments,
			shipmentsCount,
			baseShipmentsCount,
			getBaseShipment,
		},
		shipmentsService,
		cartService: { calculateCartTotalSum, incustService, computedIsOnlyServiceProductsInCart },
	} = useSelectedStoreContext();
	const localisation = useLocalisation();
	const stepper = useStepper();

	const [createdOrder, setCreatedOrder] = useState<Order | null>(null);

	const setForm = props.setForm;

	const {
		selectedShipment,
		setSelectedShipment,
		setSelectedCustomShipmentGroup,
		selectedShipmentPrice,
	} = props.selectedShipmentService;

	const computedTotalAmount = useMemo(() => {
		let sum;
		if (
			incustService.processedCheck &&
			(incustService.processedCheck.amount_to_pay ||
				incustService.processedCheck.amount_to_pay === 0)
		)
			sum = incustService.processedCheck.amount_to_pay;
		else sum = calculateCartTotalSum();

		if (selectedShipmentPrice && !selectedShipment?.is_paid_separately) {
			sum += selectedShipmentPrice.cost_delivery;
		}

		return sum;
	}, [
		incustService.processedCheck,
		calculateCartTotalSum,
		selectedShipmentPrice,
		selectedShipment?.is_paid_separately,
	]);

	const shipmentPricesService = useShipmentPrices(
		shipmentsService,
		props.selectedShipmentService,
		selectedStore.id,
		calculateCartTotalSum,
		addressValue,
		addressCoords
	);

	const setTipAmount = useCallback(
		(amount: number) => {
			setForm({ tips_sum: amount });
		},
		[setForm]
	);

	const tipsService = useTipsService(props.form.tips_sum || 0, setTipAmount, computedTotalAmount);

	useEffect(() => {
		if (menuInStoreService.menuInStore?.id) {
			setSelectedShipment(getBaseShipment("in_store"));
		} else {
			if (!selectedShipment && shipmentsCount === 1) {
				if (baseShipmentsCount) {
					const shipment =
						shipments.base[0].base_type !== "in_store"
							? shipments.base[0]
							: shipments.base[1];
					setSelectedShipment(shipment);
				} else if (shipments.groups.length) {
					setSelectedCustomShipmentGroup(shipments.groups[0]);
					setSelectedShipment(shipments.groups[0].shipments[0]);
				} else if (shipments.rest.length) {
					setSelectedShipment(shipments.rest[0]);
				} else {
					setSelectedShipment(null);
					setSelectedCustomShipmentGroup(null);
				}
			} else if (selectedShipment?.base_type === "in_store") {
				setSelectedShipment(null);
				setSelectedCustomShipmentGroup(null);
			}
		}
	}, [
		menuInStoreService.menuInStore?.id,
		selectedShipment,
		setSelectedCustomShipmentGroup,
		setSelectedShipment,
		shipments.base,
		shipments.groups,
		shipments.rest,
		shipmentsCount,
		baseShipmentsCount,
		getBaseShipment,
	]);

	const getShipmentById = shipmentsService.getShipmentById;
	const setSelectedTipPercent = tipsService.setSelectedTipPercent;
	const setActiveStep = stepper.setActiveStep;
	const tryRestoreOrderState = useCallback(() => {
		const orderState = getOrderState();
		if (orderState) {
			if (orderState.createdOrder) {
				setCreatedOrder(orderState.createdOrder);
				let activeStep = 1;
				if (
					props.selectedShipmentService.showShipments &&
					!shipmentsService.computedStoreEmptyShipments &&
					!computedIsOnlyServiceProductsInCart &&
					selectedShipment?.base_type !== "no_delivery"
				) {
					activeStep += 1;
				}
				if (selectedShipment?.enabled_tips) {
					activeStep += 1;
				}
				setActiveStep(activeStep);
			}
			if (orderState.shipmentId) {
				setSelectedShipment(getShipmentById(orderState.shipmentId) as SelectedShipmentType);
				const isGroup = shipments.groups.some(x =>
					x.shipments.some(i => i.id === orderState.shipmentId)
				);
				if (isGroup) {
					setSelectedCustomShipmentGroup(
						shipments.groups.find(x =>
							x.shipments.some(i => i.id === orderState.shipmentId)
						) as any
					);
				}
			}
			if (orderState.tipPercent) {
				setSelectedTipPercent(orderState.tipPercent);
			}
			if (orderState.personalData || orderState.createdOrder) {
				setForm({
					desired_delivery_time: orderState.createdOrder?.desired_delivery_time || null,
					desired_delivery_date: orderState.createdOrder?.desired_delivery_date || null,
					first_name:
						orderState.personalData?.first_name ||
						orderState.createdOrder?.first_name ||
						"",
					last_name:
						orderState.personalData?.last_name ||
						orderState.createdOrder?.last_name ||
						"",
					email: orderState.personalData?.email || orderState.createdOrder?.email || "",
					address_comment:
						orderState.personalData?.address_comment ||
						orderState.createdOrder?.address_comment ||
						"",
					address_floor:
						orderState.personalData?.address_floor ||
						orderState.createdOrder?.address_floor ||
						0,
					address_house:
						orderState.personalData?.address_house ||
						orderState.createdOrder?.address_house ||
						"",
					address_flat:
						orderState.personalData?.address_flat ||
						orderState.createdOrder?.address_flat ||
						"",
					address_entrance:
						orderState.personalData?.address_entrance ||
						parseInt(orderState.createdOrder?.address_entrance || "0") ||
						0,
					address_street:
						orderState.personalData?.address_street ||
						orderState.createdOrder?.address_street ||
						"",
					comment:
						orderState.personalData?.comment || orderState.createdOrder?.comment || "",
					custom_shipment_comment:
						orderState.personalData?.custom_shipment_comment ||
						orderState.createdOrder?.shipment.comment ||
						"",
					phone:
						((orderState.personalData?.phone || orderState.createdOrder?.phone) &&
							matchIsValidTel(
								orderState.personalData?.phone
									? orderState.personalData?.phone
									: orderState.createdOrder?.phone || ""
							) &&
							(orderState.personalData?.phone || orderState.createdOrder?.phone)) ||
						"",
				});
			}
		}
	}, [
		props.selectedShipmentService.showShipments,
		shipmentsService.computedStoreEmptyShipments,
		computedIsOnlyServiceProductsInCart,
		selectedShipment?.base_type,
		selectedShipment?.enabled_tips,
		setActiveStep,
		setSelectedShipment,
		getShipmentById,
		shipments.groups,
		setSelectedCustomShipmentGroup,
		setSelectedTipPercent,
		setForm,
	]);

	useEffect(() => {
		if (!selectedShipment?.enabled_tips && props.form.tips_sum) {
			setForm({ tips_sum: 0 });
		}
	}, [setForm, selectedShipment, props.form.tips_sum]);

	useEffect(() => {
		refetchShipments();
	}, [refetchShipments]);

	useEffect(() => {
		tryRestoreOrderState();
	}, [tryRestoreOrderState]);

	return (
		<div ref={stepper.ref}>
			<Stepper activeStep={stepper.activeStep} orientation={"vertical"}>
				{!menuInStoreService.menuInStore?.id && (
					<Step key={"personalData"}>
						<StepLabel StepIconComponent={StepIconComponent} icon={"user"}>
							{localisation.orders.stepPersonalHeader}
						</StepLabel>
						<StepContent>
							<PersonalDataStep
								form={props.form}
								setForm={props.setForm}
								email_required={
									props.paymentsService.paymentsInfo?.email_required || []
								}
								setPrevStep={() => {
									changePage(`/shop/${selectedStore.id}/cart`);
								}}
								setNextStep={stepper.setNextStep}
								updateService={props.updateService}
							/>
						</StepContent>
					</Step>
				)}
				{props.selectedShipmentService.showShipments &&
					!shipmentsService.computedStoreEmptyShipments &&
					!computedIsOnlyServiceProductsInCart &&
					selectedShipment?.base_type !== "no_delivery" && (
						<Step key={"delivery"}>
							<StepLabel StepIconComponent={StepIconComponent} icon={"delivery"}>
								{localisation.orders.stepDeliveryHeader}
							</StepLabel>
							<StepContent>
								<Shipping
									selectedShipmentService={props.selectedShipmentService}
									computedShopImage={props.computedShopImage}
									form={props.form}
									setForm={props.setForm}
									setPrevStep={stepper.setPrevStep}
									setNextStep={stepper.setNextStep}
									computedTotalAmount={computedTotalAmount}
									shipmentPricesService={shipmentPricesService}
									updateService={props.updateService}
								/>
							</StepContent>
						</Step>
					)}
				{selectedShipment?.enabled_tips && (
					<Step>
						<StepLabel StepIconComponent={StepIconComponent} icon={"tips"}>
							{localisation.global.tips}
						</StepLabel>
						<StepContent>
							<Tips
								form={props.form}
								setForm={props.setForm}
								setPrevStep={stepper.setPrevStep}
								setNextStep={stepper.setNextStep}
								computedTotalAmount={computedTotalAmount}
								tipsService={tipsService}
								updateService={props.updateService}
							/>
						</StepContent>
					</Step>
				)}
				<Step last={!createdOrder} key={"final"}>
					<StepLabel StepIconComponent={StepIconComponent} icon={"final"}>
						{localisation.orders.orderConfirmStepHeader}
					</StepLabel>
					<StepContent
						transitionDuration={isDeviceIos ? 0 : 300}
						sx={{ paddingBottom: 3 }}
					>
						<ConfirmationStep
							form={props.form}
							setForm={props.setForm}
							billingForm={props.billingForm}
							setBillingForm={props.setBillingForm}
							paymentsService={props.paymentsService}
							selectedShipmentService={props.selectedShipmentService}
							needLoginForEmail={props.needLoginForEmail}
							setNeedLoginForEmail={props.setNeedLoginForEmail}
							errorText={props.errorText}
							setErrorText={props.setErrorText}
							setCreatedOrder={setCreatedOrder}
							createdOrder={createdOrder}
							computedTotalAmount={computedTotalAmount}
							notAvailableProducts={props.notAvailableProducts}
							setNotAvailableProducts={props.setNotAvailableProducts}
							setPrevStep={stepper.setPrevStep}
							setNextStep={stepper.setNextStep}
							incustService={incustService}
							shipmentPricesService={shipmentPricesService}
							setShowPayments={setShowPayments}
							updateOrderService={props.updateService}
						/>
					</StepContent>
				</Step>
			</Stepper>

			<PaymentsModal
				show={showPayments}
				setShow={setShowPayments}
				orderId={createdOrder?.id}
				type={"order"}
				selectedStoreId={selectedStore.id}
				paymentService={props.paymentsService}
				createdOrder={createdOrder}
				user={user}
				orderToken={createdOrder?.token}
			/>
		</div>
	);
}

export function StepIconComponent({ icon, completed, active }: StepIconProps) {
	const icons: { [index: string]: React.ReactElement } = {
		login: <LoginIcon fontSize={"inherit"} />,
		user: <PersonIcon fontSize={"inherit"} />,
		delivery: <LocalShippingIcon fontSize={"inherit"} />,
		payment: <PaymentIcon fontSize={"inherit"} />,
		deliveryTime: <QueryBuilderIcon fontSize={"inherit"} />,
		final: <CheckIcon fontSize={"inherit"} />,
		done: <DoneAllIcon fontSize={"inherit"} />,
		tips: <AttachMoneyOutlinedIcon fontSize={"inherit"} />,
	};

	const disabled = !completed && !active;

	return (
		<Box
			sx={{
				padding: 0,
				borderRadius: 50,
				fontSize: "14px",
				textAlign: "center",
				minWidth: "unset",
				boxShadow: "none!important",
				width: "25px",
				height: "25px",
				backgroundColor: disabled ? "action.disabledBackground" : "primary.main",
				color: disabled ? "action.disabled" : "primary.contrastText",
			}}
		>
			{icons[String(icon)]}
		</Box>
	);
}
