import { useEffect, useState } from "react";
import { BotWaitInfoType, ShopContextType } from "./types";
import api from "../../api";
import { BrandInfo } from "../../api/shop/basic/types";
import { useLocation } from "react-router-dom";
import { SetState } from "../../types";
import useAddressValue, { useAddressCoords } from "./useAddressValue";
import useMenuInStoreService from "../services/useMenuInStoreService";
import { LoadingManager } from "../../features/hooks/useLoadingManager";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";

export default function useShopContextMaker(
	brandInfo: BrandInfo,
	setPageTitle: SetState<string | null>,
	botId: number | null | undefined,
	loadingManager: LoadingManager
): ShopContextType {
	const [botWaitInfo, setBotWaitInfo] = useState<BotWaitInfoType>({
		active: false,
		isError: false,
	});
	const webAppData = useWebApp();

	const [addressValue, setAddressValue] = useAddressValue();
	const [addressCoords, setAddressCoords] = useAddressCoords();

	const [showGeoModal, setShowGeoModal] = useState(false);

	const [showDetailedProduct, setShowDetailedProduct] = useState(false);

	const [isSingleShop, setIsSingleShop] = useState(false);
	const [needTryLastStoreOnSelect, setNeedTryLastStoreOnSelect] = useState(true);

	const [categoriesMenuTitle, setCategoriesMenuTitle] = useState("");

	const [showAIChat, setShowAIChat] = useState(false);

	const menuInStoreService = useMenuInStoreService(brandInfo);

	const [isEMenu, setIsEMenu] = useState<boolean>(
		menuInStoreService.menuInStore?.is_e_menu || false
	);

	useSetPageTitle(setPageTitle, brandInfo);

	useLoadLastStore(
		loadingManager,
		needTryLastStoreOnSelect,
		setNeedTryLastStoreOnSelect,
		brandInfo
	);

	return {
		botId,
		brandInfo,
		menuInStoreService,
		botWaitInfo,
		setBotWaitInfo,
		showAIChat,
		setShowAIChat,
		webAppData,
		addressValue,
		setAddressValue,
		showGeoModal,
		setShowGeoModal,
		showDetailedProduct,
		setShowDetailedProduct,
		isSingleShop,
		setIsSingleShop,
		categoriesMenuTitle,
		setCategoriesMenuTitle,
		isEMenu,
		needTryLastStoreOnSelect,
		setNeedTryLastStoreOnSelect,
		setIsEMenu,
		addressCoords,
		setAddressCoords,
	};
}

function useLoadLastStore(
	loadingManager: LoadingManager,
	needTryLastStoreOnSelect: boolean,
	setNeedTryLastStoreOnSelect: SetState<boolean>,
	brandInfo: BrandInfo
) {
	const [searchParams, setSearchParams] = useSearchParamsFixed();
	const location = useLocation();

	const [noRedirect, setNoRedirect] = useState<boolean>(false);

	const setIsLoading = loadingManager.setIsLoading;
	const isLastStoreLoading = loadingManager.getIsLoading("last-store");
	useEffect(() => {
		if (isLastStoreLoading || noRedirect) return;
		if (
			location.pathname !== "/shop" &&
			location.pathname !== "/shop/" &&
			location.pathname !== "/shop/select" &&
			location.pathname !== "/shop/select/"
		) {
			return;
		}
		const isNoRedirect = searchParams.get("no_redirect");
		if (isNoRedirect) {
			setSearchParams(prev => {
				prev.delete("no_redirect");
				return prev;
			});
			if (brandInfo.stores_count > 1) {
				setNoRedirect(true);
				return;
			}
		}

		if (!needTryLastStoreOnSelect) {
			if (location.pathname === "/shop" || location.pathname === "/shop/") {
				changePage("/shop/select");
			}
			return;
		}

		const lastStoreId = api.shop.basic.getLastStoreId();
		if (!lastStoreId) {
			if (location.pathname === "/shop" || location.pathname === "/shop/") {
				changePage("/shop/select");
			}
			return setNeedTryLastStoreOnSelect(false);
		}

		if (
			location.pathname === "/shop" ||
			location.pathname === "/shop/" ||
			location.pathname === "/shop/select" ||
			location.pathname === "/shop/select/"
		) {
			setIsLoading("last-store", true);
			api.shop.basic
				.getStore(lastStoreId, {
					brand_id: brandInfo.id,
				})
				.then(response => {
					changePage(`/shop/${response.data.id}/menu`);
				})
				.catch(err => {
					console.log(err);
					if (location.pathname === "/shop" || location.pathname === "/shop/") {
						changePage("/shop/select");
					}

					if (err?.response?.status === 404) {
						api.shop.basic.removeLastStoreId();
					}
				})
				.finally(() => {
					setIsLoading("last-store", false);
					setNeedTryLastStoreOnSelect(false);
				});
		}
	}, [
		location.pathname,
		isLastStoreLoading,
		setIsLoading,
		brandInfo.id,
		needTryLastStoreOnSelect,
		setNeedTryLastStoreOnSelect,
		searchParams,
		setSearchParams,
		noRedirect,
		brandInfo.stores_count,
	]);
}

function useWebApp(): string | null {
	useEffect(() => {
		if (window.Telegram) {
			window.Telegram.WebApp.ready();
			window.Telegram.WebApp.expand();
		}
	}, []);

	return window.Telegram?.WebApp?.initData || null;
}

function useSetPageTitle(setPageTitle: SetState<string | null>, brandInfo: BrandInfo) {
	const {
		groupService: { group },
	} = useAppContext();
	useEffect(() => {
		if (group?.texts.web.pages_headers.base) {
			setPageTitle(prevState => {
				if (!prevState) {
					group.texts.web.pages_headers.base.replace("{name}", brandInfo.name);
				}
				return prevState;
			});
		}
	}, [setPageTitle, brandInfo.name, group?.texts.web.pages_headers.base]);
}
