import { useCallback } from "react";
import { PaymentPayload, PaymentProvidersData } from "../../../api/shop/payments/types";
import api from "../../../api";
import useAppContext from "../../../useAppContext";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { manualRedirectToPaymentMethod, manualSendEpay, manualSendLiqpayForm } from "../functions";
import { GoPayDataType, PayProviders } from "../types";

export default function useGoPay(
	storeId: number | null = null,
	setError?: (error: string) => void
): IUseGoPay {
	const {
		lang,
		botId,
		brandInfo,
		showError,
		authService: { token: userToken },
	} = useAppContext();
	const localisation = useLocalisation();

	const uniposPay = useCallback(
		async (
			payments: PaymentProvidersData
			// data: GoPayDataType
		) => {
			let checkout_url;
			if (payments?.unipos?.error) {
				showError(localisation.payment.providerError.replace("{err}", ""), true);
				return false;
			}
			if (payments?.unipos?.is_qr && payments.unipos.data.url) {
				const encoded_pmt_url = encodeURIComponent(payments.unipos.data.url);
				checkout_url = `/qrcode/${encoded_pmt_url}?brand_id=${brandInfo?.id}`;

				if (botId !== null) {
					checkout_url += `&bot_id=${botId}`;
				}
				if (payments.unipos.description !== undefined) {
					checkout_url += `&description=${encodeURIComponent(payments.unipos.description)}`;
				}
			} else {
				checkout_url = payments?.unipos?.data.url;
			}
			if (checkout_url) {
				manualRedirectToPaymentMethod(payments?.unipos?.is_external, checkout_url);
			}
		},
		[botId, brandInfo?.id, localisation.payment.providerError, showError]
	);

	const goPay = useCallback(
		async (provider: PayProviders, payments: PaymentProvidersData | null) => {
			if (!payments) {
				console.log("no_payments_with_buttons");
				return false;
			}

			switch (provider) {
				case PayProviders.LiqPay:
					if (!payments.liqpay) {
						console.log("payment_method_not_available liqpay");
						return false;
					}
					manualSendLiqpayForm(
						payments.liqpay.data.data || "",
						payments.liqpay.data.signature || ""
					);
					break;
				case PayProviders.Stripe:
					if (!payments.stripe?.data.url) {
						console.log("payment_method_not_available stripe");
						if (payments.stripe?.error && setError) setError(payments.stripe?.error);
						return false;
					}
					manualRedirectToPaymentMethod(
						payments.stripe.is_external,
						payments.stripe.data.url
					);
					break;
				case PayProviders.Unipos:
					if (!payments.unipos) {
						console.log("payment_method_not_available unipos");
						return false;
					}
					await uniposPay(payments);
					break;
				case PayProviders.Wave:
					if (!payments.wave?.data.url) {
						console.log("payment_method_not_available wave");
						if (payments.wave?.error && setError) setError(payments.wave?.error);
						return false;
					}
					manualRedirectToPaymentMethod(
						payments.wave.is_external,
						payments.wave.data.url
					);
					break;
				case PayProviders.Flutterwave:
					if (!payments.flutterwave?.data.url) {
						console.log("payment_method_not_available flutterwave");
						if (payments.flutterwave?.error && setError)
							setError(payments.flutterwave?.error);
						return false;
					}
					manualRedirectToPaymentMethod(
						payments.flutterwave.is_external,
						payments.flutterwave.data.url
					);
					break;
				case PayProviders.Comsa:
					if (!payments.comsa?.data.url) {
						console.log("payment_method_not_available comsa");
						if (payments.comsa?.error && setError) setError(payments.comsa?.error);
						return false;
					}
					manualRedirectToPaymentMethod(
						payments.comsa.is_external,
						payments.comsa.data.url
					);
					break;
				case PayProviders.Pl24:
					if (!payments.pl24?.data.url) {
						console.log("payment_method_not_available pl24");
						if (payments.pl24?.error && setError) setError(payments.pl24?.error);
						return false;
					}
					manualRedirectToPaymentMethod(
						payments.pl24.is_external,
						payments.pl24.data.url
					);
					break;
				case PayProviders.Tpay:
					if (!payments.tpay?.data.url) {
						console.log("payment_method_not_available tpay");
						if (payments.tpay?.error && setError) setError(payments.tpay?.error);
						return false;
					}
					manualRedirectToPaymentMethod(
						payments.tpay.is_external,
						payments.tpay.data.url
					);
					break;
				case PayProviders.Orange:
					if (!payments.orange) {
						console.log("payment_method_not_available orange");
						return false;
					}
					break;
				case PayProviders.Fondy:
					if (!payments.fondy?.data.url) {
						console.log("payment_method_not_available fondy");
						if (payments.fondy?.error && setError) setError(payments.fondy?.error);
						return false;
					}
					manualRedirectToPaymentMethod(
						payments.fondy.is_external,
						payments.fondy.data.url
					);
					break;
				case PayProviders.Freedompay:
					if (!payments.freedompay?.data.url) {
						console.log("payment_method_not_available freedompay");
						if (payments.freedompay?.error && setError) {
							setError(payments.freedompay?.error);
						}
						return false;
					}
					manualRedirectToPaymentMethod(
						payments.freedompay.is_external,
						payments.freedompay.data.url
					);
					break;
				case PayProviders.Epay:
					if (!payments.epay?.data) {
						console.log("payment_method_not_available epay");
						if (payments.epay?.error && setError) setError(payments.epay?.error);
						return false;
					}
					manualSendEpay(payments.epay.data);
					break;
				default:
					console.log("Unknown error");
					showError("unknown payment error");
					return false;
			}

			return true;
		},
		[uniposPay, setError, showError]
	);

	const autoGoPay = useCallback(
		async (data: GoPayDataType) => {
			let payload: PaymentPayload = {
				store_id: storeId,
				order_id: data.orderId,
				order_token: data.orderToken,
				is_webview: !!window.Telegram.WebApp.initData,
				invoice_id: data.invoiceId,
				invoice_token: data.invoiceToken,
				bot_id: botId,
				lang: lang,
			};
			const payments = await api.shop.payments
				.makePayment(payload, userToken || payload.order_token || payload.invoice_token)
				.then(response => response.data);

			if (payments.incust_pay) {
				return "not_available_auto_go_pay";
			}

			for (const key in payments) {
				if (key !== "amount_to_pay" && key !== "payment_providers_count") {
					const providerData = payments[key as keyof PaymentProvidersData];
					if (
						providerData &&
						typeof providerData === "object" &&
						"error" in providerData &&
						providerData.error
					) {
						throw new Error(`${providerData.error}`);
					}
				}
			}

			return true;
		},
		[botId, lang, storeId, userToken]
	);

	return {
		autoGoPay,
		goPay,
	};
}

export interface IUseGoPay {
	autoGoPay: (data: GoPayDataType) => Promise<string | boolean>;
	goPay: (provider: PayProviders, payments: PaymentProvidersData | null) => Promise<boolean>;
}
