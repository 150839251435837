import { Box, Step, StepContent, StepIconProps, StepLabel, Stepper } from "@mui/material";
import useAppContext from "../../../../useAppContext";
import PersonIcon from "@mui/icons-material/Person";
import { MakeInvoiceStepperProps } from "../types";
import PersonalDataStep from "./steps/PersonalDataStep";
import React, { ReactElement, useState } from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentIcon from "@mui/icons-material/Payment";
import ConfirmationStep from "./steps/ConfirmationStep";
import CheckIcon from "@mui/icons-material/Check";
import PaymentDataStep from "./steps/PaymentDataStep";
import { PaymentsModal } from "../../Payment/Payments";
import { useSelectedStoreOptional } from "../../../SelectedStore/context";

export default function MakeInvoiceStepper(props: MakeInvoiceStepperProps) {
	const {
		localisation,
		authService: { user },
	} = useAppContext();
	const selectedStore = useSelectedStoreOptional();

	const { stepper } = props;
	const isEmailRequiredForPayment = !!props.paymentsService.paymentsInfo?.email_required.length;

	const [showPayments, setShowPayments] = useState<boolean>(false);

	return (
		<div ref={stepper.ref}>
			<Stepper activeStep={stepper.activeStep} orientation={"vertical"}>
				<Step>
					<StepLabel StepIconComponent={StepIconComponent} icon={"paymentData"}>
						{localisation.payment.paymentDataStepHeaderText}
					</StepLabel>
					<StepContent>
						<PaymentDataStep
							form={props.form}
							setForm={props.setForm}
							goBack={props.goBack}
							setNextStep={stepper.setNextStep}
							invoiceTemplate={props.invoiceTemplate}
							currency={props.currency}
							enteredAmount={props.enteredAmount}
							incustService={props.incustService}
						/>
					</StepContent>
				</Step>
				<Step>
					<StepLabel StepIconComponent={StepIconComponent} icon={"user"}>
						{localisation.orders.stepPersonalHeader}
					</StepLabel>
					<StepContent>
						<PersonalDataStep
							form={props.form}
							setForm={props.setForm}
							setPrevStep={stepper.setPrevStep}
							setNextStep={stepper.setNextStep}
							invoiceTemplate={props.invoiceTemplate}
							isEmailRequiredForPayment={isEmailRequiredForPayment}
						/>
					</StepContent>
				</Step>

				<Step>
					<StepLabel StepIconComponent={StepIconComponent} icon={"check"}>
						{localisation.payment.confirmationStepHeader}
					</StepLabel>
					<StepContent>
						<ConfirmationStep
							form={props.form}
							setForm={props.setForm}
							setPrevStep={stepper.setPrevStep}
							setNextStep={stepper.setNextStep}
							invoiceTemplate={props.invoiceTemplate}
							incustService={props.incustService}
							paymentService={props.paymentsService}
							currency={props.currency}
							paymentMode={props.paymentMode}
							menuInStoreId={props.menuInStoreId}
							setInvoiceCreated={props.setInvoiceCreated}
							onInvoiceCreated={props.onInvoiceCreated}
							errorText={props.errorText}
							setErrorText={props.setErrorText}
							setShowPayments={setShowPayments}
							invoiceCreated={props.invoiceCreated}
						/>
					</StepContent>
				</Step>
			</Stepper>

			<PaymentsModal
				show={showPayments}
				setShow={setShowPayments}
				type={"invoice"}
				selectedStoreId={selectedStore?.id}
				paymentService={props.paymentsService}
				invoiceCreated={props.invoiceCreated}
				user={user}
				invoiceToken={props.invoiceCreated?.token_data.token}
				onlyOnlinePayments={true}
			/>
		</div>
	);
}

function StepIconComponent({ icon, completed, active }: StepIconProps) {
	const icons: { [index: string]: ReactElement } = {
		paymentData: <EditNoteIcon fontSize={"inherit"} />,
		user: <PersonIcon fontSize={"inherit"} />,
		positions: <ReceiptIcon fontSize={"inherit"} />,
		check: <CheckIcon fontSize={"inherit"} />,
		payment: <PaymentIcon fontSize={"inherit"} />,
	};

	const disabled = !completed && !active;

	return (
		<Box
			sx={{
				padding: 0,
				borderRadius: 50,
				fontSize: "14px",
				textAlign: "center",
				minWidth: "unset",
				boxShadow: "none!important",
				width: "25px",
				height: "25px",
				backgroundColor: disabled ? "action.disabledBackground" : "primary.main",
				color: disabled ? "action.disabled" : "primary.contrastText",
			}}
		>
			{icons[String(icon)]}
		</Box>
	);
}
