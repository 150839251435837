import PersonalDataStepBase from "../../../../../components/steppers/steps/PersonalDataStepBase/PersonalDataStepBase";
import NavigationButtons from "../NavigationButtons";
import { PersonalDataStepProps } from "../../types";
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import useAppContext from "../../../../../useAppContext";
import useCheckEmail, { CheckEmailResult } from "../../../../../hooks/useCheckEmail";
import { Alert } from "@mui/material";
import { useShopContext } from "../../../../context";
import { OrderFieldSettingMode } from "../../../../../api/shop/basic/types";
import Interweave from "../../../../../features/Interweave";

export default function PersonalDataStep(props: PersonalDataStepProps) {
	const {
		authService: { client },
	} = useAppContext();
	const { brandInfo } = useShopContext();

	const [isValidPhone, setIsValidPhone] = useState(true);
	const [showInvalidError, setShowInvalidError] = useState(false);

	const [isCheckEmailLoading, setIsCheckEmailLoading] = useState(false);
	const [checkEmailError, setCheckEmailError] = useState("");

	const checkEmail = useCheckEmail();

	const fieldsSettings = useMemo(() => {
		let email: OrderFieldSettingMode = brandInfo.order_fields_settings[client].email;
		let phone: OrderFieldSettingMode = brandInfo.order_fields_settings[client].phone;

		if (props.invoiceTemplate) {
			if (props.invoiceTemplate.need_email) {
				email = "required";
			} else if (email !== "optional") {
				email = "disabled";
			}

			if (props.invoiceTemplate.need_phone) {
				phone = "required";
			} else if (phone !== "optional") {
				phone = "disabled";
			}
		}

		return { email, phone };
	}, [brandInfo.order_fields_settings, client, props.invoiceTemplate]);

	const setNextStep = props.setNextStep;
	const onSubmit = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (
				!props.form.first_name ||
				!props.form.last_name ||
				(fieldsSettings.phone === "required" && !isValidPhone) ||
				(fieldsSettings.email === "required" &&
					(!props.form.email?.includes("@") || props.form.email?.endsWith("@")))
			) {
				setShowInvalidError(true);
			} else {
				setIsCheckEmailLoading(true);

				const check = () => {
					if (!props.form.email) {
						return { ok: true, error: null };
					} else {
						return checkEmail(props.form.email);
					}
				};

				Promise.resolve<CheckEmailResult>(check())
					.then(result => {
						if (result.ok) {
							setNextStep();
							setCheckEmailError("");
						} else {
							setCheckEmailError(result.error || "");
						}
					})
					.finally(() => {
						setIsCheckEmailLoading(false);
					});
			}
		},
		[
			fieldsSettings.email,
			fieldsSettings.phone,
			props.form.first_name,
			props.form.last_name,
			props.form.email,
			isValidPhone,
			setNextStep,
			checkEmail,
		]
	);

	useEffect(() => {
		setCheckEmailError("");
	}, [props.form.email]);

	return (
		<PersonalDataStepBase
			form={props.form}
			setForm={props.setForm}
			onSubmit={onSubmit}
			isValidPhone={isValidPhone}
			setIsValidPhone={setIsValidPhone}
			showInvalidError={showInvalidError}
			isPhoneRequired={fieldsSettings.phone === "required"}
			isEmailRequired={fieldsSettings.email === "required"}
			fields={{
				email: fieldsSettings.email !== "disabled",
				phone: fieldsSettings.phone !== "disabled",
			}}
		>
			{checkEmailError && (
				<Alert severity={"error"} sx={{ mt: 2 }}>
					<Interweave content={checkEmailError} />
				</Alert>
			)}
			<NavigationButtons
				onPrevClick={props.setPrevStep}
				disabledNext={!!checkEmailError}
				loadingNext={isCheckEmailLoading}
			/>
		</PersonalDataStepBase>
	);
}
