import { AxiosInstance } from "axios";

import {
	IncustPayCardInfo,
	IncustPayPayData,
	IncustPayPaymentData,
	IncustPaySuccess,
	PaymentPayload,
	PaymentProvidersData,
} from "./types";
import { PaymentStatusResponse } from "../order/types";

export default function PaymentsApiModule(instance: AxiosInstance) {
	const getPath = (path: string) => `/payments/${path}`;

	const getHeaders = (token: string | null | undefined) =>
		token ? { Authorization: `Bearer ${token}` } : undefined;

	return {
		makePayment(
			payload: PaymentPayload,
			token?: string | null | undefined,
			payment_method?: string
		) {
			console.log(`payment_method=${payment_method}`);
			return instance.post<PaymentProvidersData>(getPath("make_payment"), payload, {
				headers: getHeaders(token),
				params: {
					payment_method: payment_method,
				},
			});
		},

		getIncustPayCardInfo(incustPayConfigurationId: number, cardNumber: string) {
			return instance.get<IncustPayCardInfo>(
				getPath(`incustPay/${incustPayConfigurationId}/card/${cardNumber}`),
				{
					headers: { Authorization: "" },
				}
			);
		},

		payWithIncust(payload: IncustPayPayData) {
			return instance.post<IncustPaySuccess>(getPath("incustPay/pay"), payload, {
				headers: payload.id_type === "card" ? { Authorization: "" } : undefined,
			});
		},

		getIncustPayData(storeId?: number | null, isTopup: boolean = false) {
			const params: { store_id?: number | null; is_topup?: boolean } = {};
			if (storeId) params["store_id"] = storeId;
			if (isTopup) params["is_topup"] = true;

			return instance.get<IncustPayPaymentData>("/store/payments/incust_pay_data", {
				params: params,
			});
		},
	};
}
