import {
	BillingAddress,
	CreateOrderPayload,
	CreateOrderProduct,
} from "../../../api/shop/order/types";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import useMergeState from "../../../helpers/useMergedState";
import OrderStepperLite from "./OrderStepperLite";
import useSelectedShipmentService from "../MakeOrder/useSelectedShipmentService";
import { ReactNode, useCallback, useEffect, useState } from "react";
import useShipmentPrices from "../../services/useShipmentPrices";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import { matchIsValidTel } from "mui-tel-input";
import { ITopupMakeOrderResult, IUseProductTopup } from "../../menu/product/hooks/useProductTopup";
import useNewPaymentsService from "../../payments/hooks/useNewPaymentsService";

interface IMakeOrderLiteProps {
	product: CreateOrderProduct;
	amount: number;
	confirmationContent: ReactNode;
	submit: (form: CreateOrderPayload) => Promise<ITopupMakeOrderResult>;
	backCallback?: () => void;
	service?: IUseProductTopup | null;
}

let confirmEmailInterval: any = null;

export default function MakeOrderLite(props: IMakeOrderLiteProps) {
	const {
		authService: { user, loadUser },
	} = useAppContext();
	const { selectedStore, shipmentsService } = useSelectedStoreContext();
	const shipment = shipmentsService.noDeliveryTypeShipment;

	const initialForm: CreateOrderPayload = {
		store_id: selectedStore.id,
		is_accepted_agreement: true,
		products: [props.product],
		shipment_method_id: shipment?.id || 0,
		free_shipment: true,
		first_name: "",
		last_name: "",
		phone: "",
		price: null,
		type: "topup",
		email: "",
	};

	const initialBillingAddress: BillingAddress = {
		first_name: "",
		last_name: "",

		company_name: "",
		vat_number: "",
		registration_number: "",

		country: "",
		state: "",
		city: "",
		zip_code: "",

		address_1: "",
		address_2: "",
		phone_number: "",
	};

	const [form, setForm] = useMergeState(initialForm);
	const [billingForm, setBillingForm] = useMergeState<BillingAddress>(initialBillingAddress);
	const [errorText, setErrorText] = useState<string | null>(null);
	const [needLoginForEmail, setNeedLoginForEmail] = useState<boolean>(false);

	const totalSum = props.amount;
	const selectedShipmentService = useSelectedShipmentService(totalSum);
	const paymentsService = useNewPaymentsService(
		selectedStore.id,
		selectedShipmentService.selectedShipment,
		selectedStore.currency
	);

	const shipmentPricesService = useShipmentPrices(
		shipmentsService,
		selectedShipmentService,
		selectedStore.id,
		() => props.amount
	);

	const isUser = !!user;
	const waitConfirmEmail = useCallback(() => {
		confirmEmailInterval = setInterval(async () => {
			await loadUser();
		}, 3000);
	}, [loadUser]);
	useEffect(() => {
		if (isUser) {
			setNeedLoginForEmail(false);

			if (!confirmEmailInterval && user?.email && !user.is_confirmed_email) {
				waitConfirmEmail();
			}

			if (user?.is_confirmed_email && confirmEmailInterval) {
				clearInterval(confirmEmailInterval);
			}
		}
	}, [isUser, user?.email, user?.is_confirmed_email, waitConfirmEmail]);

	const fillFormWithProfileData = useCallback(() => {
		const lastPersonalData = api.shop.order.getLastPersonalData();
		setForm({
			first_name: user?.first_name || lastPersonalData?.first_name || "",
			last_name: user?.last_name || lastPersonalData?.last_name || "",
			email: user?.email || lastPersonalData?.email || "",
			phone:
				(user?.wa_phone && `+${user.wa_phone}`) ||
				(lastPersonalData?.phone &&
					matchIsValidTel(lastPersonalData.phone) &&
					lastPersonalData.phone) ||
				"",
		});
	}, [setForm, user]);

	useEffect(() => {
		fillFormWithProfileData();
	}, [fillFormWithProfileData]);

	return (
		<OrderStepperLite
			form={form}
			setForm={setForm}
			paymentsService={paymentsService}
			billingForm={billingForm}
			setBillingForm={setBillingForm}
			selectedShipmentService={selectedShipmentService}
			shipmentPricesService={shipmentPricesService}
			errorText={errorText}
			needLoginForEmail={needLoginForEmail}
			confirmationContent={props.confirmationContent}
			setErrorText={setErrorText}
			submit={props.submit}
			setNeedLoginForEmail={setNeedLoginForEmail}
			backCallback={props.backCallback}
			service={props.service}
		/>
	);
}
