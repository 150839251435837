import useAppContext from "../../useAppContext";
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {IInvoiceCreatedResult, InvoicePaymentMode, InvoiceTemplate} from "../../api/invoice/types";
import api from "../../api";
import {useEffect, useMemo} from "react";
import {Box, Typography} from "@mui/material";
import MakeInvoice from "./MakeInvoice";
import Interweave from "../../features/Interweave";


const LOADING_KEY = "payment-page-invoice-template"


interface FastPayProps {
    paymentMode: InvoicePaymentMode
    enteredAmount?: number | null
    invoiceTemplateId?: number | null
    noLoyaltyKey?: string | null
    onInvoiceCreated?: (data: IInvoiceCreatedResult) => any
}


export default function FastPay(
    {
        paymentMode,
        enteredAmount,
        invoiceTemplateId,
        noLoyaltyKey,
        onInvoiceCreated,
    }: FastPayProps
) {
    const {
        lang,
        localisation,
        loadingManager: {setIsLoading},
    } = useAppContext()

    const {
        data: invoiceTemplate,
        isFetching,
        isFetched,
        isError,
        error,
    } = useQuery<InvoiceTemplate | null, any>({
        queryKey: ['payment-page-invoice', invoiceTemplateId, lang],
        queryFn: async () => {
            if (!invoiceTemplateId) return null

            return await api.invoice.getInvoiceTemplate(invoiceTemplateId).then(response => response.data)
        },
        placeholderData: keepPreviousData,
    })

    const isLoading = isFetching || !isFetched

    useEffect(() => {
        setIsLoading(LOADING_KEY, isLoading)

        return () => {
            setIsLoading(LOADING_KEY, false)
        }
    }, [isLoading, setIsLoading]);

    const computedErrorText: string | null = useMemo(() => {
        if (paymentMode === 'template' && !(isLoading || isError) && !invoiceTemplateId) {
            return localisation.payment.noTemplateForTemplatePaymentError
        }

        if (isError) {
            return error?.response?.data?.detail || localisation.global.errUnknown
        }

        return null
    }, [
        isError,
        isLoading,
        paymentMode,
        invoiceTemplateId,
        localisation.global.errUnknown,
        localisation.payment.noTemplateForTemplatePaymentError,
        error?.response?.data?.detail,
    ])

    return (
        <Box>
            {computedErrorText ? (
                <Typography variant={'h6'} color={'error'}>
                    <Interweave content={computedErrorText}/>
                </Typography>
            ) : (
                paymentMode && (!invoiceTemplateId || invoiceTemplate) && (
                    <MakeInvoice
                        paymentMode={paymentMode as InvoicePaymentMode}
                        invoiceTemplate={invoiceTemplate}
                        enteredAmount={enteredAmount}
                        noLoyaltyKey={noLoyaltyKey}
                        onInvoiceCreated={onInvoiceCreated}
                        stepperWrapperProps={{
                            sx: {
                                px: 4,
                                pt: 2,
                                pb: 3,
                            }
                        }}
                    />
                )
            )}
        </Box>
    )
}
